import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Typography, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, Box, IconButton } from '@mui/material';
import ImageIcon from '@mui/icons-material/Image'; // Icon for image files
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'; // Icon for non-image files
import DownloadIcon from '@mui/icons-material/Download'; // Icon for download button

function Brochures() {
  const [file, setFile] = useState(null);
  const [brochures, setBrochures] = useState([]);
  const [editBrochure, setEditBrochure] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchBrochures();
  }, []);

  const fetchBrochures = async () => {
    setLoading(true);
    try {
      const response = await axios.get('http://localhost:8000/api/brochures');
      setBrochures(response.data);
    } catch (error) {
      setError('Error fetching brochures.');
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) return;
    const formData = new FormData();
    formData.append('brochure', file);
    try {
      await axios.post('http://localhost:8000/api/brochures', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      setFile(null);
      fetchBrochures();
    } catch (error) {
      setError('Error uploading brochure.');
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`http://localhost:8000/api/brochures/${id}`);
      fetchBrochures();
    } catch (error) {
      setError('Error deleting brochure.');
    }
  };

  const handleEdit = (brochure) => {
    setEditBrochure(brochure);
    setFile(null); // Clear the file input if you are editing
  };

  const handleUpdate = async () => {
    if (!file || !editBrochure) return;
    const formData = new FormData();
    formData.append('brochure', file);
    try {
      await axios.put(`http://localhost:8000/api/brochures/${editBrochure.id}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      setFile(null);
      setEditBrochure(null);
      fetchBrochures();
    } catch (error) {
      setError('Error updating brochure.');
    }
  };

  const handleDownload = (id) => {
    window.location.href = `http://localhost:8000/api/brochures/file/${id}`;
  };

  const renderFilePreview = (brochure) => {
    if (brochure.mime_type.startsWith('image/')) {
      return (
        <img
          src={`http://localhost:8000/api/brochures/file/${brochure.id}`}
          alt="Preview"
          style={{ width: 100, height: 'auto' }}
        />
      );
    } else {
      return <InsertDriveFileIcon />; // Use InsertDriveFileIcon for non-image files
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom align="center">
        Brochure Management
      </Typography>

      <Box mb={4} display="flex" flexDirection="column" alignItems="center">
        <input type="file" onChange={handleFileChange} />
        <Button
          variant="contained"
          color="primary"
          onClick={editBrochure ? handleUpdate : handleUpload}
          style={{ marginTop: 16 }}
        >
          {editBrochure ? 'Update Brochure' : 'Upload Brochure'}
        </Button>
      </Box>

      {loading ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : error ? (
        <Typography color="error" align="center">
          {error}
        </Typography>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Preview</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {brochures.map(brochure => (
                <TableRow key={brochure.id}>
                  <TableCell>{brochure.id}</TableCell>
                  <TableCell>{renderFilePreview(brochure)}</TableCell>
                  <TableCell>
                    <IconButton
                      color="primary"
                      onClick={() => handleDownload(brochure.id)}
                      title="Download"
                    >
                      <DownloadIcon />
                    </IconButton>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => handleEdit(brochure)}
                      style={{ marginRight: 8 }}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => handleDelete(brochure.id)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Container>
  );
}

export default Brochures;


