import React from 'react';
import './ImageSlider.css';
import Marquee from 'react-fast-marquee';
import abb from '../../Images/abb.png';
import arbtec from '../../Images/arabtec.png';
import bhartiya_viman from '../../Images/bhartiya_viman.png';
import bhel from '../../Images/bhel.png';
import hel from '../../Images/hel.png';
import lt from '../../Images/LT.png';
import skf from '../../Images/skf.png';
import sparl from '../../Images/sparl_minda.png';

const ImageSlider = () => {
  const images = [
    { url: abb, name: 'ABB' },
    { url: arbtec, name: 'Arbtec' },
    { url: bhartiya_viman, name: 'Bhartiya Viman' },
    { url: bhel, name: 'BHEL' },
    { url: hel, name: 'HEL' },
    { url: lt, name: 'LT' },
    { url: skf, name: 'SKF' },
    { url: sparl, name: 'SPARL' }
  ];

  return (
    <>
      <h1 style={{ fontWeight: '900', fontSize: 'xx-large', marginTop: '50px', textAlign: 'center' }}>Our Clients</h1>
      <Marquee>
        {images.map((image, index) => (
          <div key={index} className="card1">
            <img className="avatar1" src={image.url} alt={`Slide-${index}`} />
            <div className="card-body1">
              <h5 className="card-title1">{image.name}</h5>
            </div>
          </div>
        ))}
      </Marquee>
    </>
  );
};

export default ImageSlider;


