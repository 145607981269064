import React, { useState, useRef,useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Menu from "../Menu/Menu";
import { Dropdown } from "antd";

// Image imports
import user_image from "../../Images/useravatar.png";
import logo from "../../Images/logo.jpg";

// Icon imports
import { BiLogOut } from "react-icons/bi";
import { HiOutlineHome } from "react-icons/hi";
import { TbLayoutGridAdd, TbFileInvoice, TbFileCertificate, TbNotes, TbPinned, TbDeviceMobileMessage, TbPlaylistAdd, TbAugmentedReality, TbBrandStorj } from "react-icons/tb";
import { CrownOutlined, UsergroupAddOutlined, UserOutlined } from '@ant-design/icons';
import { GoChevronDown } from "react-icons/go";
// CSS imports
import "./Sidebar.css";

const Sidebar = ({ children, isAdmin, setIsLogin }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(true); // State to control sidebar open/close
  const [user, setUser] = useState({});
  const location = useLocation();
  const currentPath = location.pathname;
  const sidebarRef = useRef(null);

  // Handle click events to toggle sidebar
  const handleSidebarClick = () => {
    setIsOpen(!isOpen);
  };

  // Handle click events outside the sidebar to close it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.body.addEventListener("click", handleOutsideClick);

    return () => {
      document.body.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    setIsLogin(false);
    navigate('/');
  };

  // Define sidebar menu items based on isAdmin status
  const sidebarItems = isAdmin === 1
    ? [
      { icon: <HiOutlineHome />, title: "Dashboard", address: "/" },
      { icon: <TbFileCertificate />, title: "Manage Courses", address: "/manage-course" },
      { icon: <TbPinned />, title: "Notice", address: "/notice" },
      { icon: <TbNotes />, title: "Notes", address: "/createnote" },
      { icon: <TbBrandStorj />, title: "Quiz", address: "/manage-quiz" },
      { icon: <TbAugmentedReality />, title: "Complain", address: "/complainsee" },
      { icon: <TbAugmentedReality />, title: "Coupon", address: "/coupon-card" },
      { icon: <TbDeviceMobileMessage />, title: "Chat", address: "/chats" },
      { icon: <UsergroupAddOutlined />, title: "Batches", address: "/batch" },
      { icon: <CrownOutlined />, title: "Campaign", address: "/campaign" },
      { icon: <UserOutlined />, title: "People", address: "/student" },
      { icon: <TbLayoutGridAdd />, title: "Contents", address: "/maincontaint" },
      { icon: <TbPlaylistAdd />, title: "Assignment", address: "/createassignment" },
    ]
    : [
      { icon: <HiOutlineHome />, title: "Dashboard", address: "/" },
      { icon: <TbFileCertificate />, title: "My Course", address: "/my-courses" },
      { icon: <TbFileInvoice />, title: "Courses", address: "/course" },
      { icon: <TbLayoutGridAdd />, title: "Contents", address: "/contents" },
      { icon: <TbBrandStorj />, title: "Quiz", address: "/quiz" },
      { icon: <UsergroupAddOutlined />, title: "Batches", address: "/seebatches" },
      { icon: <TbDeviceMobileMessage />, title: "Chat", address: "/chats" },
      { icon: <TbAugmentedReality />, title: "Complain", address: "/complain" },
    ];

  // Dropdown menu
  const items = [
    {
      key: "1",
      label: <span onClick={() => { navigate('/profile') }}><b style={{ fontSize: '1.2em', paddingTop: '20px' }}>My Profile</b></span>,
    }, {
      key: "2",
      label: <span><b style={{ fontSize: '1.2em', paddingTop: '20px' }}>Setting</b></span>,
    },
    {
      key: "3",
      label: <span onClick={() => handleLogout()}><b style={{ fontSize: '1.2em', paddingTop: '20px' }}>Logout</b></span>,
    }
  ];

  return (
    <>
      {/* Side Bar */}
      <div id="sidebar" className={isOpen ? "" : "hide"} ref={sidebarRef} onClick={handleSidebarClick}>
        {/* Side bar menu */}
        <Link to="/" className="logo">
          <img src={logo} alt="logo" />
        </Link>
        <ul className="side-menu top" onClick={(e) => e.stopPropagation()}>
          {sidebarItems.map((item, i) => (
            <Menu Icon={item.icon} Title={item.title} key={i} Address={item.address} Active={currentPath === item.address} />
          ))}
          <span onClick={handleLogout}>
            <Menu Icon={<BiLogOut />} Title={"Logout"} Address={""} />
          </span>
        </ul>
      </div>

      {/* Top Bar */}
      <div id="contents">
        <div className="nav">
          <div>
            <div style={{ fontSize: 'x-large' }} className="menuIcon2" onClick={handleSidebarClick}>
              ☰
            </div>
          </div>
          <div>
            <Dropdown menu={{ items }} placement="bottomLeft" overlayStyle={{ width: '17vw', textAlign: 'center' }} arrow trigger={['click']}>
              <Link className="profile">
                {user?.profilePictureUrl ? (
                  <img src={user.profilePictureUrl} alt="user" />
                ) : <img src={user_image} alt="user" />}
                <div>
                  <p style={{ fontSize: '1em' }}>{user?.name}</p>
                  <p>
                    {user?.user_type} <GoChevronDown />
                  </p>
                </div>
              </Link>
            </Dropdown>
          </div>
        </div>
        {children}
      </div>
    </>
  );
};

export default Sidebar;
