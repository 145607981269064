import React from "react"
import "./style.css"
import { Col, Container, Row } from "react-bootstrap"

const Footer = () => {
  return (
    <footer>
        <Container>
          <Row className="footer-row">
            <Col md={3} sm={5} className='box'>
              <div className="logo">
                  <ion-icon name="bag"></ion-icon>
                  <h1>TechnoFlex</h1>
              </div>
              <p>Established in 2011, Techno Flex, based in Indore, stands as a premier manufacturer specializing in PVC Coated Flexible Conduit, GI Pipes, and a wide range of high-quality electrical accessories. With a commitment to innovation and quality, we cater to diverse industry needs with reliability and excellence.</p>
            </Col>
            <Col md={3} sm={5} className='box'>
              <h2>About Us</h2>
              <ul>
                <li>Product</li>
                <li>Contact us</li>
                <li>Location</li>
                <li>Contact Us</li>
                <li>Contact Us</li>
              </ul>
            </Col>
            <Col md={3} sm={5} className='box'>
              <h2>Customer Care</h2>
              <ul>
                <li>Profile </li>
                <li>News </li>
                <li>Download Brochure </li>
                <li>Awards & Memberships </li>
                <li>Testimonial </li>
              </ul>
            </Col>
            <Col md={3} sm={5} className='box'>
              <h2>Contact Us</h2>
              <ul>
                <li>100, GOLDEN FORM HOUSE, OPP CAT SQUARE RAJENDRA NAGAR, INDORE, Indore, Madhya Pradesh, 452012
                Indore - 452003, Madhya Pradesh, India </li>
                <li>Email: info@technoflex.in</li>
                <li>Phone: +91-8770608670</li>
              </ul>
            </Col>
          </Row>
        </Container>
    </footer>
  )
}

export default Footer
