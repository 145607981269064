// import React, { useEffect } from "react";
// import { Form, Checkbox } from "antd";
// import { useNavigate } from "react-router-dom";
// import { useDispatch } from "react-redux";
// import LoginAction from "../../redux/actions/AuthAction/LoginAction";
// import { NavLink } from "react-router-dom";
// import Component from "../../components/index";
// import Validation from "../../utils/Validation";

// const { TextInput, Button } = Component;

// const onFinishFailed = (errorInfo) => {
//   console.log("Failed:", errorInfo);
// };

// const LoginForm = () => {
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const validationRules = Validation.getValidation(["userName", "password"]);

//   const onFinish = (values) => {
//     const allRequest = {
//       request: values,
//       route: navigate,
//     };
//     dispatch(LoginAction.loginRequest(allRequest));
//     console.log("Success:", values);
//   };

//   // useEffect(() => {
//   //   dispatch(LoginAction.fetchUserRequest());
//   // }, [dispatch]);

//   return (
//     <Form
//       name="basic"
//       layout="vertical"
//       initialValues={{ remember: true }}
//       onFinish={onFinish}
//       onFinishFailed={onFinishFailed}
//       autoComplete="off"
//     >
//       <TextInput
//         label="Email"
//         name="userName"
//         placeholder="Email"
//         hasFeedback={true}
//         validateTrigger={"onChange"}
//         rules={validationRules.userName}
//       />

//       <TextInput
//         label="Password"
//         name="password"
//         placeholder="Password"
//         hasFeedback={true}
//         validateTrigger={"onChange"}
//         rules={validationRules.password}
//         password={true}
//       />

//       <Form.Item
//         name="remember"
//         valuePropName="checked"
//         style={{ marginBottom: "15px" }}
//       >
//         <Checkbox>Remember me</Checkbox>
//       </Form.Item>

//       <Button
//         title="Log in"
//         type="primary"
//         className="login-button"
//         htmlType="submit"
//       />

//       <div className="forgot-password-link">
//         <NavLink to="/auth/forgot-password">Forgot password?</NavLink>
//       </div>
//     </Form>
//   );
// };

// export default LoginForm;

import React from "react";
import { Form, Checkbox, message } from "antd";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { NavLink } from "react-router-dom";
import Component from "../../components/index";
import Validation from "../../utils/Validation";

const { TextInput, Button } = Component;

const onFinishFailed = (errorInfo) => {
  console.log("Failed:", errorInfo);
};

const LoginForm = () => {
  const navigate = useNavigate();
  const validationRules = Validation.getValidation(["email", "password"]);

  const onFinish = async (values) => {
    console.log('Form values:', values); // Debugging line

    try {
      // Make the API request
      const response = await axios.post('http://localhost:8000/api/users/login', {
        email: values.email,  // Ensure these keys match the expected backend fields
        password: values.password
      });

      if (response.status === 200) {
        // Handle successful login
        message.success('Login successful!');
        navigate('/dashboard'); // Redirect to home or another route upon success
      } else {
        // Handle unexpected responses
        message.error('Login failed, please try again.');
      }
    } catch (error) {
      // Handle errors
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        const errorMessage = error.response.data.message || 'Invalid email or password';
        message.error(errorMessage);
      } else if (error.request) {
        // The request was made but no response was received
        message.error('No response from server. Please try again later.');
      } else {
        // Something happened in setting up the request that triggered an Error
        message.error('An error occurred. Please try again.');
      }
      console.error('Login error:', error);
    }
  };

  return (
    <Form
      name="basic"
      layout="vertical"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <TextInput
        label="Email"
        name="email"  // Changed to match backend expectation
        placeholder="Email"
        hasFeedback={true}
        validateTrigger={"onChange"}
        rules={validationRules.email}
      />

      <TextInput
        label="Password"
        name="password"
        placeholder="Password"
        hasFeedback={true}
        validateTrigger={"onChange"}
        rules={validationRules.password}
        password={true}
      />

      <Form.Item
        name="remember"
        valuePropName="checked"
        style={{ marginBottom: "15px" }}
      >
        <Checkbox>Remember me</Checkbox>
      </Form.Item>

      <Button
        title="Log in"
        type="primary"
        className="login-button"
        htmlType="submit"
      />

      <div className="forgot-password-link">
        <NavLink to="/auth/forgot-password">Forgot password?</NavLink>
      </div>
    </Form>
  );
};

export default LoginForm;
