// src/TechnoflexHomeSlider.js
import React, { useState, useEffect } from 'react';
import './TechnoflexHomeSlider.css';
import tecban from '../../Images/newban2.jpg'; // Correct image import
import tecoff from '../../Images/newban.jpg'; 
import bg4 from "../../Images/pipebg3.png";
// Define the images array correctly with the imported image
const images = [
  tecban,
  tecoff,
  bg4,
  tecoff,
  bg4
];

const TechnoflexHomeSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex(prevIndex => (prevIndex + 1) % images.length);
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="technoflex-home-slider">
      <div className="technoflex-home-slider-content">
        <img src={images[currentIndex]} alt={`Slide ${currentIndex + 1}`} />
      </div>
    </div>
  );
};

export default TechnoflexHomeSlider;
