// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import './ManageGalleryItems.css'; // Import CSS for custom styles

// const ManageGalleryItems = () => {
//     const [galleryItems, setGalleryItems] = useState([]);
//     const [zoomedImage, setZoomedImage] = useState(null);

//     useEffect(() => {
//         fetchGalleryItems();
//     }, []);

//     const fetchGalleryItems = async () => {
//         try {
//             const response = await axios.get('http://localhost:8000/api/product-gallery');
//             setGalleryItems(response.data);
//         } catch (error) {
//             console.error('Error fetching gallery items:', error);
//         }
//     };

//     const handleImageClick = (imageSrc) => {
//         setZoomedImage(imageSrc);
//     };

//     const handleCloseZoom = () => {
//         setZoomedImage(null);
//     };

//     return (
//         <div className="container mt-5">
//             <h3 className="text-center mb-4">Gallery Items</h3>
//             <div className="row">
//                 {galleryItems.map(item => (
//                     <div key={item.id} className="col-md-6 col-lg-4 mb-4">
//                         <div className="card shadow-sm border-0">
//                             {item.GalleryProductImage && (
//                                 <div className="card-img-wrapper">
//                                     <img
//                                         src={`data:image/jpeg;base64,${item.GalleryProductImage}`}
//                                         className="card-img-top"
//                                         alt={item.GalleryProductName}
//                                         onClick={() => handleImageClick(`data:image/jpeg;base64,${item.GalleryProductImage}`)}
//                                     />
//                                 </div>
//                             )}
//                             <div className="card-body text-center">
//                                 <h5 className="card-title">{item.GalleryProductName}</h5>
//                             </div>
//                         </div>
//                     </div>
//                 ))}
//             </div>

//             {/* Zoomed Image Modal */}
//             {zoomedImage && (
//                 <div className="zoomed-image-overlay" onClick={handleCloseZoom}>
//                     <img src={zoomedImage} alt="Zoomed" className="zoomed-image" />
//                 </div>
//             )}
//         </div>
//     );
// };

// export default ManageGalleryItems;

import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ManageGalleryItems.css'; // Import CSS for custom styles
import pg1 from '../../Images/pg1.jpg'
import pg2 from '../../Images/pg2.jpg'
import pg3 from '../../Images/pg3.jpg'
import pg4 from '../../Images/pg4.jpg'
import pg7 from '../../Images/pg7.jpg'
import pg6 from '../../Images/pg6.jpg'
const ManageGalleryItems = () => {
    // Static gallery items
    const [galleryItems] = useState([
        {
            id: 1,
            GalleryProductName: 'Die Casting Coupling',
            GalleryProductImage: pg1 
        },
        {
            id: 2,
            GalleryProductName: 'Flexible Conduit Connector 90D',
            GalleryProductImage:pg2
        },
        {
            id: 3,
            GalleryProductName: 'SS Conduit Gland',
            GalleryProductImage: pg3
        },
        {
            id: 4,
            GalleryProductName: 'Liquid Tight Steel Flexible Conduit',
            GalleryProductImage: pg4
        },
        {
            id: 5,
            GalleryProductName: 'Liquid Tight Steel Flexible Conduit',
            GalleryProductImage: pg7
        },
        {
            id: 6,
            GalleryProductName: 'Liquid Tight Flexible Conduit',
            GalleryProductImage: pg6
        }
    ]);

    const [zoomedImage, setZoomedImage] = useState(null);

    const handleImageClick = (imageSrc) => {
        setZoomedImage(imageSrc);
    };

    const handleCloseZoom = () => {
        setZoomedImage(null);
    };

    return (
        <div className="container mt-5">
            <h3 className="text-center mb-4">Discover the Technoflex Innovation</h3>
            <div className="row">
                {galleryItems.map(item => (
                    <div key={item.id} className="col-md-6 col-lg-4 mb-4">
                        <div className="card shadow-sm border-0">
                            {item.GalleryProductImage && (
                                <div className="card-img-wrapper">
                                    <img
                                        src={item.GalleryProductImage}
                                        className="card-img-top"
                                        alt={item.GalleryProductName}
                                        onClick={() => handleImageClick(item.GalleryProductImage)}
                                    />
                                </div>
                            )}
                            <div className="card-body text-center">
                                <h5 className="card-title">{item.GalleryProductName}</h5>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            {/* Zoomed Image Modal */}
            {zoomedImage && (
                <div className="zoomed-image-overlay" onClick={handleCloseZoom}>
                    <img src={zoomedImage} alt="Zoomed" className="zoomed-image" />
                </div>
            )}
        </div>
    );
};

export default ManageGalleryItems;

