import React from 'react';
import './MarketArea.css';
import { RiPlayMiniFill } from 'react-icons/ri';

function MarketArea() {
  const pipeTypes = [
    "Stainless steel pipe",
    "EWR Stainless steel pipe",
    "Seamless Stainless steel pipe",
    // Add more pipe types as needed
  ];

  const countries = [
    "ALGERIA", "ANGOLA", "ARGENTINA", "AUSTRALIA",
    "BAHRAIN", "BANGLADESH", "BRAZIL", "CAMEROON",
    // Add more countries as needed
  ];

  return (
    <div className="market-area">
      <div className="market-area__container">
        <div className="sidebar">
          <div className="quick-contact">
            <h2 className="section-header">QUICK CONTACT</h2>
            <input type="text" placeholder="Your Name" />
            <input type="email" placeholder="Your Email address" />
            <input type="number" placeholder="Your number" />
            <div className="country-select">
              <select>
                <option>Select a country</option>
                <option>United States</option>
                <option>Canada</option>
                <option>United Kingdom</option>
                <option>Australia</option>
                <option>India</option>
              </select>
            </div>
            <input type="text" placeholder="Type your message Here...." className="message-input" />
            <div className="submit-button">
              <input type="submit" value="Submit" />
            </div>
          </div>

          <div className="pipe-types">
            <h2 className="section-header">PIPE AND TUBES</h2>
            {pipeTypes.map((type, index) => (
              <div key={index} className="pipe-type-item">
                <button>{type}</button>
              </div>
            ))}
          </div>
        </div>

        <div className="main-content">
          <div className="market-area-header">
            <h2 className="section-header">MARKET AREA WE SERVE</h2>
          </div>
          <div className="country-grid">
            {Array(4).fill().map((_, rowIndex) => (
              <div key={rowIndex} className="country-row">
                {countries.slice(rowIndex * 4, (rowIndex + 1) * 4).map((country, index) => (
                  <div key={index} className="country-item">
                    <button><RiPlayMiniFill />{country}</button>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MarketArea;