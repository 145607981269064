import React from 'react';
import './TechnoflexSustainability.css';
import tecban from '../../Images/banner_five.jpg';
import sustainabilityImg from '../../Images/banner_five.jpg';
import handCircle from '../../Images/banner_five.jpg';

const TechnoflexSustainability = () => {
    return (
        <div className="technoflex-main">
            {/* Banner Image */}
            <div className="technoflex-image">
                <img src={tecban} alt="Banner" />
            </div>

            {/* Main Paragraph Section */}
            <div className="technoflex-paragraph">
                <h1><span style={{ color: '#6d74da' }}>Sustainable in</span> every aspect.</h1>
                <h2>TechnoFlex integrates sustainability into every facet of its operations. Our initiatives demonstrate our dedication to reducing our environmental impact and fostering a sustainable future.</h2>
                <p>At TechnoFlex, sustainability is not just a buzzword but a core part of our business philosophy. Since our inception in 2011, we have committed to reducing our carbon footprint and enhancing our environmental performance. We focus on utilizing advanced technologies and sustainable practices in our manufacturing processes to minimize environmental impact and contribute positively to society.</p>
            </div>

            {/* Card Section */}
            <div className="technoflex-card-parent">
                <div className="technoflex-card-child" style={{ backgroundColor: '#ffd6d6' }}>
                    <p>Increasing the use of renewable energy from</p>
                    <h1>17% to 25% of the total energy requirement.</h1>
                    <p>(FY 23-24 Re Mix 14.11%)</p>
                </div>
                <div className="technoflex-card-child" style={{ backgroundColor: '#deeafc' }}>
                    <h1>26 manufacturing units with Renewable Energy Presence</h1>
                    <p>(Solar/Wind/Hybrid) energy.</p>
                </div>
                <div className="technoflex-card-child" style={{ backgroundColor: '#f8e6f8' }}>
                    <p>Aiming to achieve a reduction of</p>
                    <h1>carbon emission by 60,000 tons.</h1>
                </div>
            </div>

            {/* Additional Card Section */}
            <div className="technoflex-card-parent">
                <div className="technoflex-card-child1" style={{ backgroundColor: '#e7efcd' }}>
                    <p>Reducing carbon emission intensity of</p>
                    <h1>CO2 per MT of production by 17%.</h1>
                </div>
                <div className="technoflex-card-child1" style={{ backgroundColor: '#fceecd' }}>
                    <h1>Business Responsibility & Sustainability</h1>
                    <p>BRSR FY 23-24 Published.</p>
                </div>
                <div className="technoflex-card-child1" style={{ backgroundColor: '#cdfccd' }}>
                    <h1>Reduction of water footprint through re-use & re-cycling</h1>
                    <p>of water.</p>
                </div>
            </div>

            {/* Sustainability Image and Text Section */}
            <div className="technoflex-contain-parent">
                <div className="technoflex-contain-child1">
                    <img src={sustainabilityImg} alt="Sustainability" />
                </div>
                <div className="technoflex-contain-child2">
                    <h1>The Blueprint of Our Sustainable Future</h1>
                    <p>TechnoFlex’s commitment to sustainability is outlined in our Sustainability Report. Discover our transparency and join us in crafting an eco-conscious future.</p>
                    <button>DOWNLOAD SUSTAINABILITY REPORT</button>
                </div>
            </div>

            {/* Carbon-Neutral Company Section */}
            <div className="technoflex-main2">
                <div className="technoflex-page-parent">
                    <div className="technoflex-page-child1">
                        <h1><span style={{ color: '#6d74da' }}>Marching towards becoming </span>a carbon-neutral company</h1>
                        <p>TechnoFlex is dedicated to reducing carbon emissions through renewable energy and low-carbon technologies. Our initiatives include replacing LPG with PNG and adopting dual fuel methods. Our HT Power infrastructure has been upgraded to optimize power usage, and our production has led to significant reductions in greenhouse gas emissions.</p>
                        <p>We have completed a Life Cycle Assessment for several of our major products, contributing to a substantial portion of our revenue, with plans to extend these assessments further.</p>
                        <h2>Lower Carbon Emission Roadmap</h2>
                        <ul>
                            <li>FY 22-23: 44.47 Million Kwh with 29,883 tCO2e emission reduction.</li>
                            <li>FY 23-24: 53.74 Million Kwh with 38,476 tCO2e emission reduction.</li>
                            <li>FY 24-25: Budgeted 80 Million Kwh with 57,280 tCO2e emission reduction.</li>
                        </ul>
                    </div>
                    <div className="technoflex-page-child2">
                        <img src={handCircle} alt="Hand Circle" />
                    </div>
                </div>
            </div>

            {/* Header Section */}
            <div className="technoflex-header-parent">
                <div className="technoflex-header-child1">
                    <h1><span style={{ color: '#6d74da' }}>Renewable </span>Energy</h1>
                    <p>Climate change presents significant challenges, including extreme weather events and rising global temperatures. At TechnoFlex, we believe in leveraging our business capabilities to drive sustainable change.</p>
                    <p>Our 'Energy and Environment' vertical focuses on climate change, energy efficiency, and renewable energy. We are committed to advancing sustainable development through rooftop solar power installations and green energy procurement across our 26 manufacturing units.</p>
                </div>
                <div className="technoflex-header-child2">
                    <p>TechnoFlex is dedicated to integrating sustainability into our business strategy. We have made substantial progress by installing solar power systems and procuring renewable energy, aligning with our vision for a sustainable future.</p>
                </div>
            </div>
            <div className="technoflex-water-green">
    <div className="technoflex-water-management">
        <img src={tecban} alt="Water Management" className="technoflex-water-img" />
        <div className="technoflex-water-content">
            <h2>Water Management</h2>
            <p>TechnoFlex is committed to addressing global water scarcity. We have implemented several initiatives to monitor, conserve, recycle, and reduce fresh water consumption.</p>
            <p>We focus on improving groundwater levels by building ponds and pits to collect and conserve rainwater. Our rainwater harvesting system (Catch the Rain) and zero liquid discharge policy (Stop the Drain) are central to our water management efforts.</p>
            <p>We also conserve water resources by reusing STP-treated water for internal purposes and strictly adhere to legal requirements of our Water Management System.</p>
        </div>
    </div>

    <div className="technoflex-green-products">
        <img src={tecban} alt="Green Products" className="technoflex-green-img" />
        <div className="technoflex-green-content">
            <h2>Green Products</h2>
            <p>Our range of eco-friendly products helps conserve natural resources. TechnoFlex's sustainable product line includes INSU Sound XLO, INSU Sound BN, INSU Sound B CAP, INSU BXL, INSU Tape, INSUflex Hose & Sheet, INSU Reflector, INSU Shield, INSU Shield Tubing, and INSU MELA foam. All these products are classified as Green products and certified by CII-Green Pro.</p>
        </div>
    </div>
</div>
<div className="technoflex-certifications">
    <div className="technoflex-certifications-content">
        <h2>Certifications</h2>
        <p>TechnoFlex is committed to maintaining high standards across our operations, with multiple certifications that reflect our dedication to quality, environmental stewardship, and safety.</p>
    </div>

    <div className="technoflex-certifications-main">
        <img src={tecban} alt="Certifications" className="technoflex-certifications-img" />
        
        <div className="technoflex-certifications-details">
            <div className="technoflex-certification-text">
                <h3>ISO 50001:2018 Certification</h3>
                <p>Our 15 major energy-intensive plants are already certified for ISO 50001 Energy Management System (EnMS).</p>
            </div>
            
            <div className="technoflex-certification-text">
                <h3>ISO 14001 & ISO 45001</h3>
                <p>16 manufacturing units are certified with ISO 14001. 15 manufacturing units are certified with ISO 45001.</p>
            </div>
        </div>
        
        <div className="technoflex-additional-info">
            <p>ESG reporting, Carbon Disclosure Projects (CDP), fulfilling all Environmental Compliances, Extended Producer Responsibility (EPR), and Green Procurement Policy for sustainable sourcing from value chain partners are a few of our key focus areas.</p>
        </div>
    </div>
</div>
<div className="technoflex-sustainable-tomorrow">
    <div className="technoflex-sustainable-content">
        <h2>A Sustainable Tomorrow</h2>
        <p>In today's day and age, the green movement is more important than ever. It is indeed every company's responsibility to make changes and choices that benefit the environment. Hence as a company, we aim to foster an environment-first culture in the future as well.</p>
    </div>
    <div className="technoflex-sustainable-image">
        <img src={tecban} alt="Sustainable Tomorrow" className="technoflex-sustainable-img" />
    </div>
</div>


        </div>
        
    );
};

export default TechnoflexSustainability;
