import React,{memo} from "react";
import { useSelector } from "react-redux";
import Component from "../../components/index";
import LoginForm from "../authentication/LoginForm";
import Logo from "../../Images/technologo.jpg";
import './Login.css'
const { Spinner } = Component;
const Login = () => {
  const data = useSelector((state) => state?.Login);

  return (
    <>
      {data?.loading && <Spinner />}
      <div className="logo-container">
        <img src={Logo} alt="Logo" className="logo" />
        <h2 className="title">Technoflex Login</h2>
      </div>
      <div className="login-container">
        <div className="login-box">
          <LoginForm />
        </div>
      </div>
    </>
  );
};

export default memo(Login);
