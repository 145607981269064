import React, { useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

const CreateProductDetails = () => {
    const [productID, setProductID] = useState('');
    const [material, setMaterial] = useState('');
    const [size, setSize] = useState('');
    const [usageApplication, setUsageApplication] = useState('');
    const [brand, setBrand] = useState('');
    const [connectionType, setConnectionType] = useState('');
    const [color, setColor] = useState('');
    const [countryOfOrigin, setCountryOfOrigin] = useState('');
    const [minimumOrderQuantity, setMinimumOrderQuantity] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            await axios.post('http://localhost:8000/api/productdetails', {
                ProductID: productID,
                Material: material,
                Size: size,
                UsageApplication: usageApplication,
                Brand: brand,
                ConnectionType: connectionType,
                Color: color,
                CountryOfOrigin: countryOfOrigin,
                MinimumOrderQuantity: minimumOrderQuantity,
            });
            alert('Product details created successfully!');
        } catch (error) {
            console.error('Error creating product details:', error);
            alert('Error creating product details.');
        }
    };

    return (
        <div className="container mt-5">
            <h2>Create New Product Details</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="productID">Product ID</label>
                    <input
                        type="number"
                        className="form-control"
                        id="productID"
                        value={productID}
                        onChange={(e) => setProductID(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="material">Material</label>
                    <input
                        type="text"
                        className="form-control"
                        id="material"
                        value={material}
                        onChange={(e) => setMaterial(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="size">Size</label>
                    <input
                        type="text"
                        className="form-control"
                        id="size"
                        value={size}
                        onChange={(e) => setSize(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="usageApplication">Usage Application</label>
                    <input
                        type="text"
                        className="form-control"
                        id="usageApplication"
                        value={usageApplication}
                        onChange={(e) => setUsageApplication(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="brand">Brand</label>
                    <input
                        type="text"
                        className="form-control"
                        id="brand"
                        value={brand}
                        onChange={(e) => setBrand(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="connectionType">Connection Type</label>
                    <input
                        type="text"
                        className="form-control"
                        id="connectionType"
                        value={connectionType}
                        onChange={(e) => setConnectionType(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="color">Color</label>
                    <input
                        type="text"
                        className="form-control"
                        id="color"
                        value={color}
                        onChange={(e) => setColor(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="countryOfOrigin">Country of Origin</label>
                    <input
                        type="text"
                        className="form-control"
                        id="countryOfOrigin"
                        value={countryOfOrigin}
                        onChange={(e) => setCountryOfOrigin(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="minimumOrderQuantity">Minimum Order Quantity</label>
                    <input
                        type="text"
                        className="form-control"
                        id="minimumOrderQuantity"
                        value={minimumOrderQuantity}
                        onChange={(e) => setMinimumOrderQuantity(e.target.value)}
                        required
                    />
                </div>
                <button type="submit" className="btn btn-primary">Create Product Details</button>
            </form>
        </div>
    );
};

export default CreateProductDetails;
