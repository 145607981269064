// src/components/TechnoflexMilestones.js

import React from 'react';
import './TechnoflexMilestones.css'; // Import the corresponding CSS file
import tecban from '../../Images/newban2.jpg'
const TechnoflexMilestones = () => {
  return (
    <div className="technoflex-main">
      <div className="technoflex-image">
        <img src={tecban} alt="Technoflex Banner" />
      </div>
      <div className="technoflex-page">
        <h1 style={{ color: 'rgb(19, 162, 194)', fontSize: '45px', marginTop: '30px' }}>Technoflex Milestones</h1>
        <h1 style={{ marginTop: '25px' }}>
        With a solid foundation and steadfast support, Techno Flex has consistently achieved new milestones and elevated its success at every turn.
        </h1>
        <p>
        What makes our journey so remarkable is our unwavering commitment to delivering high-quality products, forging strong relationships, and championing social upliftment. Let’s take a moment to explore the milestones of our Techno Flex journey.
        </p>
      </div>
      <div className="technoflex-parent-content">
        <div className="technoflex-page1"></div>

        <div className="technoflex-page11">
          <div className="technoflex-page12">
      
              <h1 style={{ marginTop: '25px', }}>2011 - Founding and Early Development</h1>
            

            <p>In 2011, Techno Flex Industries Limited was founded under the leadership of Mr. Prashant Gadekar.</p>
            <p>The company was established with the mission to manufacture, supply, and export high-quality flexible conduit products.</p>
            <p>The initial focus was on setting up a robust manufacturing base to produce a range of flexible conduits, including Aluminum and GI Steel Flexible Conduits, along with conduit accessories.</p>
            <p style={{ marginBottom: '25px' }}>The choice of Indore, a dynamic hub of business and industry, provided the perfect backdrop for the company's ambitious goals.</p>
          </div>

          <div className="technoflex-page12">
          <h1 style={{ marginTop: '25px', }}>2012 - Infrastructure and Initial Growth</h1>
            <p>By 2012, Techno Flex made significant strides in developing its infrastructure.</p>
            <p>The company set up modern manufacturing facilities equipped with advanced machinery.</p>
            <p>The emphasis was on producing flexible conduits that meet industry standards and cater to diverse applications.</p>
            <p style={{ marginBottom: '25px' }}>Techno Flex began building a reputation for quality and reliability in the market.</p>
          </div>

          <div className="technoflex-page12">
          <h1 style={{ marginTop: '25px', }}>2015 - Expansion of Product Line</h1>
            <p>In 2015, Techno Flex expanded its product offerings to include a wider range of flexible conduit solutions.</p>
            <p>The company introduced new products to meet the growing demands of industries such as construction, rail transportation, and shipping.</p>
           
            <p style={{ marginBottom: '25px' }}>This period marked a significant phase of diversification and strengthening of its product portfolio.</p>
          </div>

          <div className="technoflex-page12">
          <h1 style={{ marginTop: '25px', }}>2018 - Global Reach and Certification</h1>
            <p>By 2018, Techno Flex achieved notable milestones in expanding its global footprint.</p>
            <p>The company obtained ISO 9001:2008 certification, underscoring its commitment to quality management and customer satisfaction.</p>
            <p>Techno Flex began exporting its products to international markets, including the UAE, UK, USA, and Asia.</p>
            <p style={{ marginBottom: '25px' }}>The company’s global presence grew, reflecting its increasing recognition and trust in the global market.</p>
          </div>

          <div className="technoflex-page12">
          <h1 style={{ marginTop: '25px', }}>2020 - Enhanced Manufacturing Capabilities</h1>
            <p>In 2020, Techno Flex continued to enhance its manufacturing capabilities.</p>
            <p>The company invested in upgrading its facilities and technology to further improve product quality and production efficiency.</p>
           
            <p style={{ marginBottom: '25px' }}>This investment supported the company’s ability to meet the evolving needs of its clients and maintain high standards in its manufacturing processes.</p>
          </div>

          <div className="technoflex-page12">
          <h1 style={{ marginTop: '25px', }}>2022 - Development of Knowledge Centres</h1>
            <p>Techno Flex launched its specialized Knowledge Centres in 2022, aimed at providing valuable training and resources to professionals in the industry.</p>
            
            <p style={{ marginBottom: '25px' }}>Located strategically in key regions, including Indore, the Knowledge Centres serve as platforms for plumbers, contractors, consultants, and architects to gain hands-on experience and in-depth knowledge about flexible conduit solutions.</p>
          </div>

          <div className="technoflex-page12">
          <h1 style={{ marginTop: '25px', }}>2024 - Recognition and Industry Standing</h1>
            <p>By 2024, Techno Flex had established itself as a leading name in the flexible conduit industry. </p>
            <p>The company continued to receive accolades for its innovation, quality, and customer-centric approach.</p>
            <p>The emphasis was on producing flexible conduits that meet industry standards and cater to diverse applications.</p>
            <p style={{ marginBottom: '25px', }}>Under the visionary leadership of Mr. Prashant Gadekar, Techno Flex maintained a strong focus on delivering high-quality products and fostering relationships with clients worldwide.</p>
          </div>

          {/* Remove or update repeated milestones as necessary */}
        </div>
      </div>
    </div>
  );
}

export default TechnoflexMilestones;
