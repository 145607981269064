import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Upload, Table, message, Space } from 'antd';
import { UploadOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import axios from 'axios';

// API base URL for products
const API_BASE_URL = 'http://localhost:8000/api/product-gallery';

const ProductGallery = () => {
  const [form] = Form.useForm();
  const [galleryItems, setGalleryItems] = useState([]);
  const [editingItem, setEditingItem] = useState(null);
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    fetchGalleryItems();
  }, []);

  const fetchGalleryItems = async () => {
    try {
      const response = await axios.get(API_BASE_URL);
      setGalleryItems(response.data);
    } catch (error) {
      message.error('Failed to fetch gallery items');
    }
  };

  const handleSubmit = async (values) => {
    try {
      const { GalleryProductName } = values;
      const formData = new FormData();
      formData.append('GalleryProductName', GalleryProductName);

      if (fileList.length > 0) {
        formData.append('GalleryProductImage', fileList[0].originFileObj);
      }

      if (editingItem) {
        await axios.put(`${API_BASE_URL}/${editingItem.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        message.success('Gallery item updated successfully');
      } else {
        await axios.post(API_BASE_URL, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        message.success('Gallery item created successfully');
      }
      form.resetFields();
      setFileList([]);
      setEditingItem(null);
      fetchGalleryItems();
    } catch (error) {
      message.error('Failed to submit gallery item');
      console.error(error); // Log error details
    }
  };

  const handleEdit = (item) => {
    form.setFieldsValue({
      GalleryProductName: item.GalleryProductName,
    });
    setFileList([{
      uid: item.id,
      name: 'image.jpg',
      status: 'done',
      url: `data:image/jpeg;base64,${item.GalleryProductImage}`
    }]);
    setEditingItem(item);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${API_BASE_URL}/${id}`);
      message.success('Gallery item deleted successfully');
      fetchGalleryItems();
    } catch (error) {
      message.error('Failed to delete gallery item');
    }
  };

  const columns = [
    {
      title: 'Image',
      dataIndex: 'GalleryProductImage',
      key: 'GalleryProductImage',
      render: (text) => (
        <img
          src={`data:image/jpeg;base64,${text}`}
          alt="Gallery"
          style={{ width: '100px' }}
        />
      )
    },
    {
      title: 'Product Name',
      dataIndex: 'GalleryProductName',
      key: 'GalleryProductName'
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space>
          <Button icon={<EditOutlined />} onClick={() => handleEdit(record)}>Edit</Button>
          <Button icon={<DeleteOutlined />} danger onClick={() => handleDelete(record.id)}>Delete</Button>
        </Space>
      )
    }
  ];

  return (
    <div style={{ padding: '24px' }}>
      <Form form={form} onFinish={handleSubmit} layout="vertical" style={{ marginBottom: '24px' }}>
        <Form.Item
          name="GalleryProductName"
          label="Product Name"
          rules={[{ required: true, message: 'Please enter the product name' }]}
        >
          <Input placeholder="Enter product name" />
        </Form.Item>
        <Form.Item
          name="GalleryProductImage"
          label="Product Image"
          getValueFromEvent={({ file }) => file}
        >
          <Upload
            listType="picture"
            maxCount={1}
            showUploadList={false}
            beforeUpload={() => false} // Prevent auto upload
            fileList={fileList}
            onChange={({ fileList }) => setFileList(fileList)}
          >
            <Button icon={<UploadOutlined />}>Upload Image</Button>
          </Upload>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {editingItem ? 'Update Item' : 'Add Item'}
          </Button>
        </Form.Item>
      </Form>
      <Table
        columns={columns}
        dataSource={galleryItems}
        rowKey="id"
      />
    </div>
  );
};

export default ProductGallery;
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import 'bootstrap/dist/css/bootstrap.min.css';

// const ManageGalleryItems = () => {
//     const [galleryItems, setGalleryItems] = useState([]);
//     const [galleryProductName, setGalleryProductName] = useState('');
//     const [galleryProductImage, setGalleryProductImage] = useState(null);
//     const [editingItem, setEditingItem] = useState(null);
//     const [itemIdToDelete, setItemIdToDelete] = useState(null);

//     useEffect(() => {
//         fetchGalleryItems();
//     }, []);

//     const fetchGalleryItems = async () => {
//         try {
//             const response = await axios.get('http://localhost:8000/api/product-gallery');
//             setGalleryItems(response.data);
//         } catch (error) {
//             console.error('Error fetching gallery items:', error);
//         }
//     };

//     const handleCreateOrUpdate = async (event) => {
//         event.preventDefault();

//         const formData = new FormData();
//         formData.append('GalleryProductName', galleryProductName);
//         if (galleryProductImage) {
//             formData.append('GalleryProductImage', galleryProductImage);
//         }

//         try {
//             if (editingItem) {
//                 await axios.put(`http://localhost:8000/api/product-gallery/${editingItem.id}`, formData, {
//                     headers: {
//                         'Content-Type': 'multipart/form-data'
//                     }
//                 });
//                 alert('Gallery item updated successfully!');
//             } else {
//                 await axios.post('http://localhost:8000/api/product-gallery', formData, {
//                     headers: {
//                         'Content-Type': 'multipart/form-data'
//                     }
//                 });
//                 alert('Gallery item created successfully!');
//             }

//             setGalleryProductName('');
//             setGalleryProductImage(null);
//             setEditingItem(null);
//             fetchGalleryItems();
//         } catch (error) {
//             console.error('Error creating/updating gallery item:', error);
//             alert('Error creating/updating gallery item.');
//         }
//     };

//     const handleEdit = (item) => {
//         setGalleryProductName(item.GalleryProductName);
//         setEditingItem(item);
//     };

//     const handleDelete = async (id) => {
//         try {
//             await axios.delete(`http://localhost:8000/api/product-gallery/${id}`);
//             alert('Gallery item deleted successfully!');
//             fetchGalleryItems();
//         } catch (error) {
//             console.error('Error deleting gallery item:', error);
//             alert('Error deleting gallery item.');
//         }
//     };

//     return (
//         <div className="container mt-5">
//             <h2>{editingItem ? 'Edit Gallery Item' : 'Create New Gallery Item'}</h2>
//             <form onSubmit={handleCreateOrUpdate}>
//                 <div className="form-group">
//                     <label htmlFor="galleryProductName">Product Name</label>
//                     <input
//                         type="text"
//                         className="form-control"
//                         id="galleryProductName"
//                         value={galleryProductName}
//                         onChange={(e) => setGalleryProductName(e.target.value)}
//                         required
//                     />
//                 </div>
//                 <div className="form-group">
//                     <label htmlFor="galleryProductImage">Product Image</label>
//                     <input
//                         type="file"
//                         className="form-control-file"
//                         id="galleryProductImage"
//                         accept="image/*"
//                         onChange={(e) => setGalleryProductImage(e.target.files[0])}
//                     />
//                 </div>
//                 <button type="submit" className="btn btn-primary">
//                     {editingItem ? 'Update Gallery Item' : 'Create Gallery Item'}
//                 </button>
//             </form>
//             <h3 className="mt-5">Gallery Items</h3>
//             <ul className="list-group">
//                 {galleryItems.map(item => (
//                     <li key={item.id} className="list-group-item">
//                         <h5>{item.GalleryProductName}</h5>
//                         {item.GalleryProductImage && (
//                             <img
//                                 src={`data:image/jpeg;base64,${item.GalleryProductImage}`}
//                                 alt={item.GalleryProductName}
//                                 style={{ width: '100px', height: '100px' }}
//                             />
//                         )}
//                         <button className="btn btn-info btn-sm ml-2" onClick={() => handleEdit(item)}>Edit</button>
//                         <button className="btn btn-danger btn-sm ml-2" onClick={() => handleDelete(item.id)}>Delete</button>
//                     </li>
//                 ))}
//             </ul>
//         </div>
//     );
// };

// export default ManageGalleryItems;
