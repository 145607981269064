// src/TechnoflexRect.js
import React from 'react';
import './TechnoflexRect.css';
import exampleImage from '../../Images/technooffice.jpg'; // Replace with your actual image path

const TechnoflexRect = () => {
  return (
    <div className="technoflex-rect">
      <div className="technoflex-rect-image">
        <img src={exampleImage} alt="Example" />
      </div>
      <div className="technoflex-rect-text">
        <h1>Touching Lives In Little Ways</h1>
        <p>
        At Techno Flex, we believe in giving back to the community that has supported our growth and success. Our commitment to social responsibility is reflected through various impactful initiatives. We actively engage in programs that promote education, support healthcare, provide financial aid to those in need, and contribute to the development of local communities. By investing in these areas, Techno Flex aims to foster a positive and sustainable future, ensuring that our advancements benefit not just our business but society as a whole.
        </p>
        <button>Techno Flex CSR Initiatives</button>
      </div>
    </div>
  );
};

export default TechnoflexRect;
