import React, { useState } from 'react';
import './Carousel.css'; // Import your CSS file for styling
import techpro from '../../Images/tf1.png'; // Import your background image
import techpro1 from '../../Images/tf2.png'; // Import your background image
import techpro2 from '../../Images/tf3.png'; // Import your background image
import overlayImage from '../../Images/technooffice.jpg'; // Import overlay image

const Carousel = () => {
  const [currentSlide, setCurrentSlide] = useState(0); // State to track current slide index

  const images = [
    {
      background: techpro,
      overlayText: 'Slide 1 label',
      overlayDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      overlayImage: overlayImage
    },
    {
      background: techpro1,
      overlayText: 'Slide 2 label',
      overlayDescription: 'Nulla vitae elit libero, a pharetra augue mollis interdum.',
      overlayImage: overlayImage
    },
    {
      background: techpro2,
      overlayText: 'Slide 3 label',
      overlayDescription: 'Praesent commodo cursus magna, vel scelerisque nisl consectetur.',
      overlayImage: overlayImage
    }
  ];

  const prevSlide = () => {
    const newIndex = (currentSlide - 1 + images.length) % images.length;
    setCurrentSlide(newIndex);
  };

  const nextSlide = () => {
    const newIndex = (currentSlide + 1) % images.length;
    setCurrentSlide(newIndex);
  };

  return (
    <div className="carousel">
      <div className="carousel-inner">
        {images.map((slide, index) => (
          <div
            key={index}
            className={index === currentSlide ? "carousel-slide active" : "carousel-slide"}
            style={{ backgroundImage: `url(${slide.background})` }}
          >
            <div className="carousel-overlay">
              <div className="overlay-content">
                <h3>{slide.overlayText}</h3>
                <p>{slide.overlayDescription}</p>
                <img src={slide.overlayImage} alt="Overlay Image" className="overlay-image" />
              </div>
            </div>
          </div>
        ))}
      </div>
      <a className="carousel-control prev" onClick={prevSlide}>&#10094;</a>
      <a className="carousel-control next" onClick={nextSlide}>&#10095;</a>
    </div>
  );
};

export default Carousel;


