import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

const ManageProductDetails = () => {
    const [productDetails, setProductDetails] = useState([]);

    useEffect(() => {
        const fetchProductDetails = async () => {
            try {
                const response = await axios.get('http://localhost:8000/api/productdetails');
                setProductDetails(response.data);
            } catch (error) {
                console.error('Error fetching product details:', error);
            }
        };

        fetchProductDetails();
    }, []);

    const handleDelete = async (id) => {
        try {
            await axios.delete(`http://localhost:8000/api//api/productdetails/${id}`);
            setProductDetails(productDetails.filter(detail => detail.DetailID !== id));
        } catch (error) {
            console.error('Error deleting product details:', error);
        }
    };

    return (
        <div className="container mt-5">
            <h2>Manage Product Details</h2>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Product ID</th>
                        <th>Material</th>
                        <th>Size</th>
                        <th>Usage Application</th>
                        <th>Brand</th>
                        <th>Connection Type</th>
                        <th>Color</th>
                        <th>Country of Origin</th>
                        <th>Minimum Order Quantity</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {productDetails.map(detail => (
                        <tr key={detail.DetailID}>
                            <td>{detail.DetailID}</td>
                            <td>{detail.ProductID}</td>
                            <td>{detail.Material}</td>
                            <td>{detail.Size}</td>
                            <td>{detail.UsageApplication}</td>
                            <td>{detail.Brand}</td>
                            <td>{detail.ConnectionType}</td>
                            <td>{detail.Color}</td>
                            <td>{detail.CountryOfOrigin}</td>
                            <td>{detail.MinimumOrderQuantity}</td>
                            <td>
                                <Link to={`/product-details/view/${detail.DetailID}`} className="btn btn-info btn-sm">View</Link>
                                <Link to={`/product-details/edit/${detail.DetailID}`} className="btn btn-warning btn-sm ml-2">Edit</Link>
                                <button className="btn btn-danger btn-sm ml-2" onClick={() => handleDelete(detail.DetailID)}>Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ManageProductDetails;
