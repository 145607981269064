// import React, { useState, useEffect } from 'react';
// import './award.css';
// import cer1 from '../../Images/cer1.jpg';
// const CardCarousel = ({ cards }) => {
//   const [startIndex, setStartIndex] = useState(0);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setStartIndex((prevIndex) => (prevIndex + 1) % cards.length);
//     }, 5000); // Change cards every 5 seconds

//     return () => clearInterval(interval);
//   }, [cards.length]);

//   const nextCards = () => {
//     setStartIndex((prevIndex) => (prevIndex + 1) % cards.length);
//   };

//   const prevCards = () => {
//     setStartIndex((prevIndex) => (prevIndex - 1 + cards.length) % cards.length);
//   };

//   const goToCard = (index) => {
//     setStartIndex(index);
//   };

//   return (
//     <div className="cardd-carousel-wrapper">
//       <button className="navv-button prev" onClick={prevCards}>&lt;</button>
//       <div className="cardd-carousel">
//         <div className="cardd-container">
//           {[0, 1, 2].map((offset) => {
//             const index = (startIndex + offset) % cards.length;
//             return (
//               <div key={index} className="cardd">
//                 <img src={cards[index].image} alt={cards[index].heading} />
//                 <h2>{cards[index].heading}</h2>
//                 <p>{cards[index].paragraph}</p>
//               </div>
//             );
//           })}
//         </div>
//         <div className="dots-container">
//           {cards.map((_, index) => (
//             <span
//               key={index}
//               className={`dot ${index === startIndex ? 'active' : ''}`}
//               onClick={() => goToCard(index)}
//             ></span>
//           ))}
//         </div>
//       </div>
//       <button className="navv-button next" onClick={nextCards}>&gt;</button>
//     </div>
//   );
// };

// const Award = () => {
//   const cards = [
//     {
//       image: {cer1},
//       heading: 'NSIC Certifcate',
//       paragraph: 'The units registered under Single Point Registration Scheme of NSIC are eligible to get the benefits under ?Public Procurement Policy for Micro & Small Enterprises (MSEs) Order 2012? as notified by the Government of India, Ministry of Micro Small & Medium Enterprises, New Delhi vide Gazette Notification dated 23.03.2012.'
//     },
//     {
//       image: 'https://picsum.photos/id/1015/300/200',
//       heading: 'River Stream',
//       paragraph: 'A serene river flowing through a forest.'
//     },
//     {
//       image: 'https://picsum.photos/id/1019/300/200',
//       heading: 'Cloudy Peak',
//       paragraph: 'A mountain peak shrouded in clouds.'
//     },
//     {
//       image: 'https://picsum.photos/id/1016/300/200',
//       heading: 'Autumn Forest',
//       paragraph: 'A colorful forest during autumn season.'
//     },
//     {
//       image: 'https://picsum.photos/id/1020/300/200',
//       heading: 'Bear Creek',
//       paragraph: 'A peaceful creek running through the woods.'
//     },
//   ];

//   return (
//     <div className="Award">
//       <h1>Awards & Memberships</h1>
//       <CardCarousel cards={cards} />
//     </div>
//   );
// };

// export default Award;

import React, { useState, useEffect, useRef } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import './award.css';

// Import images
import nsic from '../../Images/nsiclogo.jpg';
import iso from '../../Images/isologo.jpg';
import fieo from '../../Images/fieologo.jpg';
import compliance from '../../Images/Compliancelogo.jpg';
import nsic1 from '../../Images/nsic-certificate.jpg'; // Imported certificate images
import iso1 from '../../Images/iso.jpg';
import fieo1 from '../../Images/fieo.jpg';
import compliance1 from '../../Images/ce-marking1.jpg';
import compliance2 from '../../Images/compliance2.jpg';
import nsic2 from '../../Images/nsic1.jpg'; 
const awardsData = [
  {
    logo: nsic,
    year: "2013",
    title: " Grants Techno Flex premium tender benefits and supply priority",
    pdfLink: nsic1 // Use the imported image path for certificate
  },
  {
    logo: iso,
    year: "2017",
    title: "Certifies Techno Flex’s adherence to international quality management standards.",
    pdfLink: iso1
  },
  {
    logo: fieo,
    year: "2017",
    title: "Shows Techno Flex’s involvement in supporting and promoting Indian exports.",
    pdfLink: fieo1
  },
  {
    logo: compliance,
    year: "2017",
    title: "Confirms Techno Flex’s adherence to specific industry standards and regulations.",
    pdfLink: compliance1
  },
  {
    logo: nsic,
    year: "2013",
    title: " Grants Techno Flex premium tender benefits and supply priority",
    pdfLink: nsic2 // Use the imported image path for certificate
  },

  {
    logo: compliance,
    year: "2017",
    title: "Confirms Techno Flex’s adherence to specific industry standards and regulations.",
    pdfLink: compliance2
  },
  // Add more awards as needed
];

const AwardCard = ({ award }) => (
  <div className="award-card">
    <img src={award.logo} alt={award.title} />
    <h4>{award.year}</h4>
    <p>{award.title}</p>
    <a href={award.pdfLink} target="_blank" rel="noopener noreferrer" className="view-certificate">
      View Certificate
    </a>
  </div>
);

const Awards = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const cardsPerPage = 1; // Scroll one card at a time
  const containerRef = useRef(null);
  const cardWidth = 300; // Width of one card in pixels

  const totalPages = Math.ceil(awardsData.length / cardsPerPage);

  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 5000); // Auto slide every 5 seconds

    return () => clearInterval(interval);
  }, [currentIndex]);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = (prevIndex - 1 + totalPages) % totalPages;
      scrollToIndex(newIndex);
      return newIndex;
    });
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = (prevIndex + 1) % totalPages;
      scrollToIndex(newIndex);
      return newIndex;
    });
  };

  const handleDotClick = (index) => {
    setCurrentIndex(index);
    scrollToIndex(index);
  };

  const scrollToIndex = (index) => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        left: index * cardWidth,
        behavior: 'smooth'
      });
    }
  };

  return (
    <section className="awards-section">
      <div className="awards-container">
        <div className="awards-title">
          <h2>
            <span>Awards</span> & Accolades
          </h2>
          <p>
            Techno Flex has set the benchmark in the flexible conduit industry since 2011, consistently delivering products that adhere to the highest standards of quality and innovation. Our unwavering commitment to excellence has earned us prestigious awards, accolades, and global recognition, establishing us as a trusted leader in our field.
          </p>
        </div>
        <div className="awards-slider">
          <button className="nav-button prev" onClick={handlePrev}>
            <ChevronLeft size={24} />
          </button>
          <div className="awards-scroll-container" ref={containerRef}>
            {awardsData.map((award, index) => (
              <AwardCard key={index} award={award} />
            ))}
          </div>
          <button className="nav-button next" onClick={handleNext}>
            <ChevronRight size={24} />
          </button>
        </div>
        <div className="dots-container">
          {Array.from({ length: totalPages }).map((_, index) => (
            <button
              key={index}
              className={`dot ${index === currentIndex ? 'active' : ''}`}
              onClick={() => handleDotClick(index)}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Awards;
