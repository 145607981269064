import React from 'react';
import './TechnoflexOverview.css';
import tecban from '../../Images/newban.jpg'
import tecban1 from '../../Images/technooffice.jpg'
const TechnoflexOverview = () => {
    return (
        <div className="overview">
            <div className="banner">
                <img src={tecban} alt="Banner" />
            </div>
            <div className="content">
                <h1>
                    <span style={{ fontSize: '40px', color: 'rgb(19, 162, 194)' }}>Founded in 2011,</span>
                    <br />
                    Techno Flex: A Legacy of Excellence in Flexible Conduits
                </h1>
                <p>
                As a leading manufacturer in the industry, Techno Flex processes a significant volume of flexible conduit products annually, offering a broad and diverse selection to meet various application needs. Our commitment to quality and innovation is central to our mission and vision, ensuring that we consistently deliver products that adhere to the highest standards.
                </p>
            </div>
            <div className="infoSection">
                <div className="infoCard">
                    <h1>Our Vision</h1>
                    <p>To drive industry growth and innovation by offering the most comprehensive range of flexible conduit solutions, contributing significantly to infrastructure development and safety across diverse sectors globally.</p>
                </div>
                <div className="infoCard secondary">
                    <h1>Our Mission</h1>
                    <p>To be the leading provider of flexible conduit solutions by delivering exceptional value and quality in every product we offer. We aim to enhance our customers' daily operations while fostering a culture of respect, innovation, and empowerment for all our stakeholders.</p>
                </div>
            </div>
            <div className="headingSection">
                <h1>What makes us <span style={{ color: 'rgb(19, 162, 194)' }}>Techno Flex</span>?</h1>
                <p>Our commitment to excellence sets us apart as a leader in the industry. We strive to establish new benchmarks in flexible conduit solutions through our innovative approaches, strong relationships, and dedication to quality. Our ongoing efforts impact various sectors, driving progress and reliability in every application.</p>
            </div>
            <div className="statsSection">
                <div className="statsCard">
                    <div className="statsDetails">
                        <p><span style={{ color: 'rgb(19, 162, 194)', fontSize: '25px' }}>10000 CR</span><br />Annual Turnover</p>
                        <p><span style={{ color: 'rgb(19, 162, 194)', fontSize: '25px' }}>640000+ MT</span><br />Products sold</p>
                        <p><span style={{ color: 'rgb(19, 162, 194)', fontSize: '25px' }}>5500+</span><br />Employee Strength</p>
                    </div>
                </div>
                <div className="statsCard secondary">
                    <div className="statsDetails">
                        <p><span style={{ color: 'rgb(19, 162, 194)', fontSize: '25px' }}>55+</span><br />Export Countries</p>
                        <p><span style={{ color: 'rgb(19, 162, 194)', fontSize: '25px' }}>5000+</span><br />Distributors</p>
                        <p><span style={{ color: 'rgb(19, 162, 194)', fontSize: '25px' }}>8</span><br />Business Verticals</p>
                    </div>
                </div>
                <div className="statsCard tertiary">
                    <div className="statsDetails">
                        <p><span style={{ color: 'rgb(19, 162, 194)', fontSize: '25px' }}>29</span><br />Manufacturing Plants</p>
                        <p><span style={{ color: 'rgb(19, 162, 194)', fontSize: '25px' }}>AA+</span><br />CRISIL Stable Rating</p>
                    </div>
                </div>
            </div>
            <div className="headingSection">
                <h1>Pillars of <span style={{ color: 'rgb(19, 162, 194)' }}>Growth</span>?</h1>
                <p style={{ width: '83%' }}>Since our inception in 2011, Techno Flex has been driven by a vision of robust growth and industry excellence. Our journey is guided by core pillars of innovation, quality, and customer satisfaction. These foundational elements have empowered us to achieve significant milestones and continue our ascent as a leading provider of flexible conduit solutions.</p>
            </div>
            <div className="infoSection">
                <div className="infoCard">
                    <img src={tecban1} alt="Pillar 8" />
                </div>
                <div className="infoCard secondary">
                    <h1>Diverse Product Range</h1>
                    <p>At Techno Flex, we pride ourselves on our extensive portfolio of flexible conduit solutions, designed to meet a wide array of application needs. Our diverse range of products, including flexible conduit accessories, aluminum conduits, and GI steel conduits, is renowned for its quality and innovation. We continuously exceed customer expectations with our comprehensive selection, offering solutions that cater to various industry requirements.</p>
                </div>
            </div>
            <div className="infoSection">
                <div className="infoCard secondary">
                    <h1>Unparalleled Innovations</h1>
                    <p>At Techno Flex, innovation is at the heart of everything we do. Our dedicated R&D team is committed to developing cutting-edge flexible conduit solutions that meet evolving market needs and enhance the quality of life. We prioritize continuous improvement and sustainability, striving to minimize our carbon footprint through advanced manufacturing processes and eco-friendly practices.</p>
                </div>
                <div className="infoCard">
                    <img src={tecban1} alt="Innovation" />
                </div>
            </div>
            <div className="infoSection">
                <div className="infoCard">
                    <img src={tecban1} alt="Manufacturing Plants" />
                </div>
                <div className="infoCard secondary">
                    <h1>State-of-the-Art Manufacturing Facilities</h1>
                    <p>At Techno Flex, we operate with advanced manufacturing and distribution capabilities spread across key locations. Our facilities are equipped with cutting-edge technology to produce high-quality flexible conduit solutions. With a robust network of state-of-the-art plants, we ensure efficiency and precision in every product we deliver. Our largest facility is strategically designed to enhance production and distribution, enabling us to better meet the needs of our global customers and partners.</p>
                </div>
            </div>
            <div className="infoSection">
                <div className="infoCard secondary">
                    <h1>Advanced Upskilling Initiatives</h1>
                    <p>At Techno Flex, we prioritize continuous learning and professional development through our specialized Knowledge Centres. These centres are designed to enhance the skills of professionals such as plumbers, contractors, consultants, and architects by providing hands-on training and comprehensive insights into our flexible conduit solutions. Our Knowledge Centres are strategically located in key areas, including  Indore, ensuring that our partners across these regions have access to the expertise needed to excel in their fields.</p>
                </div>
                <div className="infoCard">
                    <img src={tecban1} alt="Upskilling Initiatives" />
                </div>
            </div>
            <div className="headingSection">
                <h1>Team</h1>
                <p>At Techno Flex, our journey of growth is driven by the collective dedication and passion of our team. Our success is a testament to the efforts of a diverse group of professionals who are united by a common vision. With a commitment to innovation and excellence, our team members play a crucial role in shaping the future of our industry. Over the years, Techno Flex has fostered a collaborative environment where each individual contributes significantly to our shared goals, helping us build a promising and dynamic future.

</p>
            </div>
            <div className="teamSection">
                <div className="teamCard">
                    <img src={tecban1} alt="Shri B. L. Taparia" />
                    <h2>Mr. Prashant Gadekar</h2>
                    <p style={{ marginRight: '60%' }}>Chairman</p>
                </div>
                <div className="teamCard">
                    <img src={tecban1} alt="Shri M. P. Taparia" />
                    <h2>Mr. Prashant Gadekar</h2>
                    <p>Managing Director</p>
                </div>
                <div className="teamCard">
                    <img src={tecban1}alt="Shri S. J. Taparia" />
                    <h2>Mr. Prashant Gadekar</h2>
                    <p>Executive Director</p>
                </div>
                <div className="teamCard">
                    <img src={tecban1} alt="Shri V. K. Taparia" />
                    <h2>Mr. Prashant Gadekar</h2>
                    <p>Executive Director</p>
                </div>
            </div>
            <div className="teamSection secondary">
                <div className="teamCard">
                    <img src={tecban1} alt="Shri R. Kannan" />
                    <h2>Mr. Prashant Gadekar</h2>
                    <p style={{ marginRight: '30%' }}>Independent Director</p>
                </div>
                <div className="teamCard">
                    <img src={tecban1} alt="Shri R. M. Pandia" />
                    <h2>Mr. Prashant Gadekar</h2>
                    <p>Independent Director</p>
                </div>
                <div className="teamCard">
                    <img src={tecban1} alt="Ms. Ameeta Parpia" />
                    <h2>Mr. Prashant Gadekar</h2>
                    <p>Independent Director</p>
                </div>
                <div className="teamCard">
                    <img src={tecban1} alt="Shri Sarthak Behuria" />
                    <h2>Mr. Prashant Gadekar</h2>
                    <p>Independent Director</p>
                </div>
            </div>
            <div className="teamSection secondary">
                <div className="teamCard">
                    <img src={tecban1} alt="Shri Vipul Shah" />
                    <h2>Mr. Prashant Gadekar</h2>
                    <p style={{ marginRight: '30%' }}>Independent Director</p>
                </div>
                <div className="teamCard">
                    <img src={tecban1} alt="Shri Pulak Prasad" />
                    <h2>Mr. Prashant Gadekar</h2>
                    <p>Non-Executive Director</p>
                </div>
            </div>
        </div>
    );
};

export default TechnoflexOverview;
