import React, { useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

const CreateProduct = () => {
    const [productName, setProductName] = useState('');
    const [category, setCategory] = useState('');
    const [price, setPrice] = useState('');
    const [shortDesc, setShortDesc] = useState('');
    const [description, setDescription] = useState('');
    const [avgRating, setAvgRating] = useState('');
    const [productImage, setProductImage] = useState(null);

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        const formData = new FormData();
        formData.append('ProductName', productName);
        formData.append('Category', category);
        formData.append('Price', price);
        formData.append('ShortDesc', shortDesc);
        formData.append('Description', description);
        formData.append('AvgRating', avgRating);
        if (productImage) {
            formData.append('ProductImage', productImage);
        }

        try {
            await axios.post('http://localhost:8000/api/products', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            alert('Product created successfully!');
        } catch (error) {
            console.error('Error creating product:', error);
            alert('Error creating product.');
        }
    };

    return (
        <div className="container mt-5">
            <h2>Create New Product</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="productName">Product Name</label>
                    <input
                        type="text"
                        className="form-control"
                        id="productName"
                        value={productName}
                        onChange={(e) => setProductName(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="category">Category</label>
                    <input
                        type="text"
                        className="form-control"
                        id="category"
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="price">Price</label>
                    <input
                        type="text"
                        className="form-control"
                        id="price"
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="shortDesc">Short Description</label>
                    <textarea
                        className="form-control"
                        id="shortDesc"
                        value={shortDesc}
                        onChange={(e) => setShortDesc(e.target.value)}
                        rows="3"
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="description">Description</label>
                    <textarea
                        className="form-control"
                        id="description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        rows="5"
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="avgRating">Average Rating</label>
                    <input
                        type="number"
                        className="form-control"
                        id="avgRating"
                        value={avgRating}
                        onChange={(e) => setAvgRating(e.target.value)}
                        step="0.1"
                        min="0"
                        max="5"
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="productImage">Product Image</label>
                    <input
                        type="file"
                        className="form-control-file"
                        id="productImage"
                        onChange={(e) => setProductImage(e.target.files[0])}
                        required
                    />
                </div>
                <button type="submit" className="btn btn-primary">Create Product</button>
            </form>
        </div>
    );
};

export default CreateProduct;
