import { lazy, Suspense } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NavBar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import Loader from "./components/Loader/Loader";
import Sidebar from "./components/sideBar/Sidebar";
import { ToastContainer } from "react-toastify";
import Location from './components/Location/Location';
import TechnflexLocation from './components/Location/TechnflexLocation';

import TechnoflexRect from './components/Location/TechnoflexRect';


import ImpactPage from './components/Location/ImpactPage';
import InvestorSection from './components/Location/InvestorSection';
import TechnoflexHomeSlider from './components/Location/TechnoflexHomeSlider';
import MarketArea from './components/Location/MarketArea';
import Login from "./pages/authentication/Login";
import PageNotFound from "./pages/CommonPages/NotFound";
import ContactUs from "./pages/ContactUs/ContactUs";
import MainContactUs from "./pages/ContactUs/MainContactUs";
import Award from "./pages/awards/award";
import Update from "./pages/Updates/update";
import CreateProduct from './pages/productcreate/CreateProduct';
import ProductList from './pages/productcreate/ProductList';
import CreateProductDetails from './pages/productcreate/CreateProductDetails';
import ManageProductDetails from './pages/productcreate/ManageProductDetails';
import ProductDetail from './pages/productcreate/ManageProductDetails';

import ManageProduct from './pages/productcreate/ProductManage';
import TechnoflexMilestones from './pages/About/TechnoflexMilestones';
import TechnoflexOverview from './pages/About/TechnoflexOverview';
import TechnoflexSustainability from './pages/About/TechnoflexSustainability';
import EditProductDetails from './pages/productcreate/EditProductDetails';
import Brochure from './pages/Brochures/Brochures';
import ProductGallery from './pages/ProductGallery/ProductGallery';
import ManageGalleryItems from './pages/ProductGallery/GallaryView';
import Dashboard from './pages/dashboard/Dashboard';
import Countries from './pages/MyLocation/contries';
import Contry from './pages/MyLocation/viewcontry';
import ImpactSection from './pages/Impact/ImpactSection';
import ScrollToTopButton from './components/Scrollup/ScrollToTopButton';

// import Investor from "./pages/Investor/Investor";
import "react-toastify/dist/ReactToastify.css";

const Home = lazy(() => import("./pages/Home"));
const Shop = lazy(() => import("./pages/Shop"));
// const Cart = lazy(() => import("./pages/Cart"));
const Product = lazy(() => import("./pages/Product"));

function App() {
  return (
    <Suspense fallback={<Loader />}>
      <Router>
        <ToastContainer
          position="top-right"
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/shop/:id" element={<Product />} />
          <Route path="/location" element={<Location />} />
          <Route path="/tfloc" element={<TechnflexLocation />} />
          <Route path="/login" element={<Login />} />
          <Route path="/pagenotfound" element={<PageNotFound  />} />
          <Route path="/contactus" element={<ContactUs  />} />
          <Route path="/maincontactus" element={<MainContactUs  />} />
          <Route path="/award" element={<Award  />} />
          <Route path="/update" element={<Update  />} />
          <Route path="/tfhs" element={<TechnoflexHomeSlider />} />
          <Route path="/tfre" element={<TechnoflexRect />} />
          <Route path="/inv" element={<InvestorSection  />} />

          <Route path="/impact" element={<ImpactSection  />} />
          <Route path="/pc" element={<CreateProduct />} />
          <Route path="/pl" element={<ProductList  />} />
          <Route path="/sidebar" element={<Sidebar />} />
          <Route path="/brochure" element={<Brochure />} />
          <Route path="/pg" element={<ProductGallery />} />
          <Route path="/pv" element={<ManageGalleryItems />} />
          <Route path="/ma" element={<MarketArea />} />
          <Route path="/tfove" element={<TechnoflexOverview/>} />
          <Route path="/tfmile" element={<TechnoflexMilestones/>} />
          <Route path="/tfsus" element={<TechnoflexSustainability/>} />
          <Route path="/create-product-details" element={<CreateProductDetails />} />
                <Route path="/product-details" element={<ManageProductDetails />} />
                <Route path="/mp" element={<ManageProduct />} />
                <Route path="/product-details/view/:id" element={<ProductDetail />} />
                <Route path="/product-details/edit/:id" element={<EditProductDetails />} />
                <Route path="/mpd" element={<ManageProductDetails />} />
                <Route path="/dashboard" element={<Dashboard  />} />
                <Route path="/countries" element={<Countries  />} />
                <Route path="/vc" element={<Contry  />} />
          {/* <Route path="/investor" element={<Investor  />} /> */}
          {/* <Route path="/cart" element={<Cart />} /> */}
        
        </Routes>
        <ScrollToTopButton />
        <Footer />
      </Router>
    </Suspense>
  );
}

export default App;
