// import React, { useState } from 'react';
// import { Button, Stepper, Step, StepLabel, TextField, DialogContent, DialogActions, Backdrop } from '@mui/material';
// import styled from 'styled-components';
// import Swal from 'sweetalert2';
// import axios from 'axios';

// const Container = styled.div`
//   max-width: 800px;
//   min-height: 100vh;
//   margin: 0;
//   padding: 30px;
// `;

// const CreateButton = styled(Button)`
//   width: 30%;
//   font-size: 16px;
//   padding: 12px 24px;
//   background-color: #4caf50;
//   color: #fff;
//   border-radius: 20px;
//   transition: background-color 0.3s, color 0.3s;

//   &:hover {
//     background-color: #388e3c;
//   }

//   &:focus {
//     outline: none;
//   }
// `;

// const StyledButton = styled(Button)`
//   font-size: 16px;
// `;

// const StyledStepper = styled(Stepper)`
//   font-size: 18px;
// `;

// const ManageProduct = () => {
//   const [loading, setLoading] = useState(false);
//   const [activeStep, setActiveStep] = useState(0);
//   const [formData, setFormData] = useState({
//     productName: '',
//     category: '',
//     price: '',
//     shortDesc: '',
//     description: '',
//     avgRating: '',
//     productImage: null,
//     material: '',
//     size: '',
//     usageApplication: '',
//     brand: '',
//     connectionType: '',
//     color: '',
//     countryOfOrigin: '',
//     minimumOrderQuantity: '',
//     productID: null,
//   });

//   const steps = ['Basic Information', 'Product Details', 'Review and Submit'];

//   const handleNext = () => setActiveStep(prevActiveStep => prevActiveStep + 1);
//   const handleBack = () => setActiveStep(prevActiveStep => prevActiveStep - 1);
//   const handleReset = () => {
//     setActiveStep(0);
//     setFormData({
//       productName: '',
//       category: '',
//       price: '',
//       shortDesc: '',
//       description: '',
//       avgRating: '',
//       productImage: null,
//       material: '',
//       size: '',
//       usageApplication: '',
//       brand: '',
//       connectionType: '',
//       color: '',
//       countryOfOrigin: '',
//       minimumOrderQuantity: '',
//       productID: null,
//     });
//   };

//   const handleFormChange = (event) => {
//     const { name, value, type, files } = event.target;
//     setFormData({
//       ...formData,
//       [name]: type === 'file' ? files[0] : value
//     });
//   };

//   const handleSubmit = async () => {
//     setLoading(true);
//     try {
//       if (activeStep === 0) {
//         // Step 1: Create Product
//         const productFormData = new FormData();
//         Object.keys(formData).forEach(key => {
//           if (key !== 'productID' && formData[key] !== null && formData[key] !== '') {
//             productFormData.append(key, formData[key]);
//           }
//         });

//         const productResponse = await axios.post('http://localhost:8000/api/products', productFormData, {
//           headers: { 'Content-Type': 'multipart/form-data' }
//         });

//         setFormData(prevData => ({ ...prevData, productID: productResponse.data.productID }));
//         handleNext();
//       } else if (activeStep === 1) {
//         // Step 2: Add Product Details
//         const productDetails = {
//           ProductID: formData.productID,
//           Material: formData.material,
//           Size: formData.size,
//           UsageApplication: formData.usageApplication,
//           Brand: formData.brand,
//           ConnectionType: formData.connectionType,
//           Color: formData.color,
//           CountryOfOrigin: formData.countryOfOrigin,
//           MinimumOrderQuantity: formData.minimumOrderQuantity,
//         };

//         await axios.post('http://localhost:8000/api/productdetails', productDetails);
//         Swal.fire({
//           title: 'Success',
//           text: 'Product details created successfully!',
//           icon: 'success'
//         }).then(() => {
//           handleReset();
//           setLoading(false);
//         });
//       }
//     } catch (error) {
//       console.error('Error:', error);
//       Swal.fire({
//         title: 'Error',
//         text: 'An error occurred while creating the product or product details.',
//         icon: 'error'
//       });
//       setLoading(false);
//     }
//   };

//   const isStepDisabled = step => {
//     if (step === 0) {
//       return !formData.productName || !formData.category || !formData.price || !formData.shortDesc || !formData.description || !formData.avgRating || !formData.productImage;
//     } else if (step === 1) {
//       return !formData.material || !formData.size || !formData.usageApplication || !formData.brand || !formData.connectionType || !formData.color || !formData.countryOfOrigin || !formData.minimumOrderQuantity;
//     }
//     return false;
//   };

//   return (
//     <Container>
//       <Backdrop open={loading} style={{ zIndex: 30 }}>
//         <div id="load">
//           <div>X</div>
//           <div>E</div>
//           <div>L</div>
//           <div>F</div>
//           <div>O</div>
//           <div>N</div>
//           <div>H</div>
//           <div>C</div>
//           <div>E</div>
//           <div>T</div>
//         </div>
//       </Backdrop>
//       <StyledStepper activeStep={activeStep}>
//         {steps.map((label) => (
//           <Step key={label}>
//             <StepLabel>{label}</StepLabel>
//           </Step>
//         ))}
//       </StyledStepper>
//       <div>
//         {activeStep === 0 && (
//           <DialogContent>
//             <TextField
//               name="productName"
//               label="Product Name"
//               value={formData.productName}
//               onChange={handleFormChange}
//               fullWidth
//               margin="normal"
//             />
//             <TextField
//               name="category"
//               label="Category"
//               value={formData.category}
//               onChange={handleFormChange}
//               fullWidth
//               margin="normal"
//             />
//             <TextField
//               name="price"
//               label="Price"
//               value={formData.price}
//               onChange={handleFormChange}
//               fullWidth
//               margin="normal"
//             />
//             <TextField
//               name="shortDesc"
//               label="Short Description"
//               value={formData.shortDesc}
//               onChange={handleFormChange}
//               fullWidth
//               margin="normal"
//             />
//             <TextField
//               name="description"
//               label="Description"
//               value={formData.description}
//               onChange={handleFormChange}
//               fullWidth
//               margin="normal"
//             />
//             <TextField
//               name="avgRating"
//               label="Average Rating"
//               type="number"
//               value={formData.avgRating}
//               onChange={handleFormChange}
//               fullWidth
//               margin="normal"
//               inputProps={{ step: "0.1", min: "0", max: "5" }}
//             />
//             <input
//               name="productImage"
//               type="file"
//               onChange={handleFormChange}
//               accept="image/*"
//               required
//             />
//           </DialogContent>
//         )}
//         {activeStep === 1 && (
//           <DialogContent>
//             <TextField
//               name="material"
//               label="Material"
//               value={formData.material}
//               onChange={handleFormChange}
//               fullWidth
//               margin="normal"
//             />
//             <TextField
//               name="size"
//               label="Size"
//               value={formData.size}
//               onChange={handleFormChange}
//               fullWidth
//               margin="normal"
//             />
//             <TextField
//               name="usageApplication"
//               label="Usage Application"
//               value={formData.usageApplication}
//               onChange={handleFormChange}
//               fullWidth
//               margin="normal"
//             />
//             <TextField
//               name="brand"
//               label="Brand"
//               value={formData.brand}
//               onChange={handleFormChange}
//               fullWidth
//               margin="normal"
//             />
//             <TextField
//               name="connectionType"
//               label="Connection Type"
//               value={formData.connectionType}
//               onChange={handleFormChange}
//               fullWidth
//               margin="normal"
//             />
//             <TextField
//               name="color"
//               label="Color"
//               value={formData.color}
//               onChange={handleFormChange}
//               fullWidth
//               margin="normal"
//             />
//             <TextField
//               name="countryOfOrigin"
//               label="Country of Origin"
//               value={formData.countryOfOrigin}
//               onChange={handleFormChange}
//               fullWidth
//               margin="normal"
//             />
//             <TextField
//               name="minimumOrderQuantity"
//               label="Minimum Order Quantity"
//               value={formData.minimumOrderQuantity}
//               onChange={handleFormChange}
//               fullWidth
//               margin="normal"
//             />
//           </DialogContent>
//         )}
//         {activeStep === 2 && (
//           <DialogContent>
//             <h3>Review Your Information</h3>
//             <div><strong>Product Name:</strong> {formData.productName}</div>
//             <div><strong>Category:</strong> {formData.category}</div>
//             <div><strong>Price:</strong> {formData.price}</div>
//             <div><strong>Short Description:</strong> {formData.shortDesc}</div>
//             <div><strong>Description:</strong> {formData.description}</div>
//             <div><strong>Average Rating:</strong> {formData.avgRating}</div>
//             <div><strong>Material:</strong> {formData.material}</div>
//             <div><strong>Size:</strong> {formData.size}</div>
//             <div><strong>Usage Application:</strong> {formData.usageApplication}</div>
//             <div><strong>Brand:</strong> {formData.brand}</div>
//             <div><strong>Connection Type:</strong> {formData.connectionType}</div>
//             <div><strong>Color:</strong> {formData.color}</div>
//             <div><strong>Country of Origin:</strong> {formData.countryOfOrigin}</div>
//             <div><strong>Minimum Order Quantity:</strong> {formData.minimumOrderQuantity}</div>
//             <div>
//               {formData.productImage && (
//                 <img src={URL.createObjectURL(formData.productImage)} alt="Product Preview" width="100" />
//               )}
//             </div>
//           </DialogContent>
//         )}
//       </div>
//       <DialogActions>
//         {activeStep > 0 && (
//           <StyledButton onClick={handleBack}>Back</StyledButton>
//         )}
//         {activeStep < steps.length - 1 ? (
//           <StyledButton
//             onClick={handleNext}
//             disabled={isStepDisabled(activeStep)}
//           >
//             {activeStep === steps.length - 2 ? 'Finish' : 'Next'}
//           </StyledButton>
//         ) : (
//           <CreateButton onClick={handleSubmit}>Submit</CreateButton>
//         )}
//       </DialogActions>
//     </Container>
//   );
// };

// export default ManageProduct;

// import React, { useState } from 'react';
// import axios from 'axios';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { Stepper, Step, StepLabel, Button, Typography, TextField, InputLabel, FormControl, Select, MenuItem } from '@mui/material';

// const ManageProduct = () => {
//     const [activeStep, setActiveStep] = useState(0);
//     const [productName, setProductName] = useState('');
//     const [category, setCategory] = useState('');
//     const [price, setPrice] = useState('');
//     const [shortDesc, setShortDesc] = useState('');
//     const [description, setDescription] = useState('');
//     const [avgRating, setAvgRating] = useState('');
//     const [productImage, setProductImage] = useState(null);

//     const [productID, setProductID] = useState('');
//     const [material, setMaterial] = useState('');
//     const [size, setSize] = useState('');
//     const [usageApplication, setUsageApplication] = useState('');
//     const [brand, setBrand] = useState('');
//     const [connectionType, setConnectionType] = useState('');
//     const [color, setColor] = useState('');
//     const [countryOfOrigin, setCountryOfOrigin] = useState('');
//     const [minimumOrderQuantity, setMinimumOrderQuantity] = useState('');

//     const steps = ['Product Information', 'Product Details'];

//     const handleNext = () => {
//         setActiveStep(prevStep => prevStep + 1);
//     };

//     const handleBack = () => {
//         setActiveStep(prevStep => prevStep - 1);
//     };

//     const handleSubmit = async (event) => {
//         event.preventDefault();
//         if (activeStep === 0) {
//             // Submit product creation form
//             const formData = new FormData();
//             formData.append('ProductName', productName);
//             formData.append('Category', category);
//             formData.append('Price', price);
//             formData.append('ShortDesc', shortDesc);
//             formData.append('Description', description);
//             formData.append('AvgRating', avgRating);
//             if (productImage) {
//                 formData.append('ProductImage', productImage);
//             }
//             try {
//                 await axios.post('http://localhost:8000/api/products', formData, {
//                     headers: { 'Content-Type': 'multipart/form-data' }
//                 });
//                 handleNext();
//             } catch (error) {
//                 console.error('Error creating product:', error);
//             }
//         } else if (activeStep === 1) {
//             // Submit product details form
//             try {
//                 await axios.post('http://localhost:8000/api/productdetails', {
//                     ProductID: productID,
//                     Material: material,
//                     Size: size,
//                     UsageApplication: usageApplication,
//                     Brand: brand,
//                     ConnectionType: connectionType,
//                     Color: color,
//                     CountryOfOrigin: countryOfOrigin,
//                     MinimumOrderQuantity: minimumOrderQuantity
//                 });
//                 alert('Product and details created successfully!');
//             } catch (error) {
//                 console.error('Error creating product details:', error);
//             }
//         }
//     };

//     return (
//         <div className="container mt-5">
//             <Stepper activeStep={activeStep} alternativeLabel>
//                 {steps.map((label) => (
//                     <Step key={label}>
//                         <StepLabel>{label}</StepLabel>
//                     </Step>
//                 ))}
//             </Stepper>
//             <div>
//                 {activeStep === 0 && (
//                     <form onSubmit={handleSubmit}>
//                         <Typography variant="h6" gutterBottom>Product Information</Typography>
//                         <TextField
//                             label="Product Name"
//                             fullWidth
//                             value={productName}
//                             onChange={(e) => setProductName(e.target.value)}
//                             margin="normal"
//                             required
//                         />
//                         <FormControl fullWidth margin="normal" required>
//                             <InputLabel>Category</InputLabel>
//                             <Select
//                                 value={category}
//                                 onChange={(e) => setCategory(e.target.value)}
//                             >
//                                 <MenuItem value="Electronics">Electronics</MenuItem>
//                                 <MenuItem value="Clothing">Clothing</MenuItem>
//                                 <MenuItem value="Home">Home</MenuItem>
//                             </Select>
//                         </FormControl>
//                         <TextField
//                             label="Price"
//                             type="number"
//                             fullWidth
//                             value={price}
//                             onChange={(e) => setPrice(e.target.value)}
//                             margin="normal"
//                             required
//                         />
//                         <TextField
//                             label="Short Description"
//                             multiline
//                             rows={3}
//                             fullWidth
//                             value={shortDesc}
//                             onChange={(e) => setShortDesc(e.target.value)}
//                             margin="normal"
//                             required
//                         />
//                         <TextField
//                             label="Description"
//                             multiline
//                             rows={5}
//                             fullWidth
//                             value={description}
//                             onChange={(e) => setDescription(e.target.value)}
//                             margin="normal"
//                             required
//                         />
//                         <TextField
//                             label="Average Rating"
//                             type="number"
//                             step="0.1"
//                             min="0"
//                             max="5"
//                             fullWidth
//                             value={avgRating}
//                             onChange={(e) => setAvgRating(e.target.value)}
//                             margin="normal"
//                             required
//                         />
//                         <input
//                             type="file"
//                             onChange={(e) => setProductImage(e.target.files[0])}
//                             required
//                         />
//                         <div className="mt-3">
//                             <Button
//                                 variant="contained"
//                                 color="primary"
//                                 type="submit"
//                             >
//                                 Next
//                             </Button>
//                         </div>
//                     </form>
//                 )}
//                 {activeStep === 1 && (
//                     <form onSubmit={handleSubmit}>
//                         <Typography variant="h6" gutterBottom>Product Details</Typography>
//                         <TextField
//                             label="Product ID"
//                             type="number"
//                             fullWidth
//                             value={productID}
//                             onChange={(e) => setProductID(e.target.value)}
//                             margin="normal"
//                             required
//                         />
//                         <TextField
//                             label="Material"
//                             fullWidth
//                             value={material}
//                             onChange={(e) => setMaterial(e.target.value)}
//                             margin="normal"
//                             required
//                         />
//                         <TextField
//                             label="Size"
//                             fullWidth
//                             value={size}
//                             onChange={(e) => setSize(e.target.value)}
//                             margin="normal"
//                             required
//                         />
//                         <TextField
//                             label="Usage/Application"
//                             fullWidth
//                             value={usageApplication}
//                             onChange={(e) => setUsageApplication(e.target.value)}
//                             margin="normal"
//                             required
//                         />
//                         <TextField
//                             label="Brand"
//                             fullWidth
//                             value={brand}
//                             onChange={(e) => setBrand(e.target.value)}
//                             margin="normal"
//                             required
//                         />
//                         <TextField
//                             label="Connection Type"
//                             fullWidth
//                             value={connectionType}
//                             onChange={(e) => setConnectionType(e.target.value)}
//                             margin="normal"
//                             required
//                         />
//                         <TextField
//                             label="Color"
//                             fullWidth
//                             value={color}
//                             onChange={(e) => setColor(e.target.value)}
//                             margin="normal"
//                             required
//                         />
//                         <TextField
//                             label="Country of Origin"
//                             fullWidth
//                             value={countryOfOrigin}
//                             onChange={(e) => setCountryOfOrigin(e.target.value)}
//                             margin="normal"
//                             required
//                         />
//                         <TextField
//                             label="Minimum Order Quantity"
//                             type="number"
//                             fullWidth
//                             value={minimumOrderQuantity}
//                             onChange={(e) => setMinimumOrderQuantity(e.target.value)}
//                             margin="normal"
//                             required
//                         />
//                         <div className="mt-3">
//                             <Button
//                                 variant="contained"
//                                 color="primary"
//                                 type="submit"
//                             >
//                                 Submit
//                             </Button>
//                             <Button
//                                 variant="outlined"
//                                 color="secondary"
//                                 onClick={handleBack}
//                                 style={{ marginLeft: 8 }}
//                             >
//                                 Back
//                             </Button>
//                         </div>
//                     </form>
//                 )}
//             </div>
//         </div>
//     );
// };

// export default ManageProduct;


import React, { useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import Swal from 'sweetalert2';
import { Stepper, Step, StepLabel, Button, Typography, TextField, InputLabel, FormControl, Select, MenuItem, Divider } from '@mui/material';
import styled from 'styled-components';

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
`;

const StepContainer = styled.div`
  margin-top: 20px;
`;

const CustomButton = styled(Button)`
  margin-top: 20px;
`;

const ReviewContainer = styled.div`
  margin-top: 20px;
`;

const ManageProduct = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [productName, setProductName] = useState('');
    const [category, setCategory] = useState('');
    const [price, setPrice] = useState('');
    const [shortDesc, setShortDesc] = useState('');
    const [description, setDescription] = useState('');
    const [avgRating, setAvgRating] = useState('');
    const [productImage, setProductImage] = useState(null);

    const [productID, setProductID] = useState('');
    const [material, setMaterial] = useState('');
    const [size, setSize] = useState('');
    const [usageApplication, setUsageApplication] = useState('');
    const [brand, setBrand] = useState('');
    const [connectionType, setConnectionType] = useState('');
    const [color, setColor] = useState('');
    const [countryOfOrigin, setCountryOfOrigin] = useState('');
    const [minimumOrderQuantity, setMinimumOrderQuantity] = useState('');

    const steps = ['Product Information', 'Product Details', 'Review and Submit'];

    const handleNext = () => {
        setActiveStep(prevStep => prevStep + 1);
    };

    const handleBack = () => {
        setActiveStep(prevStep => prevStep - 1);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (activeStep === 0) {
            // Submit product creation form
            const formData = new FormData();
            formData.append('ProductName', productName);
            formData.append('Category', category);
            formData.append('Price', price);
            formData.append('ShortDesc', shortDesc);
            formData.append('Description', description);
            formData.append('AvgRating', avgRating);
            if (productImage) {
                formData.append('ProductImage', productImage);
            }
            try {
                const response = await axios.post('http://localhost:8000/api/products', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });
                setProductID(response.data.productID);
                handleNext();
            } catch (error) {
                console.error('Error creating product:', error);
                Swal.fire({
                    title: 'Error',
                    text: 'There was an issue creating the product. Please try again.',
                    icon: 'error'
                });
            }
        } else if (activeStep === 1) {
            // Submit product details form
            try {
                await axios.post('http://localhost:8000/api/productdetails', {
                    ProductID: productID,
                    Material: material,
                    Size: size,
                    UsageApplication: usageApplication,
                    Brand: brand,
                    ConnectionType: connectionType,
                    Color: color,
                    CountryOfOrigin: countryOfOrigin,
                    MinimumOrderQuantity: minimumOrderQuantity
                });
                handleNext();
            } catch (error) {
                console.error('Error creating product details:', error);
                Swal.fire({
                    title: 'Error',
                    text: 'There was an issue adding product details. Please try again.',
                    icon: 'error'
                });
            }
        } else if (activeStep === 2) {
            // Final review and submit
            try {
                Swal.fire({
                    title: 'Confirm Submission',
                    text: 'Are you sure you want to submit the product details?',
                    icon: 'question',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, submit!',
                    cancelButtonText: 'Cancel'
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        Swal.fire({
                            title: 'Success',
                            text: 'Product and details created successfully!',
                            icon: 'success'
                        });
                        // Reset form or redirect as needed
                        setActiveStep(0);
                        setProductName('');
                        setCategory('');
                        setPrice('');
                        setShortDesc('');
                        setDescription('');
                        setAvgRating('');
                        setProductImage(null);
                        setProductID('');
                        setMaterial('');
                        setSize('');
                        setUsageApplication('');
                        setBrand('');
                        setConnectionType('');
                        setColor('');
                        setCountryOfOrigin('');
                        setMinimumOrderQuantity('');
                    }
                });
            } catch (error) {
                console.error('Error finalizing submission:', error);
                Swal.fire({
                    title: 'Error',
                    text: 'There was an issue finalizing the submission. Please try again.',
                    icon: 'error'
                });
            }
        }
    };

    return (
        <Container>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <StepContainer>
                {activeStep === 0 && (
                    <form onSubmit={handleSubmit}>
                        <Typography variant="h6" gutterBottom>Product Information</Typography>
                        <TextField
                            label="Product Name"
                            fullWidth
                            value={productName}
                            onChange={(e) => setProductName(e.target.value)}
                            margin="normal"
                            required
                        />
                        <FormControl fullWidth margin="normal" required>
                            <InputLabel>Category</InputLabel>
                            <Select
                                value={category}
                                onChange={(e) => setCategory(e.target.value)}
                            >
                                <MenuItem value="Electronics">Electronics</MenuItem>
                                <MenuItem value="Clothing">Clothing</MenuItem>
                                <MenuItem value="Home">Home</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            label="Price"
                            type="number"
                            fullWidth
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                            margin="normal"
                            required
                        />
                        <TextField
                            label="Short Description"
                            multiline
                            rows={3}
                            fullWidth
                            value={shortDesc}
                            onChange={(e) => setShortDesc(e.target.value)}
                            margin="normal"
                            required
                        />
                        <TextField
                            label="Description"
                            multiline
                            rows={5}
                            fullWidth
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            margin="normal"
                            required
                        />
                        <TextField
                            label="Average Rating"
                            type="number"
                            step="0.1"
                            min="0"
                            max="5"
                            fullWidth
                            value={avgRating}
                            onChange={(e) => setAvgRating(e.target.value)}
                            margin="normal"
                            required
                        />
                        <input
                            type="file"
                            onChange={(e) => setProductImage(e.target.files[0])}
                            required
                        />
                        <div className="mt-3">
                            <CustomButton
                                variant="contained"
                                color="primary"
                                type="submit"
                            >
                                Next
                            </CustomButton>
                        </div>
                    </form>
                )}
                {activeStep === 1 && (
                    <form onSubmit={handleSubmit}>
                        <Typography variant="h6" gutterBottom>Product Details</Typography>
                        <TextField
                            label="Material"
                            fullWidth
                            value={material}
                            onChange={(e) => setMaterial(e.target.value)}
                            margin="normal"
                            required
                        />
                        <TextField
                            label="Size"
                            fullWidth
                            value={size}
                            onChange={(e) => setSize(e.target.value)}
                            margin="normal"
                            required
                        />
                        <TextField
                            label="Usage/Application"
                            fullWidth
                            value={usageApplication}
                            onChange={(e) => setUsageApplication(e.target.value)}
                            margin="normal"
                            required
                        />
                        <TextField
                            label="Brand"
                            fullWidth
                            value={brand}
                            onChange={(e) => setBrand(e.target.value)}
                            margin="normal"
                            required
                        />
                        <TextField
                            label="Connection Type"
                            fullWidth
                            value={connectionType}
                            onChange={(e) => setConnectionType(e.target.value)}
                            margin="normal"
                            required
                        />
                        <TextField
                            label="Color"
                            fullWidth
                            value={color}
                            onChange={(e) => setColor(e.target.value)}
                            margin="normal"
                            required
                        />
                        <TextField
                            label="Country of Origin"
                            fullWidth
                            value={countryOfOrigin}
                            onChange={(e) => setCountryOfOrigin(e.target.value)}
                            margin="normal"
                            required
                        />
                        <TextField
                            label="Minimum Order Quantity"
                            type="number"
                            fullWidth
                            value={minimumOrderQuantity}
                            onChange={(e) => setMinimumOrderQuantity(e.target.value)}
                            margin="normal"
                            required
                        />
                        <div className="mt-3">
                            <CustomButton
                                variant="contained"
                                color="primary"
                                type="submit"
                            >
                                Next
                            </CustomButton>
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={handleBack}
                                style={{ marginLeft: 8 }}
                            >
                                Back
                            </Button>
                        </div>
                    </form>
                )}
                {activeStep === 2 && (
                    <ReviewContainer>
                        <Typography variant="h6" gutterBottom>Review and Submit</Typography>
                        <Typography><strong>Product Name:</strong> {productName}</Typography>
                        <Typography><strong>Category:</strong> {category}</Typography>
                        <Typography><strong>Price:</strong> {price}</Typography>
                        <Typography><strong>Short Description:</strong> {shortDesc}</Typography>
                        <Typography><strong>Description:</strong> {description}</Typography>
                        <Typography><strong>Average Rating:</strong> {avgRating}</Typography>
                        <Typography><strong>Material:</strong> {material}</Typography>
                        <Typography><strong>Size:</strong> {size}</Typography>
                        <Typography><strong>Usage/Application:</strong> {usageApplication}</Typography>
                        <Typography><strong>Brand:</strong> {brand}</Typography>
                        <Typography><strong>Connection Type:</strong> {connectionType}</Typography>
                        <Typography><strong>Color:</strong> {color}</Typography>
                        <Typography><strong>Country of Origin:</strong> {countryOfOrigin}</Typography>
                        <Typography><strong>Minimum Order Quantity:</strong> {minimumOrderQuantity}</Typography>
                        <Divider style={{ margin: '20px 0' }} />
                        <div className="mt-3">
                            <CustomButton
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                            >
                                Submit
                            </CustomButton>
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={handleBack}
                                style={{ marginLeft: 8 }}
                            >
                                Back
                            </Button>
                        </div>
                    </ReviewContainer>
                )}
            </StepContainer>
        </Container>
    );
};

export default ManageProduct;
