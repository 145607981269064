// MainContactUs.js
import React from 'react';
import './MainContactUs.css'; // Ensure the CSS file is in the same directory or adjust the path
import ContactUs from './ContactUs'; // Adjust the path as needed

const MainContactUs = () => {
  return (
    <div className="main-contact-us">
      <div className="background-image-container">
        <div className="main-contact-us-overlay">
          <h1>Get In Touch</h1>
          <p>Kindly share your reviews, feedback, concerns, and queries below.</p>
        </div>
      </div>
      <div className="main-contact-form-container">
        <ContactUs />
      </div>
    </div>
  );
};

export default MainContactUs;


