import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './TechnflexLocation.css'; // Import the CSS file for styling
import globimg from '../../Images/globimg.jpg';

const TechnflexLocation = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  const handleExploreClick = () => {
    navigate('/vc'); // Navigate to the location page
  };

  return (
    <div className="technflex-location">
      <div className="image-section">
        <img src={globimg} alt="Globe Image" />
      </div>
      <div className="text-section">
        <h1>Pan India Presence</h1>
        <p>
          With its expanding manufacturing and distribution network across the nation, Technoflex has exceptionally fortified its PAN India presence.
        </p>
        <button onClick={handleExploreClick} className="explore-link">
          Explore our locations
        </button>
        <div className="units-offices">
          <div className="unit">
            <h2>Units</h2>
            <p>Details about the units.</p>
          </div>
          <div className="office">
            <h2>Offices</h2>
            <p>Details about the offices.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TechnflexLocation;
