import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

const EditProductDetails = ({ match }) => {
    const [productDetail, setProductDetail] = useState({});
    const { id } = match.params;

    useEffect(() => {
        const fetchProductDetail = async () => {
            try {
                const response = await axios.get(`http://localhost:8000/api/product-details/${id}`);
                setProductDetail(response.data);
            } catch (error) {
                console.error('Error fetching product detail:', error);
            }
        };

        fetchProductDetail();
    }, [id]);

    const handleChange = (e) => {
        setProductDetail({
            ...productDetail,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            await axios.put(`http://localhost:8000//api/productdetails/${id}`, productDetail);
            alert('Product details updated successfully!');
        } catch (error) {
            console.error('Error updating product details:', error);
            alert('Error updating product details.');
        }
    };

    return (
        <div className="container mt-5">
            <h2>Edit Product Details</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="productID">Product ID</label>
                    <input
                        type="number"
                        className="form-control"
                        id="productID"
                        name="ProductID"
                        value={productDetail.ProductID || ''}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="material">Material</label>
                    <input
                        type="text"
                        className="form-control"
                        id="material"
                        name="Material"
                        value={productDetail.Material || ''}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="size">Size</label>
                    <input
                        type="text"
                        className="form-control"
                        id="size"
                        name="Size"
                        value={productDetail.Size || ''}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="usageApplication">Usage Application</label>
                    <input
                        type="text"
                        className="form-control"
                        id="usageApplication"
                        name="UsageApplication"
                        value={productDetail.UsageApplication || ''}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="brand">Brand</label>
                    <input
                        type="text"
                        className="form-control"
                        id="brand"
                        name="Brand"
                        value={productDetail.Brand || ''}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="connectionType">Connection Type</label>
                    <input
                        type="text"
                        className="form-control"
                        id="connectionType"
                        name="ConnectionType"
                        value={productDetail.ConnectionType || ''}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="color">Color</label>
                    <input
                        type="text"
                        className="form-control"
                        id="color"
                        name="Color"
                        value={productDetail.Color || ''}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="countryOfOrigin">Country of Origin</label>
                    <input
                        type="text"
                        className="form-control"
                        id="countryOfOrigin"
                        name="CountryOfOrigin"
                        value={productDetail.CountryOfOrigin || ''}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="minimumOrderQuantity">Minimum Order Quantity</label>
                    <input
                        type="text"
                        className="form-control"
                        id="minimumOrderQuantity"
                        name="MinimumOrderQuantity"
                        value={productDetail.MinimumOrderQuantity || ''}
                        onChange={handleChange}
                        required
                    />
                </div>
                <button type="submit" className="btn btn-primary">Update Product Details</button>
            </form>
        </div>
    );
};

export default EditProductDetails;
