import React from 'react';
import './InvestorSection.css'; // Ensure to create this CSS file with the following styles
import product from '../../Images/techban3.jpg'
const InvestorSection = () => {
  return (
    <section className="investor_section">
      <div className="investor_container">
        <div className="investor_flex">
          <div className="investor_image">
            <img
              src={product}// Replace with actual Techno Flex image URL
              alt="Techno Flex"
              className="investor_image_img"
            />
          </div>
          <div className="investor_content">
            <h2 className="investor_heading">
              <span className="investor_color_red">Techno Flex</span> Investor's Room
            </h2>
            <p className="investor_paragraph">
              Techno Flex is committed to delivering excellence in manufacturing and ensuring the highest quality standards. Our dedication to innovation and safety drives us to meet and exceed the expectations of our stakeholders.
            </p>
            <div className="investor_button_section">
              <a
                href="#"
                className="investor_button"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn More
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InvestorSection;
