import React, { useState, useEffect } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import './ImpactSection.css'; // Import the CSS file
import electrical from './image/die-casting-coupling.jpg'
import gi  from './image/gi-steel-tube-pipe.jpg'
import pvc from './image/plastic-coated-metal.jpg'
import liquid from './image/liquid-tight-steel-flexible-conduit.jpg'
import product from './image/product.jpg'
import ban1 from './image/tf1.png'
import ban2 from './image/tf2.png'
import ban3 from './image/tf4.png'
const TechnoFlexImpactSection = () => {
  const [activeBoxes, setActiveBoxes] = useState({});
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleToggle = (index) => {
    setActiveBoxes(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  const bannerImages = [
    ban1,
    ban2,
    ban3,
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % bannerImages.length);
    }, 5000);
    return () => clearInterval(timer);
  }, []);

  const impactData = [
    {
      title: "Milestones & Achievements",
      subtitle: "Pioneering Innovation & Quality",
      description: "From our founding in 2011 to our recent expansions, Techno Flex has continuously pushed the boundaries of flexible conduit manufacturing. Our commitment to innovation and quality is reflected in our various industry certifications and global reach.",
      expandedContent: "Our milestones include the establishment of modern manufacturing facilities, expansion of product lines, and achieving ISO certification. Each step reflects our dedication to enhancing infrastructure development and maintaining the highest quality standards.",
      image: liquid
    },
    {
      title: "Electrical Accessories",
      subtitle: "Comprehensive Range of Solutions",
      description: "Our product range includes a wide variety of electrical accessories, from brass reducing bushes to PVC flexible conduit fittings. We offer die-casting couplings, nickel-plated brass adapters, elbow fittings, and more.",
      expandedContent: "Each accessory is designed with precision to ensure perfect compatibility and long-lasting performance. Our brass adapters and reducers are known for their durability and excellent conductivity, making them ideal for various electrical applications.",
      image: electrical
    },
    {
      title: "PVC Coated Flexible Conduit",
      subtitle: "Versatile Protection for Wiring",
      description: "We provide an extensive range of PVC coated flexible conduits, including 20mm options, GI flexible pipes with PVC coating, and 1-inch flexible hose pipes. Our products offer superior protection and flexibility for diverse electrical installations.",
      expandedContent: "The PVC coating provides excellent resistance against corrosion, abrasion, and chemical exposure, making these conduits suitable for both indoor and outdoor applications. They are widely used in industries such as automotive, construction, and manufacturing.",
      image: pvc
    },
    {
      title: "Liquid Tight Flexible Conduit",
      subtitle: "Advanced Sealing for Harsh Environments",
      description: "Our liquid tight flexible conduit range includes UL type LTFC, steel variants, and reinforced options. These products are designed to provide superior protection in environments where moisture and liquid ingress are concerns.",
      expandedContent: "The liquid tight design ensures a watertight seal, making these conduits ideal for use in wet locations, outdoor installations, and areas subject to wash-downs. They offer excellent flexibility while maintaining their protective properties.",
      image: liquid
    },
    {
      title: "GI Pipes",
      subtitle: "Durable Galvanized Solutions",
      description: "We manufacture a variety of galvanized iron pipes, including 1.6 mm GI pipes, GI steel tube pipes, and welded galvanized iron pipes. Our GI pipes are known for their strength, corrosion resistance, and versatility.",
      expandedContent: "The galvanization process ensures long-lasting protection against rust and corrosion, making these pipes suitable for both indoor and outdoor applications. They are widely used in plumbing, electrical conduits, and structural support in various industries.",
      image: gi
    }
  ];

  return (
    <div className="techno-flex-impact-section">
      <section className="techno-flex-banner-section">
        <div className="techno-flex-home-banner-slider">
          {bannerImages.map((img, index) => (
            <div 
              key={index} 
              className={`techno-flex-banner_item ${index === currentSlide ? 'active' : ''}`}
            >
              <div 
                className="techno-flex-banner-slider-img"
                style={{backgroundImage: `url(${img})`}}
              ></div>
            </div>
          ))}
        </div>
        <div className="techno-flex-scroll-down">
          <div className="techno-flex-c-scrolldown">
            <div className="techno-flex-c-line"></div>
          </div>
        </div>
        <div className="techno-flex-breadcrumbs">
          <div className="container">
            {/* <a href="https://www.technoflex.com">Home</a>
            <span>
              <img src="/api/placeholder/10/10" alt="Right icon" />
            </span>
            <a href="#">Techno Flex Impact</a> */}
          </div>
        </div>
      </section>

      <section className="techno-flex-foundation_main">
        <div className="container">
          <div className="techno-flex-foundation_title">
            <h2 className="techno-flex-heading-text">
              <span className="techno-flex-color_red_home">Innovating Solutions,</span> Shaping the Future
            </h2>
            <p className="techno-flex-para_new_font">
              At Techno Flex, we are committed to providing innovative and reliable flexible conduit solutions. Our journey is marked by milestones that reflect our dedication to quality and our role in shaping industry standards.
            </p>
          </div>

          <div className="techno-flex-impact_new_main">
            {impactData.map((item, index) => (
              <div key={index} className="techno-flex-impact_box">
                <div className="techno-flex-impact_new_flex">
                  <div className="techno-flex-impact_new_img">
                    <img src={item.image} alt={item.title} className="img-responsive" />
                  </div>
                  <div className="techno-flex-impact_new_content">
                    <h3 className="techno-flex-subheading-text">
                      <span className="techno-flex-color_red_home">{item.subtitle}</span>
                    </h3>
                    <h2 className="techno-flex-heading-text">{item.title}</h2>
                    <p className="techno-flex-para_new_font">{item.description}</p>
                    <div className="techno-flex-btn_section">
                      <button className="techno-flex-read_btn" onClick={() => handleToggle(index)}>
                        {activeBoxes[index] ? 'Read Less' : 'Read More'}
                        {activeBoxes[index] ? <ChevronUp className="icon" /> : <ChevronDown className="icon" />}
                      </button>
                    </div>
                  </div>
                </div>
                {activeBoxes[index] && (
                  <div className="techno-flex-impact_box_info">
                    <p className="techno-flex-para_new_font">{item.expandedContent}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default TechnoFlexImpactSection;
