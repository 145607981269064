import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './ImpactPage.css'; // Import the CSS file for styling
import tecban from '../../Images/fullban2.jpg';

const ImpactPage = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  const handleImpactClick = () => {
    navigate('/impact'); // Navigate to the impact page
  };

  return (
    <div className="impact-page">
      <div className="impact-image-container">
        <img src={tecban} alt="Technoflex Products" />
      </div>
      <div className="impact-content">
        <div className="impact-content-wrapper">
          <div className="impact-header-section">
            <h1>
              <span style={{ color: "rgb(19, 162, 194)" }}>
                Innovating Products,<br />
              </span>
              Creating Impact
            </h1>
            <button onClick={handleImpactClick} className="impact-cta-button">
              Techno Flex Impact
            </button>
          </div>
          <div className="impact-text-section">
            <p>
              Since our inception in 2011, Techno Flex has set the standard in flexible conduits with cutting-edge manufacturing and a broad product range. Our commitment to innovation and quality has driven significant advancements and global expansion. As we continue to lead in technology, Techno Flex delivers reliable, high-quality solutions that enhance infrastructure and support industries worldwide.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImpactPage;
