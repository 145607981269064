import React from "react";
import './Menu.css'
import { Link } from "react-router-dom";

const Menu = ({ Icon, Title, Address, Active }) => {
  return (
    <li className={Active ? "active" : ""}>
      <Link to={Address}>
        {Icon}
        <span className="text">
          <b>{Title}</b>
        </span>
      </Link>
    </li>
  );
};

export default Menu;
