// import React from "react";
// import "./Location.css"; // Import CSS file for styling
// import productImg08 from "../../Images/banner_one.jpg";
// import productImg09 from "../../Images/technooffice.jpg";
// import productImg10 from "../../Images/techno6.jpg";
// import productImg11 from "../../Images/gip8.jpg";
// import productImg12 from "../../Images/ltfc10.jpg";
// import  Carousel from "../../components/Carosal/carosal"
//  import ImageSlider from "../../components/ClientSlider/slider"
// const Location = () => {
//   return (
//     <div className="main">
//       <div className="parent">
      
//         {/* Content for parent */}
//       </div>
//       <div className="images">
//       <Carousel/>
//         {/* <img src={productImg09 } alt="" /> */}

//       </div>
//       <div className="parent0">
//         <div className="child">
//           <img src={productImg10} alt="" />
//           <h5>QUALITY ASSURANCE</h5>
//           <p>
//             We manufacture our complete range there using superb quality raw
//             materials. We are committed to offering the best quality products to our
//             esteemed clients.
//           </p>
//         </div>
//         <div className="child">
//           <img src={productImg10} alt="" />
//           <h5>24/7 SUPPORT</h5>
//           <p>
//             We manufacture our complete range there using superb quality raw
//             materials. We are committed to offering the best quality products to our
//             esteemed clients.
//           </p>
//         </div>
//         <div className="child">
//           <img src={productImg10} alt="" />
//           <h5>QUALITY ASSURANCE</h5>
//           <p>
//             We manufacture our complete range there using superb quality raw
//             materials. We are committed to offering the best quality products to our
//             esteemed clients.
//           </p>
//         </div>
//       </div>
//       <div className="main1">
//         <div className="heading">
//           <h3>STEEL PIPE SOURCING (INDIA)</h3>
//         </div>
//         <div className="link"></div>
//         <div className="parent1">
//           <div className="child0">
//             <img src={productImg11} alt="" />
//           </div>
//           <div className="child00">
//             <div className="child1">
//               <h4>STAINLESS STEEL PIPE IN INDORE</h4>
//             </div>
//             <div className="child1">
//               <p>
//                 Established in 2011 , Steel Pipe Sourcing has been dedicated to
//                 meeting the market's demands for high-quality pipes and tubes.
//                 As specialist manufacturers, exporters, importers, suppliers,
//                 stockists, dealers, distributors, and traders, we take pride in
//                 offering a wide range of Stainless Steel Pipe in Mumbai and ERW
//                 stainless steel pipes and tubes, duplex and super duplex UNS
//                 pipes and tubes, as well as stainless steel EFW pipes.
//               </p>
//             </div>

//             <div className="child11">
//               <h4>Stainless Steel Pipe Manufacturers in INDORE</h4>
//             </div>
//             <div className="child11">
//               <p style={{ paddingBottom: "15px" }}>
//                 We are delighted to introduce ourselves as outstanding
//                 distributors of the following products:
//               </p>
//               <p>
//                 <i className="ri-play-fill"></i>Stainless Steel Tubes: We
//                 provide Stainless Steel Pipe Manufacturers in Mumbai that adhere
//                 to ASTM A312, ASTM A213, ASTM A269, and ASTM A358 specifications.
//                 These tubes are meticulously crafted to meet the highest
//                 standards and are suitable for various applications.
//               </p>
//               <p>
//                 <i className="ri-play-fill"></i>Stainless Steel IBR Boiler
//                 Pipes: Our range includes stainless steel IBR boiler pipes with
//                 IBR certification. These pipes are designed to withstand high
//                 temperature and pressure conditions, making them ideal for
//                 boiler applications.
//               </p>
//               <p>
//                 <i className="ri-play-fill"></i>Duplex and Super Duplex Pipes
//                 and Tubes: We offer duplex and super duplex pipes and tubes
//                 known for their exceptional strength and corrosion resistance.
//                 They find applications in industries such as chemical processing,
//                 pharmaceuticals, agriculture, fertilizers, petrochemicals, and
//                 power generation.
//               </p>
//               <p>
//                 <i className="ri-play-fill"></i>Large Diameter Stainless Steel
//                 Pipes: Our inventory includes large diameter stainless steel
//                 pipes that cater to diverse industrial requirements. These pipes
//                 are manufactured to precise specifications and exhibit excellent
//                 durability and performance.
//               </p>
//               <p style={{ paddingTop: "15px" }}>
//                 Stainless Steel Pipes for High-Temperature Services: Our range of
//                 stainless steel pipes is specifically designed to withstand
//                 high-temperature environments, ensuring reliable performance in
//                 demanding applications
//               </p>
//               <div className="child11">
//                 <h4>Stainless Steel Pipe Suppliers in INDORE</h4>
//               </div>
//               <p>
//                 At Steel Pipe Sourcing, we are committed to providing our
//                 customers with top-notch Stainless Steel Pipe Suppliers in INDORE
//                 that meet their specific requirements. With our extensive product
//                 range, impeccable quality standards, and customer-centric
//                 approach, we have become a trusted name in the industry.
//               </p>
//               <button>Read More</button>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* <div className="main2">
//         <div className="haeding1">
//           <h2>WHAT WE MANUFACTURE</h2>
//         </div>

//         <marquee>
//           <div className="perent2">
//             <div className="child2">
//               <img src={productImg12} alt="" />
//               <h3>304 304L Stainless Steel Welde </h3>
//               <button>Read More</button>
//             </div>
//             <div className="child2">
//               <img src={productImg12} alt="" />
//               <h3>304 304L Stainless Steel Welde </h3>
//               <button>Read More</button>
//             </div>
//             <div className="child2">
//               <img src={productImg12} alt="" />
//               <h3>304 304L Stainless Steel Welde </h3>
//               <button>Read More</button>
//             </div>
//             <div className="child2">
//               <img src={productImg12} alt="" />
//               <h3>304 304L Stainless Steel Welde </h3>
//               <button>Read More</button>
//             </div>
//           </div>
//         </marquee>
        
//       </div> */}
//       <div>
//         <ImageSlider/>
//       </div>
      
//     </div>
//   );
// };

// export default Location;
// Location.jsx
// Location.jsx
// Location.jsx
// import React from 'react';
// import { useLocation } from 'react-router-dom';
// import './Location.css';
// import productImg10 from "../../Images/techno6.jpg";
// import productImg11 from "../../Images/gip8.jpg";
// import ImageSlider from "../../components/ClientSlider/slider";
// import Carousel from "../../components/Carosal/carosal";

// const Location = () => {
//   const location = useLocation();
//   const { selectedCountry, cities } = location.state || { selectedCountry: { name: 'N/A' }, cities: [] };

//   return (
//     <div className="main">
//       <div className="parent">
//         {/* Content for parent */}
//       </div>
//       <div className="images">
//         <Carousel />
//       </div>
//       <div className="parent0">
//         <div className="child">
//           <img src={productImg10} alt="Quality Assurance" />
//           <h5>QUALITY ASSURANCE</h5>
//           <p>
//             We manufacture our complete range there using superb quality raw
//             materials. We are committed to offering the best quality products to our
//             esteemed clients.
//           </p>
//         </div>
//         <div className="child">
//           <img src={productImg10} alt="24/7 Support" />
//           <h5>24/7 SUPPORT</h5>
//           <p>
//             We manufacture our complete range there using superb quality raw
//             materials. We are committed to offering the best quality products to our
//             esteemed clients.
//           </p>
//         </div>
//         <div className="child">
//           <img src={productImg10} alt="Quality Assurance" />
//           <h5>QUALITY ASSURANCE</h5>
//           <p>
//             We manufacture our complete range there using superb quality raw
//             materials. We are committed to offering the best quality products to our
//             esteemed clients.
//           </p>
//         </div>
//       </div>
//       <div className="main1">
//         <div className="heading">
//           <h3>Conduit Solutions from TechnoFlex ({selectedCountry.name})</h3>
//         </div>
//         <div className="link"></div>
//         <div className="parent1">
//           <div className="child0">
//             <img src={productImg11} alt="Steel Pipe Sourcing" />
//           </div>
//           <div className="child00">
//             <div className="child1">
//               <h4>Conduit Pipes and Solutions in {selectedCountry.name}</h4>
//             </div>
//             <div className="child1">
//               <p>
//               Established in 2011, TechnoFlex  Pvt. Ltd. has been a leading name in the conduit pipe industry, dedicated to meeting the market’s evolving demands with precision and innovation. As expert manufacturers, exporters, and suppliers, we proudly offer a comprehensive range of conduit solutions tailored to various industrial applications.
//               </p>
//             </div>

//             <div className="child11">
//               <h4>Conduit Pipe Manufacturers in {selectedCountry.name}</h4>
//             </div>
//             <div className="child11">
//               <p style={{ paddingBottom: "15px" }}>
//               We are delighted to introduce ourselves as premier distributors of the following conduit solutions:
//               </p>
//               <p>
//                 <i className="ri-play-fill"></i>Flexible Conduit: Engineered for flexibility and protection, our flexible conduits are ideal for routing electrical cables in dynamic environments where movement and vibration are prevalent. Designed to adapt to various configurations, these conduits ensure reliable cable management in challenging settings.
//               </p>
//               <p>
//                 <i className="ri-play-fill"></i>PVC Coated GI Flexible Conduit: Combining the strength of galvanized steel with a protective PVC coating, these conduits offer enhanced durability and resistance to environmental factors. They are perfect for applications exposed to harsh conditions, providing both strength and flexibility.
//               </p>
//               <p>
//                 <i className="ri-play-fill"></i>Electric Metallic Tubing (EMT): Known for its lightweight yet robust construction, EMT is an excellent choice for general electrical wiring applications. It provides a reliable conduit for electrical cables, ensuring effective protection and ease of installation.
//               </p>
//               <p>
//                 <i className="ri-play-fill"></i>Mild Steel (MS) Conduit: Our mild steel conduits are versatile and robust, offering a cost-effective solution for a range of structural and electrical installations. They provide a solid, dependable option for various applications.
//               </p>
//               <p>
//                 <i className="ri-play-fill"></i>Stainless Steel (SS) Conduit: Designed for the most demanding environments, our stainless steel conduits deliver superior corrosion resistance and durability. These conduits are engineered to withstand extreme conditions, making them ideal for applications requiring high performance and longevity.
//               </p>
//               <p>
//                 <i className="ri-play-fill"></i>Additional Accessories: To support secure and efficient installations, we offer a comprehensive range of essential accessories, including nuts, bolts, and connectors. These accessories are designed to complement our conduit solutions, ensuring a complete and functional setup.
//               </p>
//               <div className="child11">
//                 <h4>Conduit Pipe Suppliers in {selectedCountry.name}</h4>
//               </div>
//               <p>
//               At TechnoFlex, we are committed to delivering top-quality conduit solutions to our customers in {selectedCountry.name}. Our extensive product range, combined with our dedication to superior quality and customer satisfaction, has established us as a trusted name in the conduit pipe industry.
//               </p>
//               <button>Read More</button>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div>
//         <ImageSlider />
//       </div>

    
    
//     </div>
//   );
// };

// export default Location;


import React from 'react';
import { useLocation } from 'react-router-dom';
import './Location.css';
import productImg10 from "../../Images/techno6.jpg";
import productImg11 from "../../Images/gip8.jpg";
import ImageSlider from "../../components/ClientSlider/slider";
import Carousel from "../../components/Carosal/carosal";

const Location = () => {
  const location = useLocation();
  const { selectedCountry, selectedCity, cities } = location.state || { selectedCountry: { name: 'N/A' }, selectedCity: 'N/A', cities: [] };

  return (
    <div className="main">
      <div className="parent">
        {/* Content for parent */}
      </div>
      <div className="images">
        <Carousel />
      </div>
      <div className="parent0">
        <div className="child">
          <img src={productImg10} alt="Quality Assurance" />
          <h5>QUALITY ASSURANCE</h5>
          <p>
            We manufacture our complete range there using superb quality raw
            materials. We are committed to offering the best quality products to our
            esteemed clients.
          </p>
        </div>
        <div className="child">
          <img src={productImg10} alt="24/7 Support" />
          <h5>24/7 SUPPORT</h5>
          <p>
            We manufacture our complete range there using superb quality raw
            materials. We are committed to offering the best quality products to our
            esteemed clients.
          </p>
        </div>
        <div className="child">
          <img src={productImg10} alt="Quality Assurance" />
          <h5>QUALITY ASSURANCE</h5>
          <p>
            We manufacture our complete range there using superb quality raw
            materials. We are committed to offering the best quality products to our
            esteemed clients.
          </p>
        </div>
      </div>
      <div className="main1">
        <div className="heading">
          <h3>Conduit Solutions from TechnoFlex ({selectedCountry.name} - {selectedCity})</h3>
        </div>
        <div className="link"></div>
        <div className="parent1">
          <div className="child0">
            <img src={productImg11} alt="Steel Pipe Sourcing" />
          </div>
          <div className="child00">
            <div className="child1">
              <h4>Conduit Pipes and Solutions in ({selectedCountry.name} - {selectedCity})</h4>
            </div>
            <div className="child1">
              <p>
              Established in 2011, TechnoFlex Pvt. Ltd. has been a leading name in the conduit pipe industry, dedicated to meeting the market’s evolving demands with precision and innovation. As expert manufacturers, exporters, and suppliers, we proudly offer a comprehensive range of conduit solutions tailored to various industrial applications.
              </p>
            </div>

            <div className="child11">
              <h4>Conduit Pipe Manufacturers in ({selectedCountry.name} - {selectedCity})</h4>
            </div>
            <div className="child11">
              <p style={{ paddingBottom: "15px" }}>
              We are delighted to introduce ourselves as premier distributors of the following conduit solutions:
              </p>
              <p>
                <i className="ri-play-fill"></i><span style={{color:"#6d74da",fontSize:"20px"}}>Flexible Conduit:</span> Engineered for flexibility and protection, our flexible conduits are ideal for routing electrical cables in dynamic environments where movement and vibration are prevalent. Designed to adapt to various configurations, these conduits ensure reliable cable management in challenging settings.
              </p>
              <p>
                <i className="ri-play-fill"></i><span style={{color:" #6d74da",fontSize:"20px"}}>PVC Coated GI Flexible Conduit:</span> Combining the strength of galvanized steel with a protective PVC coating, these conduits offer enhanced durability and resistance to environmental factors. They are perfect for applications exposed to harsh conditions, providing both strength and flexibility.
              </p>
              <p>
                <i className="ri-play-fill"></i><span style={{color:" #6d74da",fontSize:"20px"}}>Electric Metallic Tubing (EMT):</span>  Known for its lightweight yet robust construction, EMT is an excellent choice for general electrical wiring applications. It provides a reliable conduit for electrical cables, ensuring effective protection and ease of installation.
              </p>
              <p>
                <i className="ri-play-fill"></i><span style={{color:" #6d74da",fontSize:"20px"}}>Mild Steel (MS) Conduit:</span>  Our mild steel conduits are versatile and robust, offering a cost-effective solution for a range of structural and electrical installations. They provide a solid, dependable option for various applications.
              </p>
              <p>
                <i className="ri-play-fill"></i><span style={{color:" #6d74da",fontSize:"20px"}}>Stainless Steel (SS) Conduit:</span>  Designed for the most demanding environments, our stainless steel conduits deliver superior corrosion resistance and durability. These conduits are engineered to withstand extreme conditions, making them ideal for applications requiring high performance and longevity.
              </p>
              <p>
                <i className="ri-play-fill"></i><span style={{color:"#6d74da", fontSize:"20px"}}>Additional Accessories:</span>  To support secure and efficient installations, we offer a comprehensive range of essential accessories, including nuts, bolts, and connectors. These accessories are designed to complement our conduit solutions, ensuring a complete and functional setup.
              </p>
              <div className="child11">
                <h4>Conduit Pipe Suppliers in ({selectedCountry.name} - {selectedCity})</h4>
              </div>
              <p>
              At TechnoFlex, we are committed to delivering top-quality conduit solutions to our customers in {selectedCountry.name}. Our extensive product range, combined with our dedication to superior quality and customer satisfaction, has established us as a trusted name in the conduit pipe industry.
              </p>
              <button>Read More</button>
            </div>
          </div>
        </div>
      </div>

      <div>
        <ImageSlider />
      </div>
    </div>
  );
};

export default Location;
