import Button from './Button/Button';
// import CheckBox from './CheckBox/CheckBox';
// import DateTime from './dateTime/DateTime';
// import InputNumber from './InputNumber/InputNumber';
// import Radio from './Radio/RadioButton';
// import SelectBox from './SelectBox/SelectBox';
import Spinner from './Spinner/Spiner';
import TextInput from './TextInput/TextInput';
// import Typography from './Typography/Typography';
// import UploadButton from './UploadButton/UploadButton';
// import Modal from './Modal/Modal';
// import SideBar from './sideBar/SideBar';
// import Loading from './DropDownSpinner/DropDownSpinner';
// import MenuBar from './MenuBar/MenuBar'
// import HeaderNav from "./layout-components/HeaderNav";
// import DynamicTable from "./Table/Table";
// import DropDown from './DropDown/DropDown';
// import SearchBox from './Search/Search';
class Component{
    static Button = Button;
    // static CheckBox = CheckBox;
    // static DateTime = DateTime;
    // static InputNumber = InputNumber;
    // static Radio = Radio;
    // static SelectBox = SelectBox;
    static TextInput = TextInput;
    static Spinner = Spinner;
    // static Typography = Typography;
    // static UploadButton = UploadButton;
    // static Modal = Modal;
    // static SideBar = SideBar;
    // static MiniLoader = Loading;
    // static MenuBar = MenuBar;
    // static DynamicTable = DynamicTable;
    // static DropDown = DropDown;
    // static SearchBox = SearchBox

}
// export class UtilsComponent{
//     static HeaderNav = HeaderNav;
// }
export default Component;