// import { useEffect, useState } from "react";
// import { Container, Nav, Navbar,NavDropdown } from "react-bootstrap";
// import "./navbar.css";
// import { Link } from "react-router-dom";
// import { useSelector } from "react-redux";
// const NavBar = () => {
//   const { cartList } = useSelector((state) => state.cart);
//   const [expand, setExpand] = useState(false);
//   const [isFixed, setIsFixed] = useState(false);
//   // fixed Header
//   function scrollHandler() {
//     if (window.scrollY >= 100) {
//       setIsFixed(true);
//     } else if (window.scrollY <= 50) {
//       setIsFixed(false);
//     }
//   }
//   window.addEventListener("scroll", scrollHandler);
//   // useEffect(()=> {
//   //   if(CartItem.length ===0) {
//   //     const storedCart = localStorage.getItem("cartItem");
//   //     setCartItem(JSON.parse(storedCart));
//   //   }
//   // },[])
//   return (
//     <Navbar
//       fixed="top"
//       expand="md"
//       className={isFixed ? "navbar fixed" : "navbar"}
//     >
//       <Container className="navbar-container">
//         <Navbar.Brand to="/">
//           <ion-icon name="bag"></ion-icon>
//           <h1 className="logo">TechnoFlex</h1>
//         </Navbar.Brand>
//         {/* Media cart and toggle */}
//         <div className="d-flex">
//           <div className="media-cart">
//             <svg
//               xmlns="http://www.w3.org/2000/svg"
//               viewBox="0 0 24 24"
//               fill="black"
//               className="nav-icon"
//             >
//               <path
//                 fillRule="evenodd"
//                 d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z"
//                 clipRule="evenodd"
//               />
//             </svg>
//             {/* <Link
//               aria-label="Go to Cart Page"
//               to="/cart"
//               className="cart"
//               data-num={cartList.length}
//             >
//               <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 viewBox="0 0 24 24"
//                 fill="black"
//                 className="nav-icon"
//               >
//                 <path d="M2.25 2.25a.75.75 0 000 1.5h1.386c.17 0 .318.114.362.278l2.558 9.592a3.752 3.752 0 00-2.806 3.63c0 .414.336.75.75.75h15.75a.75.75 0 000-1.5H5.378A2.25 2.25 0 017.5 15h11.218a.75.75 0 00.674-.421 60.358 60.358 0 002.96-7.228.75.75 0 00-.525-.965A60.864 60.864 0 005.68 4.509l-.232-.867A1.875 1.875 0 003.636 2.25H2.25zM3.75 20.25a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zM16.5 20.25a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z" />
//               </svg>
//             </Link> */}
//           </div>
//           <Navbar.Toggle
//             aria-controls="basic-navbar-nav"
//             onClick={() => {
//               setExpand(expand ? false : "expanded");
//             }}
//           >
//             <span></span>
//             <span></span>
//             <span></span>
//           </Navbar.Toggle>
//         </div>
//         <Navbar.Collapse id="basic-navbar-nav">
//           <Nav className="justify-content-end flex-grow-1 pe-3">
//             <Nav.Item>
//               <Link
//                 aria-label="Go to Home Page"
//                 className="navbar-link"
//                 to="/"
//                 onClick={() => setExpand(false)}
//               >
//                 <span className="nav-link-label">Home</span>
//               </Link>
//             </Nav.Item>

//             <Nav.Item>
//               <Link
//                 aria-label="Go to Shop Page"
//                 className="navbar-link"
//                 to="/shop"
//                 onClick={() => setExpand(false)}
//               >
//                 <span className="nav-link-label">Product</span>
//               </Link>
//             </Nav.Item>
         
//             <Nav.Item>
//               <Link
//                 aria-label="Go to gallary Page"
//                 className="navbar-link"
//                 to="/pv"
//                 onClick={() => setExpand(false)}
//               >
//                 <span className="nav-link-label">Gallery</span>
//               </Link>
//             </Nav.Item>
//             <Nav.Item>
//               <Link
//                 aria-label="Go to login Page"
//                 className="navbar-link"
//                 to="/login"
//                 onClick={() => setExpand(false)}
//               >
//                 <span className="nav-link-label">Login </span>
//               </Link>
//             </Nav.Item>
//             <Nav.Item>
//               {/* <Link
//                 aria-label="Go to Cart Page"
//                 className="navbar-link"
//                 to="/cart"
//                 onClick={() => setExpand(false)}
//               >
//                 <span className="nav-link-label">Cart</span>
//               </Link> */}
//             </Nav.Item>
//             <Nav.Item className="expanded-cart">
//               {/* <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 viewBox="0 0 24 24"
//                 fill="black"
//                 className="nav-icon"
//               >
//                 <path
//                   fillRule="evenodd"
//                   d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z"
//                   clipRule="evenodd"
//                 />
//               </svg> */}
//               {/* <Link
//                 aria-label="Go to Cart Page"
//                 to="/cart"
//                 className="cart"
//                 data-num={cartList.length}
//               >
//                 <svg
//                   xmlns="http://www.w3.org/2000/svg"
//                   viewBox="0 0 24 24"
//                   fill="black"
//                   className="nav-icon"
//                 >
//                   <path d="M2.25 2.25a.75.75 0 000 1.5h1.386c.17 0 .318.114.362.278l2.558 9.592a3.752 3.752 0 00-2.806 3.63c0 .414.336.75.75.75h15.75a.75.75 0 000-1.5H5.378A2.25 2.25 0 017.5 15h11.218a.75.75 0 00.674-.421 60.358 60.358 0 002.96-7.228.75.75 0 00-.525-.965A60.864 60.864 0 005.68 4.509l-.232-.867A1.875 1.875 0 003.636 2.25H2.25zM3.75 20.25a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zM16.5 20.25a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z" />
//                 </svg>
//               </Link> */}
//             </Nav.Item>
//             <NavDropdown title="Location" id="basic-nav-dropdown">
//             <NavDropdown.Item as={Link} to="/location">
//                 INDIA
//               </NavDropdown.Item>
//               <NavDropdown.Item as={Link} to="/location">
//                 UAE
//               </NavDropdown.Item>
//               <NavDropdown.Item as={Link} to="/location">
//                 USA
//               </NavDropdown.Item>
//               <NavDropdown.Item as={Link} to="/location">
//                 UK
//               </NavDropdown.Item>
//               {/* Add more countries as needed */}
//             </NavDropdown>
//           </Nav>
//         </Navbar.Collapse>
//       </Container>
//     </Navbar>
//   );
// };

// export default NavBar;
// import React, { useState, useEffect } from "react";
// import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
// import { Link } from "react-router-dom";
// import { FaFacebookF, FaInstagram, FaYoutube, FaLinkedinIn } from "react-icons/fa";
// import "./navbar.css";

// import logo from '../../Images/logo.jpg'

// const NavBar = () => {

//   const [expand, setExpand] = useState(false);
//   const [isFixed, setIsFixed] = useState(false);
//   // fixed Header
//   function scrollHandler() {
//     if (window.scrollY >= 100) {
//       setIsFixed(true);
//     } else if (window.scrollY <= 50) {
//       setIsFixed(false);
//     }
//   }
//   window.addEventListener("scroll", scrollHandler);

//   return (
//     <Navbar
//       fixed="top"
//       expand="lg"
//       className={isFixed ? "navbar fixed" : "navbar"}
//     >
//       <Container className="navbar-container">
//         <Navbar.Brand as={Link} to="/">
//           <img 
//             src={logo} 
//             alt="TechnoFlex Logo" 
//             className="navbar-logo"
//           />
//         </Navbar.Brand>
//         <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpand(!expand)}>
//           <span></span>
//           <span></span>
//           <span></span>
//         </Navbar.Toggle>
//         <Navbar.Collapse id="basic-navbar-nav">
//           <Nav className="mx-auto">
//             <Nav.Item>
//               <Link className="navbar-link" to="/">
//                 <span className="nav-link-label">Home</span>
//               </Link>
//             </Nav.Item>
//             <Nav.Item>
//               <Link className="navbar-link" to="/shop">
//                 <span className="nav-link-label">Product</span>
//               </Link>
//             </Nav.Item>
//             <NavDropdown title="About" id="about-dropdown" className="nav-dropdown">
//               <NavDropdown.Item as={Link} to="/tfove">Overview</NavDropdown.Item>
//               <NavDropdown.Item as={Link} to="/tfsus">Sustainability</NavDropdown.Item>
//               <NavDropdown.Item as={Link} to="/tfmile">Milestone</NavDropdown.Item>
//               <NavDropdown.Item as={Link} to="/vc">Location</NavDropdown.Item>
//             </NavDropdown>
//             <Nav.Item>
//               <Link className="navbar-link" to="/pv">
//                 <span className="nav-link-label">Gallery</span>
//               </Link>
//             </Nav.Item>
//             <Nav.Item>
//               <Link className="navbar-link" to="/maincontactus">
//                 <span className="nav-link-label">Contact Us</span>
//               </Link>
//             </Nav.Item>
//           </Nav>
//           <div className="social-icons">
//           <a href="https://www.facebook.com/technoflexindore/" target="_blank" rel="noopener noreferrer"><FaFacebookF /></a>
// <a href="https://www.instagram.com/technoflex.in/reel/C-xH4AkMB3l/" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
// <a href="https://www.youtube.com/@Technoflexin" target="_blank" rel="noopener noreferrer"><FaYoutube /></a>
// <a href="https://in.linkedin.com/company/techno-flex-india/" target="_blank" rel="noopener noreferrer"><FaLinkedinIn /></a>

//           </div>
//         </Navbar.Collapse>
//       </Container>
//     </Navbar>
//   );
// };

// export default NavBar;

import React, { useState, useEffect } from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaFacebookF, FaInstagram, FaYoutube, FaLinkedinIn } from "react-icons/fa";
import "./navbar.css"; // Ensure this file exists and includes the necessary styles
import logo from '../../Images/logo1.png'; // Ensure this path is correct

const NavBar = () => {
  const [expand, setExpand] = useState(false);
  const [isFixed, setIsFixed] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  // Handle scroll event to toggle fixed class
  const scrollHandler = () => {
    setIsFixed(window.scrollY >= 100);
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollHandler);
    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  // Handle link clicks on mobile
  const handleNavLinkClick = () => {
    if (window.innerWidth < 992) {
      setExpand(false);
      setDropdownOpen(false);
    }
  };

  // Handle dropdown toggle
  const handleDropdownToggle = (isOpen) => {
    if (window.innerWidth < 992) {
      setDropdownOpen(isOpen);
    }
  };

  // Handle dropdown item click
  const handleDropdownItemClick = () => {
    setDropdownOpen(false);
    setExpand(false);
  };

  return (
    <Navbar
      expand="lg"
      className={`navbar ${isFixed ? "fixed" : ""}`}
      expanded={expand}
    >
      <Container className="navbar-container">
        <Navbar.Brand as={Link} to="/" onClick={handleNavLinkClick}>
          <img src={logo} alt="TechnoFlex Logo" className="navbar-logo" />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={() => setExpand(!expand)}
        >
          <span></span>
          <span></span>
          <span></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto">
            <Nav.Item>
              <Link className="navbar-link" to="/" onClick={handleNavLinkClick}>
                Home
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link className="navbar-link" to="/shop" onClick={handleNavLinkClick}>
                Product
              </Link>
            </Nav.Item>
            <NavDropdown
              title="About"
              id="about-dropdown"
              show={dropdownOpen}
              onMouseEnter={() => window.innerWidth >= 992 && setDropdownOpen(true)}
              onMouseLeave={() => window.innerWidth >= 992 && setDropdownOpen(false)}
              onToggle={handleDropdownToggle}
            >
              <NavDropdown.Item as={Link} to="/tfove" onClick={handleDropdownItemClick}>
                Overview
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/tfsus" onClick={handleDropdownItemClick}>
                Sustainability
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/tfmile" onClick={handleDropdownItemClick}>
                Milestone
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/vc" onClick={handleDropdownItemClick}>
                Location
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Item>
              <Link className="navbar-link" to="/pv" onClick={handleNavLinkClick}>
                Gallery
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link className="navbar-link" to="/maincontactus" onClick={handleNavLinkClick}>
                Contact Us
              </Link>
            </Nav.Item>
          </Nav>
          <div className="social-icons">
            <a href="https://www.facebook.com/technoflexindore/" target="_blank" rel="noopener noreferrer">
              <FaFacebookF />
            </a>
            <a href="https://www.instagram.com/technoflex.in/reel/C-xH4AkMB3l/" target="_blank" rel="noopener noreferrer">
              <FaInstagram />
            </a>
            <a href="https://www.youtube.com/@Technoflexin" target="_blank" rel="noopener noreferrer">
              <FaYoutube />
            </a>
            <a href="https://in.linkedin.com/company/techno-flex-india/" target="_blank" rel="noopener noreferrer">
              <FaLinkedinIn />
            </a>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
