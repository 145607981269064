import React, { useState, useEffect } from 'react';
import axios from 'axios';

function Countries() {
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');

  useEffect(() => {
    // Fetch countries
    axios.get('http://localhost:8000/api/countries')
      .then(response => setCountries(response.data))
      .catch(error => console.error('Error fetching countries:', error));
  }, []);

  const handleCountryChange = (event) => {
    const countryCode = event.target.value;
    setSelectedCountry(countryCode);

    // Fetch cities for selected country
    axios.get('http://localhost:8000/api/cities', { params: { country_code: countryCode } })
      .then(response => setCities(response.data))
      .catch(error => console.error('Error fetching cities:', error));
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Country and City Selector</h1>
      <div className="mb-4">
        <label htmlFor="country" className="block text-lg font-medium mb-2">Select Country:</label>
        <select
          id="country"
          className="border p-2 w-full"
          onChange={handleCountryChange}
          value={selectedCountry}
        >
          <option value="">-- Select a country --</option>
          {countries.map(country => (
            <option key={country.country_code} value={country.country_code}>
              {country.country_name}
            </option>
          ))}
        </select>
      </div>
      <div>
        <label htmlFor="city" className="block text-lg font-medium mb-2">Select City:</label>
        <select id="city" className="border p-2 w-full" disabled={!selectedCountry}>
          <option value="">-- Select a city --</option>
          {cities.map(city => (
            <option key={city.city_id} value={city.city_id}>
              {city.city_name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

export default  Countries;
