// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import './contry.css';
// import { RiPlayMiniFill } from 'react-icons/ri';

// function Country() {
//   const [countries, setCountries] = useState([]);
//   const [cities, setCities] = useState([]);
//   const [selectedCountry, setSelectedCountry] = useState('');
//   const [loading, setLoading] = useState(false);

//   const pipeTypes = [
//     "Stainless steel pipe",
//     "EWR Stainless steel pipe",
//     "Seamless Stainless steel pipe",
//     // Add more pipe types as needed
//   ];

//   useEffect(() => {
//     setLoading(true);
//     axios.get('http://localhost:8000/api/countries')
//       .then(response => setCountries(response.data))
//       .catch(error => console.error('Error fetching countries:', error))
//       .finally(() => setLoading(false));
//   }, []);

//   const handleCountryClick = (countryCode) => {
//     setSelectedCountry(countryCode);
//     setLoading(true);
//     axios.get(`http://localhost:8000/api/cities/country/${countryCode}`)
//       .then(response => setCities(response.data))
//       .catch(error => console.error('Error fetching cities:', error))
//       .finally(() => setLoading(false));
//   };

//   return (
//     <div className="market-area">
//       <div className="market-area__container">
//         <div className="sidebar">
//           <div className="quick-contact">
//             <h2 className="section-header">QUICK CONTACT</h2>
//             <input type="text" placeholder="Your Name" />
//             <input type="email" placeholder="Your Email address" />
//             <input type="number" placeholder="Your number" />
//             <div className="country-select">
//               <select>
//                 <option>Select a country</option>
//                 <option>United States</option>
//                 <option>Canada</option>
//                 <option>United Kingdom</option>
//                 <option>Australia</option>
//                 <option>India</option>
//               </select>
//             </div>
//             <input type="text" placeholder="Type your message Here...." className="message-input" />
//             <div className="submit-button">
//               <input type="submit" value="Submit" />
//             </div>
//           </div>

//           <div className="pipe-types">
//             <h2 className="section-header">PIPE AND TUBES</h2>
//             {pipeTypes.map((type, index) => (
//               <div key={index} className="pipe-type-item">
//                 <button>{type}</button>
//               </div>
//             ))}
//           </div>
//         </div>

//         <div className="main-content">
//           <div className="market-area-header">
//             <h2 className="section-header">MARKET AREA WE SERVE</h2>
//           </div>
//           <div className="country-grid">
//             {countries.map((country, index) => (
//               <div key={index} className="country-item">
//                 <button onClick={() => handleCountryClick(country.country_code)}>
//                   <RiPlayMiniFill />{country.country_name}
//                 </button>
//               </div>
//             ))}
//           </div>

//           {loading && <p className="loading-text">Loading...</p>}

//           {selectedCountry && !loading && (
//             <div className="city-list">
//               <h2 className="section-header">Cities in {selectedCountry}</h2>
//               <table className="city-table">
//                 <thead>
//                   <tr>
//                     <th>City ID</th>
//                     <th>City Name</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {cities.length > 0 ? cities.map(city => (
//                     <tr key={city.id}>
//                       <td>{city.id}</td>
//                       <td>{city.city_name}</td>
//                     </tr>
//                   )) : (
//                     <tr>
//                       <td colSpan="2" className="no-cities">No cities found</td>
//                     </tr>
//                   )}
//                 </tbody>
//               </table>
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Country;
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import './contry.css'; // Ensure this file includes the relevant styles
// import { RiPlayMiniFill } from 'react-icons/ri';

// function Country() {
//   const [countries, setCountries] = useState([]);
//   const [cities, setCities] = useState([]);
//   const [selectedCountry, setSelectedCountry] = useState('');
//   const [loading, setLoading] = useState(false);

//   const pipeTypes = [
//     "Stainless steel pipe",
//     "EWR Stainless steel pipe",
//     "Seamless Stainless steel pipe",
//     // Add more pipe types as needed
//   ];

//   useEffect(() => {
//     setLoading(true);
//     axios.get('http://localhost:8000/api/countries')
//       .then(response => setCountries(response.data))
//       .catch(error => console.error('Error fetching countries:', error))
//       .finally(() => setLoading(false));
//   }, []);

//   const handleCountryClick = (countryCode) => {
//     setSelectedCountry(countryCode);
//     setLoading(true);
//     axios.get(`http://localhost:8000/api/cities/country/${countryCode}`)
//       .then(response => setCities(response.data))
//       .catch(error => console.error('Error fetching cities:', error))
//       .finally(() => setLoading(false));
//   };

//   return (
//     <div className="market-area">
//       <div className="market-area__container">
//         <div className="sidebar">
//           <div className="quick-contact">
//             <h2 className="section-header">QUICK CONTACT</h2>
//             <input type="text" placeholder="Your Name" />
//             <input type="email" placeholder="Your Email address" />
//             <input type="number" placeholder="Your number" />
//             <div className="country-select">
//               <select>
//                 <option>Select a country</option>
//                 <option>United States</option>
//                 <option>Canada</option>
//                 <option>United Kingdom</option>
//                 <option>Australia</option>
//                 <option>India</option>
//               </select>
//             </div>
//             <input type="text" placeholder="Type your message Here...." className="message-input" />
//             <div className="submit-button">
//               <input type="submit" value="Submit" />
//             </div>
//           </div>

//           <div className="pipe-types">
//             <h2 className="section-header">PIPE AND TUBES</h2>
//             {pipeTypes.map((type, index) => (
//               <div key={index} className="pipe-type-item">
//                 <button>{type}</button>
//               </div>
//             ))}
//           </div>
//         </div>

//         <div className="main-content">
//           <div className="market-area-header">
//             <h2 className="section-header">MARKET AREA WE SERVE</h2>
//           </div>
//           <div className="country-grid">
//             {countries.map((country, index) => (
//               <div key={index} className="country-item">
//                 <button onClick={() => handleCountryClick(country.country_code)}>
//                   <RiPlayMiniFill />{country.country_name}
//                 </button>
//               </div>
//             ))}
//           </div>

//           {loading && <p className="loading-text">Loading...</p>}

//           {selectedCountry && !loading && (
//             <div className="city-grid">
//               <h2 className="section-header">Cities in {selectedCountry}</h2>
//               <div className="city-grid-container">
//                 {cities.length > 0 ? cities.map(city => (
//                   <div key={city.id} className="city-item">
//                     <h3>{city.city_name}</h3>
//                   </div>
//                 )) : (
//                   <div className="no-cities">No cities found</div>
//                 )}
//               </div>
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Country;


// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import './contry.css'; // Ensure this file includes the relevant styles
// import { RiPlayMiniFill } from 'react-icons/ri';

// function Country() {
//   const [countries, setCountries] = useState([]);
//   const [cities, setCities] = useState([]);
//   const [selectedCountry, setSelectedCountry] = useState({ code: '', name: '' });
//   const [loading, setLoading] = useState(false);

//   const pipeTypes = [
//     "Stainless steel pipe",
//     "EWR Stainless steel pipe",
//     "Seamless Stainless steel pipe",
//     // Add more pipe types as needed
//   ];

//   useEffect(() => {
//     setLoading(true);
//     axios.get('http://localhost:8000/api/countries')
//       .then(response => setCountries(response.data))
//       .catch(error => console.error('Error fetching countries:', error))
//       .finally(() => setLoading(false));
//   }, []);

//   const handleCountryClick = (countryCode, countryName) => {
//     setSelectedCountry({ code: countryCode, name: countryName });
//     setLoading(true);
//     axios.get(`http://localhost:8000/api/cities/country/${countryCode}`)
//       .then(response => setCities(response.data))
//       .catch(error => console.error('Error fetching cities:', error))
//       .finally(() => setLoading(false));
//   };

//   return (
//     <div className="market-area">
//       <div className="market-area__container">
//         <div className="sidebar">
//           <div className="quick-contact">
//             <h2 className="section-header">QUICK CONTACT</h2>
//             <input type="text" placeholder="Your Name" />
//             <input type="email" placeholder="Your Email address" />
//             <input type="number" placeholder="Your number" />
//             <div className="country-select">
//               <select>
//                 <option>Select a country</option>
//                 <option>United States</option>
//                 <option>Canada</option>
//                 <option>United Kingdom</option>
//                 <option>Australia</option>
//                 <option>India</option>
//               </select>
//             </div>
//             <input type="text" placeholder="Type your message Here...." className="message-input" />
//             <div className="submit-button">
//               <input type="submit" value="Submit" />
//             </div>
//           </div>

//           <div className="pipe-types">
//             <h2 className="section-header">PIPE AND TUBES</h2>
//             {pipeTypes.map((type, index) => (
//               <div key={index} className="pipe-type-item">
//                 <button>{type}</button>
//               </div>
//             ))}
//           </div>
//         </div>

//         <div className="main-content">
//           <div className="market-area-header">
//             <h2 className="section-header">MARKET AREA WE SERVE</h2>
//           </div>
//           <div className="country-grid">
//             {countries.map((country, index) => (
//               <div key={index} className="country-item">
//                 <button onClick={() => handleCountryClick(country.country_code, country.country_name)}>
//                   <RiPlayMiniFill />{country.country_name}
//                 </button>
//               </div>
//             ))}
//           </div>

//           {loading && <p className="loading-text">Loading...</p>}

//           {selectedCountry.name && !loading && (
//             <div className="city-grid">
//               <h2 className="section-header">Cities in {selectedCountry.name}</h2>
//               <div className="city-grid-container">
//                 {cities.length > 0 ? cities.map(city => (
//                   <div key={city.id} className="city-item">
//                     <h3>{city.city_name}</h3>
//                   </div>
//                 )) : (
//                   <div className="no-cities">No cities found</div>
//                 )}
//               </div>
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Country;

// Country.jsx
// Country.jsx
// Country.jsx
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { RiPlayMiniFill } from 'react-icons/ri';
// import { useNavigate } from 'react-router-dom';
// import './contry.css';

// function Country() {
//   const [countries, setCountries] = useState([]);
//   const [cities, setCities] = useState([]);
//   const [selectedCountry, setSelectedCountry] = useState({ code: '', name: '' });
//   const [loading, setLoading] = useState(false);
//   const navigate = useNavigate();

//   const pipeTypes = [
//     "Stainless steel pipe",
//     "EWR Stainless steel pipe",
//     "Seamless Stainless steel pipe",
//   ];

//   useEffect(() => {
//     setLoading(true);
//     axios.get('http://localhost:8000/api/countries')
//       .then(response => setCountries(response.data))
//       .catch(error => console.error('Error fetching countries:', error))
//       .finally(() => setLoading(false));
//   }, []);

//   const handleCountryClick = (countryCode, countryName) => {
//     const newSelectedCountry = { code: countryCode, name: countryName };
//     setSelectedCountry(newSelectedCountry);
//     setLoading(true);
//     axios.get(`http://localhost:8000/api/cities/country/${countryCode}`)
//       .then(response => {
//         setCities(response.data);
//         // Navigate to the Location page with state
//         navigate('/location', { 
//           state: { selectedCountry: newSelectedCountry, cities: response.data } 
//         });
//       })
//       .catch(error => console.error('Error fetching cities:', error))
//       .finally(() => setLoading(false));
//   };

//   return (
//     <div className="market-area">
//       <div className="market-area__container">
//         <div className="sidebar">
//           <div className="quick-contact">
//             <h2 className="section-header">QUICK CONTACT</h2>
//             <input type="text" placeholder="Your Name" />
//             <input type="email" placeholder="Your Email address" />
//             <input type="number" placeholder="Your number" />
//             <div className="country-select">
//               <select>
//                 <option>Select a country</option>
//                 <option>United States</option>
//                 <option>Canada</option>
//                 <option>United Kingdom</option>
//                 <option>Australia</option>
//                 <option>India</option>
//               </select>
//             </div>
//             <input type="text" placeholder="Type your message Here...." className="message-input" />
//             <div className="submit-button">
//               <input type="submit" value="Submit" />
//             </div>
//           </div>

//           <div className="pipe-types">
//             <h2 className="section-header">PIPE AND TUBES</h2>
//             {pipeTypes.map((type, index) => (
//               <div key={index} className="pipe-type-item">
//                 <button>{type}</button>
//               </div>
//             ))}
//           </div>
//         </div>

//         <div className="main-content">
//           <div className="market-area-header">
//             <h2 className="section-header">MARKET AREA WE SERVE</h2>
//           </div>
//           <div className="country-grid">
//             {countries.map((country, index) => (
//               <div key={index} className="country-item">
//                 <button onClick={() => handleCountryClick(country.country_code, country.country_name)}>
//                   <RiPlayMiniFill />{country.country_name}
//                 </button>
//               </div>
//             ))}
//           </div>

//           {loading && <p className="loading-text">Loading...</p>}

//           {selectedCountry.name && !loading && (
//             <div className="city-grid">
//               <h2 className="section-header">Cities in {selectedCountry.name}</h2>
//               <div className="city-grid-container">
//                 {cities.length > 0 ? cities.map(city => (
//                   <div key={city.id} className="city-item">
//                     <h3>{city.city_name}</h3>
//                   </div>
//                 )) : (
//                   <div className="no-cities">No cities found</div>
//                 )}
//               </div>
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Country;


// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { RiPlayMiniFill } from 'react-icons/ri';
// import { useNavigate } from 'react-router-dom';
// import './contry.css';

// function Country() {
//   const [countries, setCountries] = useState([]);
//   const [cities, setCities] = useState([]);
//   const [selectedCountry, setSelectedCountry] = useState({ code: '', name: '' });
//   const [loading, setLoading] = useState(false);
//   const navigate = useNavigate();

//   const pipeTypes = [
//     "Stainless steel pipe",
//     "EWR Stainless steel pipe",
//     "Seamless Stainless steel pipe",
//   ];

//   useEffect(() => {
//     setLoading(true);
//     // Fetch countries
//     axios.get('http://localhost:8000/api/countries')
//       .then(response => setCountries(response.data))
//       .catch(error => console.error('Error fetching countries:', error))
//       .finally(() => setLoading(false));

//     // Fetch all cities on component mount
//     axios.get('http://localhost:8000/api/cities')
//       .then(response => setCities(response.data))
//       .catch(error => console.error('Error fetching cities:', error));
//   }, []);

//   const handleCountryClick = (countryCode, countryName) => {
//     const newSelectedCountry = { code: countryCode, name: countryName };
//     setSelectedCountry(newSelectedCountry);
//     setLoading(true);
//     axios.get(`http://localhost:8000/api/cities/country/${countryCode}`)
//       .then(response => {
//         setCities(response.data);
//         // Navigate to the Location page with state
//         navigate('/location', { 
//           state: { selectedCountry: newSelectedCountry, cities: response.data } 
//         });
//       })
//       .catch(error => console.error('Error fetching cities:', error))
//       .finally(() => setLoading(false));
//   };

//   return (
//     <div className="market-area">
//       <div className="market-area__container">
//         <div className="sidebar">
//           <div className="quick-contact">
//             <h2 className="section-header">QUICK CONTACT</h2>
//             <input type="text" placeholder="Your Name" />
//             <input type="email" placeholder="Your Email address" />
//             <input type="number" placeholder="Your number" />
//             <div className="country-select">
//               <select>
//                 <option>Select a country</option>
//                 <option>United States</option>
//                 <option>Canada</option>
//                 <option>United Kingdom</option>
//                 <option>Australia</option>
//                 <option>India</option>
//               </select>
//             </div>
//             <input type="text" placeholder="Type your message Here...." className="message-input" />
//             <div className="submit-button">
//               <input type="submit" value="Submit" />
//             </div>
//           </div>

//           <div className="pipe-types">
//             <h2 className="section-header">PIPE AND TUBES</h2>
//             {pipeTypes.map((type, index) => (
//               <div key={index} className="pipe-type-item">
//                 <button>{type}</button>
//               </div>
//             ))}
//           </div>
//         </div>

//         <div className="main-content">
//           <div className="market-area-header">
//             <h2 className="section-header">MARKET AREA WE SERVE</h2>
//           </div>
//           <div className="country-grid">
//             {countries.map((country, index) => (
//               <div key={index} className="country-item">
//                 <button onClick={() => handleCountryClick(country.country_code, country.country_name)}>
//                   <RiPlayMiniFill />{country.country_name}
//                 </button>
//               </div>
//             ))}
//           </div>

//           {loading && <p className="loading-text">Loading...</p>}

//           {cities.length > 0 && (
//             <div className="city-grid">
//               <h2 className="section-header">All Cities</h2>
//               <div className="city-grid-container">
//                 {cities.length > 0 ? cities.map(city => (
//                   <div key={city.id} className="city-item">
//                     <h3>{city.city_name}</h3>
//                   </div>
//                 )) : (
//                   <div className="no-cities">No cities found</div>
//                 )}
//               </div>
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Country;

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { RiPlayMiniFill } from 'react-icons/ri';
// import { useNavigate } from 'react-router-dom';
// import './contry.css';

// function Country() {
//   const [countries, setCountries] = useState([]);
//   const [cities, setCities] = useState([]);
//   const [selectedCountry, setSelectedCountry] = useState({ code: '', name: '' });
//   const [loading, setLoading] = useState(false);
//   const navigate = useNavigate();

//   const pipeTypes = [
//     "Stainless steel pipe",
//     "EWR Stainless steel pipe",
//     "Seamless Stainless steel pipe",
//   ];

//   useEffect(() => {
//     setLoading(true);
//     // Fetch countries
//     axios.get('http://localhost:8000/api/countries')
//       .then(response => setCountries(response.data))
//       .catch(error => console.error('Error fetching countries:', error))
//       .finally(() => setLoading(false));

//     // Fetch all cities on component mount
//     axios.get('http://localhost:8000/api/cities')
//       .then(response => setCities(response.data))
//       .catch(error => console.error('Error fetching cities:', error));
//   }, []);

//   const handleCountryClick = (countryCode, countryName) => {
//     const newSelectedCountry = { code: countryCode, name: countryName };
//     setSelectedCountry(newSelectedCountry);
//     setLoading(true);
//     axios.get(`http://localhost:8000/api/cities/country/${countryCode}`)
//       .then(response => {
//         setCities(response.data);
//         // Navigate to the Location page with state
//         navigate('/location', { 
//           state: { selectedCountry: newSelectedCountry, cities: response.data } 
//         });
//       })
//       .catch(error => console.error('Error fetching cities:', error))
//       .finally(() => setLoading(false));
//   };

//   return (
//     <div className="market-area">
//       <div className="market-area__container">
//         <div className="sidebar">
//           <div className="quick-contact">
//             <h2 className="section-header">QUICK CONTACT</h2>
//             <input type="text" placeholder="Your Name" />
//             <input type="email" placeholder="Your Email address" />
//             <input type="number" placeholder="Your number" />
//             <div className="country-select">
//               <select>
//                 <option>Select a country</option>
//                 <option>United States</option>
//                 <option>Canada</option>
//                 <option>United Kingdom</option>
//                 <option>Australia</option>
//                 <option>India</option>
//               </select>
//             </div>
//             <input type="text" placeholder="Type your message Here...." className="message-input" />
//             <div className="submit-button">
//               <input type="submit" value="Submit" />
//             </div>
//           </div>

//           <div className="pipe-types">
//             <h2 className="section-header">PIPE AND TUBES</h2>
//             {pipeTypes.map((type, index) => (
//               <div key={index} className="pipe-type-item">
//                 <button>{type}</button>
//               </div>
//             ))}
//           </div>
//         </div>

//         <div className="main-content">
//           <div className="market-area-header">
//             <h2 className="section-header">MARKET AREA WE SERVE</h2>
//           </div>
//           <div className="country-grid">
//             {countries.map((country, index) => (
//               <div key={index} className="country-item">
//                 <button onClick={() => handleCountryClick(country.country_code, country.country_name)}>
//                   <RiPlayMiniFill />{country.country_name}
//                 </button>
//               </div>
//             ))}
//           </div>

//           {loading && <p className="loading-text">Loading...</p>}

//           {cities.length > 0 && (
//             <div className="city-list">
//               <h2 className="section-header">Cities in {selectedCountry.name}</h2>
//               <table className="city-table">
//                 <thead>
//                   <tr>
//                     <th>City Name</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {cities.length > 0 ? cities.map(city => (
//                     <tr key={city.id}>
//                       <td>{city.city_name}</td>
//                     </tr>
//                   )) : (
//                     <tr>
//                       <td colSpan="1" className="no-cities">No cities found</td>
//                     </tr>
//                   )}
//                 </tbody>
//               </table>
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Country;


// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { RiPlayMiniFill } from 'react-icons/ri';
// import { useNavigate } from 'react-router-dom';
// import './contry.css'; // Ensure the CSS file name is correct

// function Country() {
//   const [countries, setCountries] = useState([]);
//   const [cities, setCities] = useState([]);
//   const [selectedCountry, setSelectedCountry] = useState({ code: '', name: '' });
//   const [loading, setLoading] = useState(false);
//   const navigate = useNavigate();

//   const pipeTypes = [
//     "Stainless steel pipe",
//     "EWR Stainless steel pipe",
//     "Seamless Stainless steel pipe",
//   ];

//   useEffect(() => {
//     setLoading(true);
//     // Fetch countries
//     axios.get('http://localhost:8000/api/countries')
//       .then(response => setCountries(response.data))
//       .catch(error => console.error('Error fetching countries:', error))
//       .finally(() => setLoading(false));

//     // Fetch all cities on component mount
//     axios.get('http://localhost:8000/api/cities/country/${countryCode}')
//       .then(response => setCities(response.data))
//       .catch(error => console.error('Error fetching cities:', error));
//   }, []);

//   const handleCountryClick = (countryCode, countryName) => {
//     const newSelectedCountry = { code: countryCode, name: countryName };
//     setSelectedCountry(newSelectedCountry);
//     setLoading(true);
//     axios.get(`http://localhost:8000/api/cities/country/${countryCode}`)
//       .then(response => {
//         setCities(response.data);
//         // Navigate to the Location page with state
//         navigate('/location', { 
//           state: { selectedCountry: newSelectedCountry, cities: response.data } 
//         });
//       })
//       .catch(error => console.error('Error fetching cities:', error))
//       .finally(() => setLoading(false));
//   };

//   return (
//     <div className="market-area">
//       <div className="market-area__container">
//         <div className="sidebar">
//           <div className="quick-contact">
//             <h2 className="section-header">QUICK CONTACT</h2>
//             <input type="text" placeholder="Your Name" />
//             <input type="email" placeholder="Your Email address" />
//             <input type="number" placeholder="Your number" />
//             <div className="country-select">
//               <select>
//                 <option>Select a country</option>
//                 {countries.map(country => (
//                   <option key={country.country_code} value={country.country_code}>
//                     {country.country_name}
//                   </option>
//                 ))}
//               </select>
//             </div>
//             <input type="text" placeholder="Type your message Here...." className="message-input" />
//             <div className="submit-button">
//               <input type="submit" value="Submit" />
//             </div>
//           </div>

//           <div className="pipe-types">
//             <h2 className="section-header">PIPE AND TUBES</h2>
//             {pipeTypes.map((type, index) => (
//               <div key={index} className="pipe-type-item">
//                 <button>{type}</button>
//               </div>
//             ))}
//           </div>
//         </div>

//         <div className="main-content">
//           <div className="market-area-header">
//             <h2 className="section-header">MARKET AREA WE SERVE</h2>
//           </div>
//           <div className="country-grid">
//             {countries.map((country) => (
//               <div key={country.country_code} className="country-item">
//                 <button onClick={() => handleCountryClick(country.country_code, country.country_name)}>
//                   <RiPlayMiniFill />{country.country_name}
//                 </button>
//               </div>
//             ))}
//           </div>

//           {loading && <p className="loading-text">Loading...</p>}

//           {cities.length > 0 && (
//             <div className="city-list">
//               <h2 className="section-header">Cities in {selectedCountry.name}</h2>
//               <table className="city-table">
//                 <thead>
//                   <tr>
//                     <th>City Name</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {cities.length > 0 ? cities.map(city => (
//                     <tr key={city.id}>
//                       <td>{city.city_name}</td>
//                     </tr>
//                   )) : (
//                     <tr>
//                       <td colSpan="1" className="no-cities">No cities found</td>
//                     </tr>
//                   )}
//                 </tbody>
//               </table>
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Country;

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import './contry.css'; // Ensure the CSS file name is correct

// function Country() {
//   const [countries, setCountries] = useState([]);
//   const [citiesByCountry, setCitiesByCountry] = useState({});
//   const [loading, setLoading] = useState(false);

//   const pipeTypes = [
//     "Stainless steel pipe",
//     "EWR Stainless steel pipe",
//     "Seamless Stainless steel pipe",
//   ];

//   useEffect(() => {
//     setLoading(true);

//     // Fetch countries
//     axios.get('http://localhost:8000/api/countries')
//       .then(response => {
//         const countriesData = response.data;
//         setCountries(countriesData);

//         // Fetch cities for each country
//         const fetchCitiesPromises = countriesData.map(country =>
//           axios.get(`http://localhost:8000/api/cities/country/${country.country_code}`)
//             .then(response => ({
//               countryCode: country.country_code,
//               cities: response.data
//             }))
//             .catch(error => {
//               console.error(`Error fetching cities for country ${country.country_code}:`, error);
//               return { countryCode: country.country_code, cities: [] }; // Return empty array on error
//             })
//         );

//         Promise.all(fetchCitiesPromises)
//           .then(citiesResponses => {
//             const citiesMap = citiesResponses.reduce((acc, { countryCode, cities }) => {
//               acc[countryCode] = cities;
//               return acc;
//             }, {});
//             setCitiesByCountry(citiesMap);
//           })
//           .catch(error => console.error('Error fetching cities data:', error))
//           .finally(() => setLoading(false));
//       })
//       .catch(error => console.error('Error fetching countries:', error));
//   }, []);

//   return (
//     <div className="market-area">
//       <div className="market-area__container">
//         <div className="sidebar">
//           <div className="quick-contact">
//             <h2 className="section-header">QUICK CONTACT</h2>
//             <input type="text" placeholder="Your Name" />
//             <input type="email" placeholder="Your Email address" />
//             <input type="number" placeholder="Your number" />
//             <div className="country-select">
//               <select>
//                 <option>Select a country</option>
//                 {countries.map(country => (
//                   <option key={country.country_code} value={country.country_code}>
//                     {country.country_name}
//                   </option>
//                 ))}
//               </select>
//             </div>
//             <input type="text" placeholder="Type your message Here...." className="message-input" />
//             <div className="submit-button">
//               <input type="submit" value="Submit" />
//             </div>
//           </div>

//           <div className="pipe-types">
//             <h2 className="section-header">PIPE AND TUBES</h2>
//             {pipeTypes.map((type, index) => (
//               <div key={index} className="pipe-type-item">
//                 <button>{type}</button>
//               </div>
//             ))}
//           </div>
//         </div>

//         <div className="main-content">
//           <div className="market-area-header">
//             <h2 className="section-header">MARKET AREA WE SERVE</h2>
//           </div>
//           <div className="country-grid">
//             {countries.map(country => (
//               <div key={country.country_code} className="country-item">
//                 <h3>{country.country_name}</h3>
//                 <div className="city-list">
//                   <h4>Cities:</h4>
//                   {citiesByCountry[country.country_code] ? (
//                     citiesByCountry[country.country_code].length > 0 ? (
//                       <table className="city-table">
//                         <thead>
//                           <tr>
//                             <th>City Name</th>
//                           </tr>
//                         </thead>
//                         <tbody>
//                           {citiesByCountry[country.country_code].map(city => (
//                             <tr key={city.city_id}>
//                               <td>{city.city_name}</td>
//                             </tr>
//                           ))}
//                         </tbody>
//                       </table>
//                     ) : (
//                       <p>No cities found</p>
//                     )
//                   ) : (
//                     <p>Loading cities...</p>
//                   )}
//                 </div>
//               </div>
//             ))}
//           </div>

//           {loading && <p className="loading-text">Loading...</p>}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Country;

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import './contry.css'; // Ensure the CSS file name is correct

// function Country() {
//   const [countries, setCountries] = useState([]);
//   const [citiesByCountry, setCitiesByCountry] = useState({});
//   const [loading, setLoading] = useState(false);

//   const pipeTypes = [
//     "Stainless steel pipe",
//     "EWR Stainless steel pipe",
//     "Seamless Stainless steel pipe",
//   ];

//   useEffect(() => {
//     setLoading(true);

//     // Fetch countries
//     axios.get('http://localhost:8000/api/countries')
//       .then(response => {
//         const countriesData = response.data;
//         setCountries(countriesData);

//         // Fetch cities for each country
//         const fetchCitiesPromises = countriesData.map(country =>
//           axios.get(`http://localhost:8000/api/cities/country/${country.country_code}`)
//             .then(response => ({
//               countryCode: country.country_code,
//               cities: response.data
//             }))
//             .catch(error => {
//               console.error(`Error fetching cities for country ${country.country_code}:`, error);
//               return { countryCode: country.country_code, cities: [] }; // Return empty array on error
//             })
//         );

//         Promise.all(fetchCitiesPromises)
//           .then(citiesResponses => {
//             const citiesMap = citiesResponses.reduce((acc, { countryCode, cities }) => {
//               acc[countryCode] = cities;
//               return acc;
//             }, {});
//             setCitiesByCountry(citiesMap);
//           })
//           .catch(error => console.error('Error fetching cities data:', error))
//           .finally(() => setLoading(false));
//       })
//       .catch(error => console.error('Error fetching countries:', error));
//   }, []);

//   return (
//     <div className="market-area">
//       <div className="market-area__container">
//         <div className="sidebar">
//           <div className="quick-contact">
//             <h2 className="section-header">QUICK CONTACT</h2>
//             <input type="text" placeholder="Your Name" />
//             <input type="email" placeholder="Your Email address" />
//             <input type="number" placeholder="Your number" />
//             <div className="country-select">
//               <select>
//                 <option>Select a country</option>
//                 {countries.map(country => (
//                   <option key={country.country_code} value={country.country_code}>
//                     {country.country_name}
//                   </option>
//                 ))}
//               </select>
//             </div>
//             <input type="text" placeholder="Type your message Here...." className="message-input" />
//             <div className="submit-button">
//               <input type="submit" value="Submit" />
//             </div>
//           </div>

//           <div className="pipe-types">
//             <h2 className="section-header">PIPE AND TUBES</h2>
//             {pipeTypes.map((type, index) => (
//               <div key={index} className="pipe-type-item">
//                 <button>{type}</button>
//               </div>
//             ))}
//           </div>
//         </div>

//         <div className="main-content">
//           <div className="market-area-header">
//             <h2 className="section-header">MARKET AREA WE SERVE</h2>
//           </div>
//           <div className="country-grid">
//             {countries.map(country => (
//               <div key={country.country_code} className="country-item">
//                 <h3>{country.country_name}</h3>
//                 <div className="city-list">
//                   <h4>Cities:</h4>
//                   {citiesByCountry[country.country_code] ? (
//                     citiesByCountry[country.country_code].length > 0 ? (
//                       <table className="city-table">
//                         <thead>
//                           <tr>
//                             <th>City Name</th>
//                           </tr>
//                         </thead>
//                         <tbody>
//                           {citiesByCountry[country.country_code].map(city => (
//                             <tr key={city.city_id}>
//                               <td>{city.city_name}</td>
//                             </tr>
//                           ))}
//                         </tbody>
//                       </table>
//                     ) : (
//                       <p>No cities found</p>
//                     )
//                   ) : (
//                     <p>Loading cities...</p>
//                   )}
//                 </div>
//               </div>
//             ))}
//           </div>

//           {loading && <p className="loading-text">Loading...</p>}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Country;
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import './contry.css'; // Ensure the CSS file name is correct

// function Country() {
//   const [countries, setCountries] = useState([]);
//   const [citiesByCountry, setCitiesByCountry] = useState({});
//   const [loading, setLoading] = useState(false);

//   const pipeTypes = [
//     "Stainless steel pipe",
//     "EWR Stainless steel pipe",
//     "Seamless Stainless steel pipe",
//   ];

//   useEffect(() => {
//     setLoading(true);

//     // Fetch countries
//     axios.get('http://localhost:8000/api/countries')
//       .then(response => {
//         const countriesData = response.data;
//         setCountries(countriesData);

//         // Fetch cities for each country
//         const fetchCitiesPromises = countriesData.map(country =>
//           axios.get(`http://localhost:8000/api/cities/country/${country.country_code}`)
//             .then(response => ({
//               countryCode: country.country_code,
//               cities: response.data
//             }))
//             .catch(error => {
//               console.error(`Error fetching cities for country ${country.country_code}:`, error);
//               return { countryCode: country.country_code, cities: [] }; // Return empty array on error
//             })
//         );

//         Promise.all(fetchCitiesPromises)
//           .then(citiesResponses => {
//             const citiesMap = citiesResponses.reduce((acc, { countryCode, cities }) => {
//               acc[countryCode] = cities;
//               return acc;
//             }, {});
//             setCitiesByCountry(citiesMap);
//           })
//           .catch(error => console.error('Error fetching cities data:', error))
//           .finally(() => setLoading(false));
//       })
//       .catch(error => console.error('Error fetching countries:', error));
//   }, []);

//   return (
//     <div className="market-area">
//       <div className="market-area__container">
//         <div className="sidebar">
//           <div className="quick-contact">
//             <h2 className="section-header">QUICK CONTACT</h2>
//             <input type="text" placeholder="Your Name" />
//             <input type="email" placeholder="Your Email address" />
//             <input type="number" placeholder="Your number" />
//             <div className="country-select">
//               <select>
//                 <option>Select a country</option>
//                 {countries.map(country => (
//                   <option key={country.country_code} value={country.country_code}>
//                     {country.country_name}
//                   </option>
//                 ))}
//               </select>
//             </div>
//             <input type="text" placeholder="Type your message Here...." className="message-input" />
//             <div className="submit-button">
//               <input type="submit" value="Submit" />
//             </div>
//           </div>

//           <div className="pipe-types">
//             <h2 className="section-header">PIPE AND TUBES</h2>
//             {pipeTypes.map((type, index) => (
//               <div key={index} className="pipe-type-item">
//                 <button>{type}</button>
//               </div>
//             ))}
//           </div>
//         </div>

//         <div className="main-content">
//           <div className="market-area-header">
//             <h2 className="section-header">MARKET AREA WE SERVE</h2>
//           </div>
//           <div className="country-grid">
//             {countries.map(country => (
//               <div key={country.country_code} className="country-item">
//                 <h3>{country.country_name}</h3>
//                 <h4>Cities:</h4>
//                 {citiesByCountry[country.country_code] ? (
//                   citiesByCountry[country.country_code].length > 0 ? (
//                     <table className="city-table">
//                       <thead>
//                         <tr>
//                           <th>City Name</th>
//                         </tr>
//                       </thead>
//                       <tbody>
//                         {citiesByCountry[country.country_code].map(city => (
//                           <tr key={city.city_id}>
//                             <td>{city.city_name}</td>
//                           </tr>
//                         ))}
//                       </tbody>
//                     </table>
//                   ) : (
//                     <p>No cities found</p>
//                   )
//                 ) : (
//                   <p>Loading cities...</p>
//                 )}
//               </div>
//             ))}
//           </div>

//           {loading && <p className="loading-text">Loading...</p>}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Country;




// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { RiPlayMiniFill } from 'react-icons/ri';
// import { useNavigate } from 'react-router-dom';
// import './contry.css'; // Ensure the CSS file name is correct

// function Country() {
//   const [countries, setCountries] = useState([]);
//   const [citiesByCountry, setCitiesByCountry] = useState({});
//   const [selectedCountry, setSelectedCountry] = useState({ code: '', name: '' });
//   const [loading, setLoading] = useState(false);
//   const navigate = useNavigate();

//   const pipeTypes = [
//     "Stainless steel pipe",
//     "EWR Stainless steel pipe",
//     "Seamless Stainless steel pipe",
//   ];

//   useEffect(() => {
//     setLoading(true);

//     // Fetch countries and cities in parallel
//     axios.get('http://localhost:8000/api/countries')
//       .then(response => {
//         const countriesData = response.data;
//         setCountries(countriesData);

//         // Fetch cities for each country
//         const fetchCitiesPromises = countriesData.map(country =>
//           axios.get(`http://localhost:8000/api/cities/country/${country.country_code}`)
//             .then(response => ({
//               countryCode: country.country_code,
//               cities: response.data
//             }))
//             .catch(error => {
//               console.error(`Error fetching cities for country ${country.country_code}:`, error);
//               return { countryCode: country.country_code, cities: [] }; // Return empty array on error
//             })
//         );

//         Promise.all(fetchCitiesPromises)
//           .then(citiesResponses => {
//             const citiesMap = citiesResponses.reduce((acc, { countryCode, cities }) => {
//               acc[countryCode] = cities;
//               return acc;
//             }, {});
//             setCitiesByCountry(citiesMap);
//           })
//           .catch(error => console.error('Error fetching cities data:', error))
//           .finally(() => setLoading(false));
//       })
//       .catch(error => console.error('Error fetching countries:', error));
//   }, []);

//   const handleCountryClick = (countryCode, countryName) => {
//     const newSelectedCountry = { code: countryCode, name: countryName };
//     setSelectedCountry(newSelectedCountry);
//     navigate('/location', {
//       state: { selectedCountry: newSelectedCountry, cities: citiesByCountry[countryCode] || [] }
//     });
//   };

//   return (
//     <div className="market-area">
//       <div className="market-area__container">
//         <div className="sidebar">
//           <div className="quick-contact">
//             <h2 className="section-header">QUICK CONTACT</h2>
//             <input type="text" placeholder="Your Name" />
//             <input type="email" placeholder="Your Email address" />
//             <input type="number" placeholder="Your number" />
//             <div className="country-select">
//               <select>
//                 <option>Select a country</option>
//                 {countries.map(country => (
//                   <option key={country.country_code} value={country.country_code}>
//                     {country.country_name}
//                   </option>
//                 ))}
//               </select>
//             </div>
//             <input type="text" placeholder="Type your message Here...." className="message-input" />
//             <div className="submit-button">
//               <input type="submit" value="Submit" />
//             </div>
//           </div>

//           <div className="pipe-types">
//             <h2 className="section-header">PIPE AND TUBES</h2>
//             {pipeTypes.map((type, index) => (
//               <div key={index} className="pipe-type-item">
//                 <button>{type}</button>
//               </div>
//             ))}
//           </div>
//         </div>

//         <div className="main-content">
//           <div className="market-area-header">
//             <h2 className="section-header">MARKET AREA WE SERVE</h2>
//           </div>
//           <div className="country-grid">
//             {countries.map(country => (
//               <div key={country.country_code} className="country-item">
//                 <button onClick={() => handleCountryClick(country.country_code, country.country_name)}>
//                   <RiPlayMiniFill /> {country.country_name}
//                 </button>
//               </div>
//             ))}
//           </div>

//           {loading && <p className="loading-text">Loading...</p>}

//           {Object.keys(citiesByCountry).length > 0 && (
//             <div className="cities-container">
//               {Object.entries(citiesByCountry).map(([countryCode, cities]) => (
//                 <div key={countryCode} className="country-section">
//                   <h2 className="section-header">Cities in {countries.find(c => c.country_code === countryCode)?.country_name || 'Unknown'}</h2>
//                   <div className="city-flex">
//                     {cities.length > 0 ? cities.map(city => (
//                       <div key={city.city_id} className="city-item">
//                         {city.city_name}
//                       </div>
//                     )) : (
//                       <p>No cities found</p>
//                     )}
//                   </div>
//                 </div>
//               ))}
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Country;

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { RiPlayMiniFill } from 'react-icons/ri';
// import { useNavigate } from 'react-router-dom';
// import './contry.css'; // Ensure the CSS file name is correct

// function Country() {
//   const [countries, setCountries] = useState([]);
//   const [citiesByCountry, setCitiesByCountry] = useState({});
//   const [selectedCountry, setSelectedCountry] = useState({ code: '', name: '' });
//   const [loading, setLoading] = useState(false);
//   const navigate = useNavigate();

//   const pipeTypes = [
//     "Stainless steel pipe",
//     "EWR Stainless steel pipe",
//     "Seamless Stainless steel pipe",
//   ];

//   useEffect(() => {
//     setLoading(true);

//     // Fetch countries and cities in parallel
//     axios.get('http://localhost:8000/api/countries')
//       .then(response => {
//         const countriesData = response.data;
//         setCountries(countriesData);

//         // Fetch cities for each country
//         const fetchCitiesPromises = countriesData.map(country =>
//           axios.get(`http://localhost:8000/api/cities/country/${country.country_code}`)
//             .then(response => ({
//               countryCode: country.country_code,
//               cities: response.data
//             }))
//             .catch(error => {
//               console.error(`Error fetching cities for country ${country.country_code}:`, error);
//               return { countryCode: country.country_code, cities: [] }; // Return empty array on error
//             })
//         );

//         Promise.all(fetchCitiesPromises)
//           .then(citiesResponses => {
//             const citiesMap = citiesResponses.reduce((acc, { countryCode, cities }) => {
//               acc[countryCode] = cities;
//               return acc;
//             }, {});
//             setCitiesByCountry(citiesMap);
//           })
//           .catch(error => console.error('Error fetching cities data:', error))
//           .finally(() => setLoading(false));
//       })
//       .catch(error => console.error('Error fetching countries:', error));
//   }, []);

//   const handleCountryClick = (countryCode, countryName) => {
//     const newSelectedCountry = { code: countryCode, name: countryName };
//     setSelectedCountry(newSelectedCountry);
//     navigate('/location', {
//       state: { selectedCountry: newSelectedCountry, cities: citiesByCountry[countryCode] || [] }
//     });
//   };

//   return (
//     <div className="market-area">
//       <div className="market-area__container">
//         <div className="sidebar">
//           <div className="quick-contact">
//             <h2 className="section-header">QUICK CONTACT</h2>
//             <input type="text" placeholder="Your Name" />
//             <input type="email" placeholder="Your Email address" />
//             <input type="number" placeholder="Your number" />
//             <div className="country-select">
//               <select>
//                 <option>Select a country</option>
//                 {countries.map(country => (
//                   <option key={country.country_code} value={country.country_code}>
//                     {country.country_name}
//                   </option>
//                 ))}
//               </select>
//             </div>
//             <input type="text" placeholder="Type your message Here...." className="message-input" />
//             <div className="submit-button">
//               <input type="submit" value="Submit" />
//             </div>
//           </div>

//           <div className="pipe-types">
//             <h2 className="section-header">PIPE AND TUBES</h2>
//             {pipeTypes.map((type, index) => (
//               <div key={index} className="pipe-type-item">
//                 <button>{type}</button>
//               </div>
//             ))}
//           </div>
//         </div>

//         <div className="main-content">
//           <div className="market-area-header">
//             <h2 className="section-header">MARKET AREA WE SERVE</h2>
//           </div>
//           <div className="country-grid">
//             {countries.map(country => (
//               <div key={country.country_code} className="country-item">
//                 <button onClick={() => handleCountryClick(country.country_code, country.country_name)}>
//                   <RiPlayMiniFill /> {country.country_name}
//                 </button>
//               </div>
//             ))}
//           </div>

//           {loading && <p className="loading-text">Loading...</p>}

//           {Object.keys(citiesByCountry).length > 0 && (
//             <div className="cities-container">
//               {Object.entries(citiesByCountry).map(([countryCode, cities]) => (
//                 <div key={countryCode} className="country-section">
//                   <h2 className="section-header"> {countries.find(c => c.country_code === countryCode)?.country_name || 'Unknown'}</h2>
//                   <div className="city-flex">
//                     {cities.length > 0 ? cities.map(city => (
//                       <div key={city.city_id} className="country-item">
//                        <RiPlayMiniFill /> {city.city_name}
//                       </div>
//                     )) : (
//                       <p>No cities found</p>
//                     )}
//                   </div>
//                 </div>
//               ))}
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Country;

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { RiPlayMiniFill } from 'react-icons/ri';
// import { useNavigate } from 'react-router-dom';
// import './contry.css'; // Ensure the CSS file name is correct

// function Country() {
//   const [countries, setCountries] = useState([]);
//   const [citiesByCountry, setCitiesByCountry] = useState({});
//   const [selectedCountry, setSelectedCountry] = useState({ code: '', name: '' });
//   const [loading, setLoading] = useState(false);
//   const navigate = useNavigate();

//   const pipeTypes = [
//     "Stainless steel pipe",
//     "EWR Stainless steel pipe",
//     "Seamless Stainless steel pipe",
//   ];

//   useEffect(() => {
//     setLoading(true);

//     axios.get('http://localhost:8000/api/countries')
//       .then(response => {
//         const countriesData = response.data;
//         setCountries(countriesData);

//         const fetchCitiesPromises = countriesData.map(country =>
//           axios.get(`http://localhost:8000/api/cities/country/${country.country_code}`)
//             .then(response => ({
//               countryCode: country.country_code,
//               cities: response.data
//             }))
//             .catch(error => {
//               console.error(`Error fetching cities for country ${country.country_code}:`, error);
//               return { countryCode: country.country_code, cities: [] };
//             })
//         );

//         Promise.all(fetchCitiesPromises)
//           .then(citiesResponses => {
//             const citiesMap = citiesResponses.reduce((acc, { countryCode, cities }) => {
//               acc[countryCode] = cities;
//               return acc;
//             }, {});
//             setCitiesByCountry(citiesMap);
//           })
//           .catch(error => console.error('Error fetching cities data:', error))
//           .finally(() => setLoading(false));
//       })
//       .catch(error => console.error('Error fetching countries:', error));
//   }, []);

//   const handleCountryClick = (countryCode, countryName) => {
//     const newSelectedCountry = { code: countryCode, name: countryName };
//     setSelectedCountry(newSelectedCountry);
//     navigate('/location', {
//       state: { selectedCountry: newSelectedCountry, cities: citiesByCountry[countryCode] || [] }
//     });
//   };

//   return (
//     <div className="market-area">
//       <div className="market-area__container">
//         <div className="sidebar">
//           <div className="quick-contact">
//             <h2 className="section-header">QUICK CONTACT</h2>
//             <input type="text" placeholder="Your Name" />
//             <input type="email" placeholder="Your Email address" />
//             <input type="number" placeholder="Your number" />
//             <div className="country-select">
//               <select>
//                 <option>Select a country</option>
//                 {countries.map(country => (
//                   <option key={country.country_code} value={country.country_code}>
//                     {country.country_name}
//                   </option>
//                 ))}
//               </select>
//             </div>
//             <input type="text" placeholder="Type your message Here...." className="message-input" />
//             <div className="submit-button">
//               <input type="submit" value="Submit" />
//             </div>
//           </div>

//           <div className="pipe-types">
//             <h2 className="section-header">PIPE AND TUBES</h2>
//             {pipeTypes.map((type, index) => (
//               <div key={index} className="pipe-type-item">
//                 <button>{type}</button>
//               </div>
//             ))}
//           </div>
//         </div>

//         <div className="main-content">
//           <div className="market-area-header">
//             <h2 className="section-header">MARKET AREA WE SERVE</h2>
//           </div>
//           <div className="grid-container">
//             <div className="grid-section">
//               <h2 className="section-header">Countries</h2>
//               <div className="grid">
//                 {countries.map(country => (
//                   <div key={country.country_code} className="grid-item">
//                     <button onClick={() => handleCountryClick(country.country_code, country.country_name)}>
//                       <RiPlayMiniFill /> {country.country_name}
//                     </button>
//                   </div>
//                 ))}
//               </div>
//             </div>

//             {loading && <p className="loading-text">Loading...</p>}

//             {Object.keys(citiesByCountry).length > 0 && (
//               <div className="grid-section">
//                 <h2 className="section-header">Cities by Country</h2>
//                 {Object.entries(citiesByCountry).map(([countryCode, cities]) => (
//                   <div key={countryCode} className="country-cities">
//                     <h3 className="section-header">
//                       {countries.find(c => c.country_code === countryCode)?.country_name}
//                     </h3>
//                     <div className="grid">
//                       {cities.map(city => (
//                         <div key={city.city_id} className="grid-item">
//                           <button>
//                             <RiPlayMiniFill /> {city.city_name}
//                           </button>
//                         </div>
//                       ))}
//                     </div>
//                   </div>
//                 ))}
//               </div>
//             )}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Country;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { RiPlayMiniFill } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import './contry.css'; // Ensure the CSS file name is correct

function Country() {
  const [countries, setCountries] = useState([]);
  const [citiesByCountry, setCitiesByCountry] = useState({});
  const [selectedCountry, setSelectedCountry] = useState({ code: '', name: '' });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const pipeTypes = [
    "Electrical Accessories",
    "PVC Coated Flexible Conduit",
    "Liquid Tight Flexible Conduit",
    "GI Pipes",
  ];

  useEffect(() => {
    setLoading(true);

    axios.get('http://localhost:8000/api/countries')
      .then(response => {
        const countriesData = response.data;
        setCountries(countriesData);

        const fetchCitiesPromises = countriesData.map(country =>
          axios.get(`http://localhost:8000/api/cities/country/${country.country_code}`)
            .then(response => ({
              countryCode: country.country_code,
              cities: response.data
            }))
            .catch(error => {
              console.error(`Error fetching cities for country ${country.country_code}:`, error);
              return { countryCode: country.country_code, cities: [] };
            })
        );

        Promise.all(fetchCitiesPromises)
          .then(citiesResponses => {
            const citiesMap = citiesResponses.reduce((acc, { countryCode, cities }) => {
              acc[countryCode] = cities;
              return acc;
            }, {});
            setCitiesByCountry(citiesMap);
          })
          .catch(error => console.error('Error fetching cities data:', error))
          .finally(() => setLoading(false));
      })
      .catch(error => console.error('Error fetching countries:', error));
  }, []);

  const handleCountryClick = (countryCode, countryName) => {
    const newSelectedCountry = { code: countryCode, name: countryName };
    setSelectedCountry(newSelectedCountry);
    navigate('/location', {
      state: { selectedCountry: newSelectedCountry, cities: citiesByCountry[countryCode] || [] }
    });
  };

  const handleCityClick = (countryCode, cityName) => {
    const newSelectedCountry = { code: countryCode, name: countries.find(c => c.country_code === countryCode)?.country_name || '' };
    navigate('/location', {
      state: { selectedCountry: newSelectedCountry, selectedCity: cityName, cities: citiesByCountry[countryCode] || [] }
    });
  };

  return (
    <div className="market-area">
      <div className="market-area__container">
        <div className="sidebar">
          <div className="quick-contact">
            <h2 className="section-header">QUICK CONTACT</h2>
            <input type="text" placeholder="Your Name" />
            <input type="email" placeholder="Your Email address" />
            <input type="number" placeholder="Your number" />
            <div className="country-select">
              <select>
                <option>Select a country</option>
                {countries.map(country => (
                  <option key={country.country_code} value={country.country_code}>
                    {country.country_name}
                  </option>
                ))}
              </select>
            </div>
            <input type="text" placeholder="Type your message Here...." className="message-input" />
            <div className="submit-button">
              <input type="submit" value="Submit" />
            </div>
          </div>

          <div className="pipe-types">
            <h2 className="section-header">PIPE AND TUBES</h2>
            {pipeTypes.map((type, index) => (
              <div key={index} className="pipe-type-item">
                <button>{type}</button>
              </div>
            ))}
          </div>
        </div>

        <div className="main-content">
          <div className="market-area-header">
            <h2 className="section-header">MARKET AREA WE SERVE</h2>
          </div>
          <div className="grid-container">
            <div className="grid-section">
              <h2 className="section-header">Countries</h2>
              <div className="grid">
                {countries.map(country => (
                  <div key={country.country_code} className="grid-item">
                    <button onClick={() => handleCountryClick(country.country_code, country.country_name)}>
                      <RiPlayMiniFill /> {country.country_name}
                    </button>
                  </div>
                ))}
              </div>
            </div>

            {loading && <p className="loading-text">Loading...</p>}

            {Object.keys(citiesByCountry).length > 0 && (
              <div className="grid-section">
                <h2 className="section-header">Cities by Country</h2>
                {Object.entries(citiesByCountry).map(([countryCode, cities]) => (
                  <div key={countryCode} className="country-cities">
                    <h3 className="section-header">
                      {countries.find(c => c.country_code === countryCode)?.country_name}
                    </h3>
                    <div className="grid">
                      {cities.map(city => (
                        <div key={city.city_id} className="grid-item">
                          <button onClick={() => handleCityClick(countryCode, city.city_name)}>
                            <RiPlayMiniFill /> {city.city_name}
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Country;
