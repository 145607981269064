// utils/Validation.js
const getValidation = (fields) => {
  const rules = {};
  if (fields.includes('email')) {
    rules.email = [
      { required: true, message: 'Please enter your email!' },
      { type: 'email', message: 'Please enter a valid email!' },
    ];
  }
  if (fields.includes('password')) {
    rules.password = [
      { required: true, message: 'Please enter your password!' },
    ];
  }
  return rules;
};

export default { getValidation };
