// import React from 'react';
// import { Layout, Card, Col, Row, Statistic, Avatar, Progress, List, Typography } from 'antd';
// import { ShoppingCartOutlined, DollarOutlined, BoxPlotOutlined, ShopOutlined, UnorderedListOutlined } from '@ant-design/icons';
// import './DashboardHome.css'; // Custom CSS for styling
// import Sidebar from "../../components/sideBar/Sidebar";
// const { Content } = Layout;
// const { Title } = Typography;

// const DashboardHome = () => {
//   // Sample data for recent activity
//   const recentActivity = [
//     { title: 'New product added: Wireless Mouse', description: 'Just now' },
//     { title: 'Order #12345 shipped', description: '15 minutes ago' },
//     { title: 'Product stock updated: Bluetooth Speaker', description: '30 minutes ago' },
//   ];

//   return (
//     <Content style={{ padding: '24px', minHeight: '100vh' }}>
//         <Sidebar/>
//       <Title level={2}>Dashboard</Title>
//       <Row gutter={16}>
//         <Col span={8}>
//           <Card>
//             <Statistic
//               title="Total Sales"
//               value={1024}
//               prefix={<ShoppingCartOutlined />}
//               suffix="Orders"
//             />
//           </Card>
//         </Col>
//         <Col span={8}>
//           <Card>
//             <Statistic
//               title="Total Revenue"
//               value={`$${123456}`}
//               prefix={<DollarOutlined />}
//             />
//           </Card>
//         </Col>
//         <Col span={8}>
//           <Card>
//             <Statistic
//               title="Total Products"
//               value={128}
//               prefix={<BoxPlotOutlined />}
//             />
//           </Card>
//         </Col>
//       </Row>

//       <Row gutter={16} style={{ marginTop: '16px' }}>
//         <Col span={12}>
//           <Card title="Recent Activity">
//             <List
//               itemLayout="horizontal"
//               dataSource={recentActivity}
//               renderItem={item => (
//                 <List.Item>
//                   <List.Item.Meta
//                     title={item.title}
//                     description={item.description}
//                   />
//                 </List.Item>
//               )}
//             />
//           </Card>
//         </Col>
//         <Col span={12}>
//           <Card title="Product Performance">
//             <div>
//               <Progress type="circle" percent={80} format={() => '80%'} strokeColor="#52c41a" />
//               <div style={{ marginTop: '16px' }}>
//                 <Progress percent={65} strokeColor="#1890ff" />
//                 <Progress percent={40} strokeColor="#ff4d4f" style={{ marginTop: '8px' }} />
//               </div>
//             </div>
//           </Card>
//         </Col>
//       </Row>

//       <Row gutter={16} style={{ marginTop: '16px' }}>
//         <Col span={8}>
//           <Card>
//             <div className="quick-link">
//               <ShopOutlined style={{ fontSize: '24px', marginRight: '8px' }} />
//               <a href="/manage-products">Manage Products</a>
//             </div>
//           </Card>
//         </Col>
//         <Col span={8}>
//           <Card>
//             <div className="quick-link">
//               <UnorderedListOutlined style={{ fontSize: '24px', marginRight: '8px' }} />
//               <a href="/orders">View Orders</a>
//             </div>
//           </Card>
//         </Col>
//         <Col span={8}>
//           <Card>
//             <div className="quick-link">
//               <BoxPlotOutlined style={{ fontSize: '24px', marginRight: '8px' }} />
//               <a href="/inventory">Manage Inventory</a>
//             </div>
//           </Card>
//         </Col>
//       </Row>
//     </Content>
//   );
// };

// export default DashboardHome;


// DashboardHome.js
import React from 'react';
import { Layout, Card, Col, Row, Statistic, Progress, List, Typography } from 'antd';
import { ShoppingCartOutlined, DollarOutlined, BoxPlotOutlined, ShopOutlined, UnorderedListOutlined } from '@ant-design/icons';
import './DashboardHome.css';
import Sidebar from "../../components/sideBar/Sidebar";

const { Content } = Layout;
const { Title } = Typography;

const DashboardHome = () => {
  // Sample data for recent activity
  const recentActivity = [
    { title: 'New product added: Wireless Mouse', description: 'Just now' },
    { title: 'Order #12345 shipped', description: '15 minutes ago' },
    { title: 'Product stock updated: Bluetooth Speaker', description: '30 minutes ago' },
  ];

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sidebar isAdmin={true} setIsLogin={() => {}} /> {/* Set isAdmin based on user role */}
      <Layout style={{ marginLeft: '300px' }}> {/* Adjust margin to accommodate sidebar */}
        <Content style={{ padding: '24px' }}>
          <Title level={2}>Dashboard</Title>
          <Row gutter={16}>
            <Col span={8}>
              <Card>
                <Statistic
                  title="Total Sales"
                  value={1024}
                  prefix={<ShoppingCartOutlined />}
                  suffix="Orders"
                />
              </Card>
            </Col>
            <Col span={8}>
              <Card>
                <Statistic
                  title="Total Revenue"
                  value={`$${123456}`}
                  prefix={<DollarOutlined />}
                />
              </Card>
            </Col>
            <Col span={8}>
              <Card>
                <Statistic
                  title="Total Products"
                  value={128}
                  prefix={<BoxPlotOutlined />}
                />
              </Card>
            </Col>
          </Row>

          <Row gutter={16} style={{ marginTop: '16px' }}>
            <Col span={12}>
              <Card title="Recent Activity">
                <List
                  itemLayout="horizontal"
                  dataSource={recentActivity}
                  renderItem={item => (
                    <List.Item>
                      <List.Item.Meta
                        title={item.title}
                        description={item.description}
                      />
                    </List.Item>
                  )}
                />
              </Card>
            </Col>
            <Col span={12}>
              <Card title="Product Performance">
                <div>
                  <Progress type="circle" percent={80} format={() => '80%'} strokeColor="#52c41a" />
                  <div style={{ marginTop: '16px' }}>
                    <Progress percent={65} strokeColor="#1890ff" />
                    <Progress percent={40} strokeColor="#ff4d4f" style={{ marginTop: '8px' }} />
                  </div>
                </div>
              </Card>
            </Col>
          </Row>

          <Row gutter={16} style={{ marginTop: '16px' }}>
            <Col span={8}>
              <Card>
                <div className="quick-link">
                  <ShopOutlined style={{ fontSize: '24px', marginRight: '8px' }} />
                  <a href="/manage-products">Manage Products</a>
                </div>
              </Card>
            </Col>
            <Col span={8}>
              <Card>
                <div className="quick-link">
                  <UnorderedListOutlined style={{ fontSize: '24px', marginRight: '8px' }} />
                  <a href="/orders">View Orders</a>
                </div>
              </Card>
            </Col>
            <Col span={8}>
              <Card>
                <div className="quick-link">
                  <BoxPlotOutlined style={{ fontSize: '24px', marginRight: '8px' }} />
                  <a href="/inventory">Manage Inventory</a>
                </div>
              </Card>
            </Col>
          </Row>
        </Content>
      </Layout>
    </Layout>
  );
};

export default DashboardHome;
