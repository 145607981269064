// import React, { useState, useEffect } from 'react';
// import './update.css';
// import cer4 from '../../Images/cer4.jpg';
// import ulfmc1 from '../../Images/ulfmc1.jpg';
// import ueft1 from '../../Images/ueft1.png';
// import ugi1 from '../../Images/ugi1.jpg';
// import ugs1 from '../../Images/ugs1.jpg';
// import gip1 from '../../Images/gip1.jpg';
// const CardCarousel = ({ cards }) => {
//   const [startIndex, setStartIndex] = useState(0);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setStartIndex((prevIndex) => (prevIndex + 1) % cards.length);
//     }, 5000); // Change cards every 5 seconds
//     return () => clearInterval(interval);
//   }, [cards.length]);

//   const nextCards = () => {
//     setStartIndex((prevIndex) => (prevIndex + 1) % cards.length);
//   };

//   const prevCards = () => {
//     setStartIndex((prevIndex) => (prevIndex - 1 + cards.length) % cards.length);
//   };

//   const goToCard = (index) => {
//     setStartIndex(index);
//   };

//   return (
//     <div className="carddd-carousel-wrapper">
//       <button className="navvv-button prev" onClick={prevCards}>&lt;</button>
//       <div className="carddd-carousel">
//         <div className="carddd-container">
//           {[0, 1, 2].map((offset) => {
//             const index = (startIndex + offset) % cards.length;
//             const card = cards[index];
//             return (
//               <div key={index} className="carddd">
//                 <img src={card.image} alt={card.productName} />
//                 <div className="carddd-content">
//                   <div className="carddd-header">
//                     <span className="productt-name">{card.productName}</span>
//                     <span className="datee">{card.date}</span>
//                   </div>
//                   <div className="readd-time">{card.readTime}</div>
//                   <h2>{card.heading}</h2>
//                   <p>{card.paragraph}</p>
//                   <a href={card.readMoreLink} className="readd-more">Read More</a>
//                 </div>
//               </div>
//             );
//           })}
//         </div>
//         <div className="dotss-container">
//           {cards.map((_, index) => (
//             <span
//               key={index}
//               className={`dot ${index === startIndex ? 'active' : ''}`}
//               onClick={() => goToCard(index)}
//             ></span>
//           ))}
//         </div>
//       </div>
//       <button className="navvv-button next" onClick={nextCards}>&gt;</button>
//     </div>
//   );
// };

// const Update = () => {
//   const cards = [
//     {
//       "image": cer4,
//       "productName": "Techno Flex Updates",
//       "date": "2024-08-09",
//       "readTime": "3 min read",
//       "heading": "Techno Flex Achieves ISO Certification",
//       "paragraph": "Techno Flex is now an ISO Certified company and is also affiliated with CRISIL Rating & NSIC. We are committed to delivering safe and high-quality products by meticulously controlling raw material procurement and monitoring process parameters.",
//       "readMoreLink": "#"
//     },
//     {
//       "image": ulfmc1,
//       "productName": "Product Innovations",
//       "date": "2024-08-05",
//       "readTime": "4 min read",
//       "heading": "Introducing Type UA - Liquid-Tight Flexible Metal Conduit (LFMC)",
//       "paragraph": "Our Type UA Liquid-Tight Flexible Metal Conduit is designed with a continuously interlocked hot-dipped zinc galvanized steel core for exceptional crush and corrosion resistance. It features a durable, flame-retardant PVC jacket and is approved for direct burial and use in concrete.",
//       "readMoreLink": "#"
//     },
//     {
//       "image": ueft1,
//       "productName": "Product Offerings",
//       "date": "2024-07-30",
//       "readTime": "4 min read",
//       "heading": "Comprehensive Range of Flexible Conduit Accessories",
//       "paragraph": "Explore our complete range of flexible conduit accessories, including conduit accessories, zinc die-casting couplings, SS conduit glands, cable glands, brass lock nuts, stopping plugs, and brass reducers and enlargements.",
//       "readMoreLink": "#"
//     },
//     {
//       "image": ueft1,
//       "productName": "High Quality Electrical Fittings",
//       "date": "2024-07-25",
//       "readTime": "4 min read",
//       "heading": "Guaranteed for Life: Our Electrical Fittings",
//       "paragraph": "Techno Flex strives to exceed customer expectations with our wide range of electrical fittings, including EMT connectors, PVC, and flexible conduit fittings. All our products are guaranteed for life, ensuring the highest quality and reliability.",
//       "readMoreLink": "#"
//     },
//     {
//       "image": ugi1,
//       "productName": "GI Conduit and Junction Box",
//       "date": "2024-07-20",
//       "readTime": "5 min read",
//       "heading": "High-Quality GI Conduits and Junction Boxes",
//       "paragraph": "As an ISO 9001:2008 accredited company, Techno Flex is a leading manufacturer of high-quality flexible conduits and accessories. Our products are engineered from superior-grade metals and alloys, ensuring durability and resistance against corrosion.",
//       "readMoreLink": "#"
//     },
//     {
//       "image": ugs1,
//       "productName": "Flexible Conduit Specifications",
//       "date": "2024-07-15",
//       "readTime": "5 min read",
//       "heading": "Specifications of Our Galvanized Steel Core Flexible Conduit",
//       "paragraph": "Our flexible conduit features a galvanized steel core with a PVC jacket, available in sizes from 3/8\" to 4\". It is designed for high mechanical strength, corrosion resistance, and easy wire pull-through, suitable for various applications including intelligent buildings and construction.",
//       "readMoreLink": "#"
//     },
//     {
//       "image": gip1,
//       "productName": "Why Choose Techno Flex",
//       "date": "2024-07-10",
//       "readTime": "3 min read",
//       "heading": "Why Choose Techno Flex?",
//       "paragraph": "Techno Flex is India’s leading supplier of electrical pipes, strut channels, and fittings. We offer superior quality, competitive pricing, and excellent service. Our products are inspected for quality assurance and we ensure timely delivery with a focus on customer trust and satisfaction.",
//       "readMoreLink": "#"
//     }
//   ];
  

//   return (
//     <div className="Update">
//       <h1>News & Updates</h1>
//       <CardCarousel cards={cards} />
//     </div>
//   );
// };

// export default Update;

import React, { useRef, useEffect, useState } from 'react';
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaWhatsapp, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import './update.css';
import ulfmc1 from '../../Images/ulfmc1.jpg';
import ueft1 from '../../Images/ueft1.png';
import gip1 from '../../Images/gip1.jpg';
import ugs1 from '../../Images/ugs1.jpg';
const BlogCard = ({ blog }) => {
  return (
    <div className="blog-card">
      <div className="img-wrapper">
        <img src={blog.imageUrl} alt={blog.title} />
        <div className="blog-share-icons">
          <a href={`https://www.facebook.com/sharer/sharer.php?u=${blog.url}`} target="_blank" rel="noopener noreferrer" className="blog-share-icon">
            <FaFacebookF />
          </a>
          <a href={`https://twitter.com/intent/tweet?text=${blog.url}`} target="_blank" rel="noopener noreferrer" className="blog-share-icon">
            <FaTwitter />
          </a>
          <a href={`https://www.linkedin.com/shareArticle?url=${blog.url}`} target="_blank" rel="noopener noreferrer" className="blog-share-icon">
            <FaLinkedinIn />
          </a>
          <a href={`https://api.whatsapp.com/send?text=${blog.url}`} target="_blank" rel="noopener noreferrer" className="blog-share-icon">
            <FaWhatsapp />
          </a>
        </div>
      </div>
      <div className="blog-info">
        <div className="blog-meta">
          <h6>{blog.category}</h6>
          <h6>{blog.date}</h6>
        </div>
        <p>
          <span className="blog-read-time">Read Time: {blog.readTime}</span>
        </p>
        <h3 className="blog-title">{blog.title}</h3>
        <h4 className="blog-excerpt">{blog.excerpt}</h4>
        <a href={blog.url} className="blog-read-more" target="_blank" rel="noopener noreferrer">READ MORE</a>
      </div>
    </div>
  );
};

const Update= () => {
  const blogsData = [
    {
      imageUrl: gip1,
      url: '#',
      category: 'Company News',
      date: 'September 1, 2024',
      readTime: '05:00 minutes',
      title: 'Techno Flex: A Commitment to Quality and Safety',
      excerpt: 'Techno Flex is an ISO Certified company and affiliated with CRISIL Rating & NSIC. We are committed to supplying safe and high-quality products...',
    },
    {
      imageUrl: ulfmc1,
      url: '#',
      category: 'Product Highlight',
      date: 'September 5, 2024',
      readTime: '06:00 minutes',
      title: 'Type UA - Liquid-Tight Flexible Metal Conduit (LFMC)',
      excerpt: 'The Type UA conduit is constructed with a hot dipped zinc galvanized steel core, offering exceptional crush and corrosion resistance...',
    },
    {
      imageUrl: ueft1,
      url: '#',
      category: 'Product Offering',
      date: 'September 10, 2024',
      readTime: '04:00 minutes',
      title: 'Flexible Conduit Accessories: Comprehensive Choices',
      excerpt: 'Offering a range of flexible conduit accessories including coupling, conduit gland, and more. Explore our full selection of high-quality products...',
    },
    {
      imageUrl: gip1,
      url: '#',
      category: 'Quality Assurance',
      date: 'September 15, 2024',
      readTime: '07:00 minutes',
      title: 'Electrical Fittings & EMT Connectors: Guaranteed Quality',
      excerpt: 'Techno Flex offers high-quality electrical fittings and EMT connectors, guaranteed for life. Our products meet and exceed customer expectations...',
    },
    {
      imageUrl: ugs1,
      url: '#',
      category: 'Company Profile',
      date: 'September 20, 2024',
      readTime: '08:00 minutes',
      title: 'GI Conduit and Junction Box: Superior Engineering',
      excerpt: 'As a leading manufacturer, Techno Flex provides a range of high-quality GI conduits and junction boxes, engineered for durability and performance...',
    },
    {
      imageUrl: gip1,
      url: '#',
      category: 'Product Details',
      date: 'September 25, 2024',
      readTime: '05:00 minutes',
      title: 'Galvanized Steel Core Flexible Conduit: Features and Benefits',
      excerpt: 'Our galvanized steel core flexible conduit offers flexibility, corrosion resistance, and high mechanical strength for various applications...',
    },
  ];
  
  const scrollContainerRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      handleScroll('right');
    }, 5000); // Auto-scroll every 5 seconds

    return () => clearInterval(interval);
  }, [currentIndex]);

  const handleScroll = (direction) => {
    if (scrollContainerRef.current) {
      const containerWidth = scrollContainerRef.current.offsetWidth;
      const cardWidth = scrollContainerRef.current.querySelector('.blog-card').offsetWidth;
      const totalVisibleCards = Math.floor(containerWidth / cardWidth);
      const maxIndex = blogsData.length - totalVisibleCards;

      const newIndex = direction === 'left'
        ? Math.max(0, currentIndex - 1)
        : Math.min(maxIndex, currentIndex + 1);

      setCurrentIndex(newIndex);

      scrollContainerRef.current.scrollTo({
        left: newIndex * cardWidth,
        behavior: 'smooth'
      });
    }
  };

  return (
    <section className="blog-section">
      <div className="blog-container">
        <h2 className="blog-heading"><span>Headlines </span> & Updates</h2>
        <div className="blog-slider-container">
          <div className="blog-slider" ref={scrollContainerRef}>
            {blogsData.map((blog, index) => (
              <BlogCard key={index} blog={blog} />
            ))}
          </div>
          <button
            className="blog-nav-button left"
            onClick={() => handleScroll('left')}
            aria-label="Scroll left"
            disabled={currentIndex === 0}
          >
            <FaChevronLeft />
          </button>
          <button
            className="blog-nav-button right"
            onClick={() => handleScroll('right')}
            aria-label="Scroll right"
            disabled={currentIndex === blogsData.length - Math.floor(scrollContainerRef.current?.offsetWidth / scrollContainerRef.current?.querySelector('.blog-card')?.offsetWidth)}
          >
            <FaChevronRight />
          </button>
        </div>
      </div>
    </section>
  );
};

export default Update;
