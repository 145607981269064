// components/TextInput/TextInput.js
import React from 'react';
import { Input, Form } from 'antd';

const TextInput = ({
  label,
  name,
  icon,
  size,
  className,
  placeholder,
  rules,
  hasFeedback,
  validateTrigger,
  onChange,
  password,
  ...props
}) => (
  <Form.Item
    label={label}
    name={name}
    rules={rules}
    hasFeedback={hasFeedback}
    validateTrigger={validateTrigger}
    className={className}
  >
    {password ? (
      <Input.Password
        size={size}
        placeholder={placeholder}
        prefix={icon}
        onChange={onChange}
        {...props}
      />
    ) : (
      <Input
        size={size}
        placeholder={placeholder}
        prefix={icon}
        onChange={onChange}
        {...props}
      />
    )}
  </Form.Item>
);

export default TextInput;

