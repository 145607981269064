// import React, { useState } from 'react';
// import './ContactUs.css';

// const ContactUs = () => {
//   const [formData, setFormData] = useState({
//     name: '',
//     companyName: '',
//     contactNo: '',
//     email: '',
//     state: '',
//     city: '',
//     pincode: '',
//     message: '',
//     areaOfInterest: '',
//     country: ''
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value
//     });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     // Handle form submission logic here
//     console.log(formData);
//   };

//   return (
//     <div className="contact-us-container">
//       <div className="contact-info">
//         <h2> <span className="contact-text">Contact</span> Us</h2>
//         <p>
//           <strong>Address:</strong><br />
//           100, Golden,
//           Farm House, <br />
//           opp. Tilak Parisar,
//           CAT Square, <br />
//           Rajendra Nagar,<br />
//           Indore - 452012 
//         </p>
//         <p>
//           <strong>Phone:</strong><br />
//           +91-9827458587<br />
//           +91-8770608670
//         </p>
//         <p>
//           <strong>Email:</strong><br />
//           <a href="">info@technoflex.in</a>
//         </p>
//       </div>

//       <div className="contact-form">
//         <form onSubmit={handleSubmit}>
//           <div className="form-group full-width">
//             <label>Area of Interest</label>
//             <input
//               type="text"
//               name="areaOfInterest"
//               value={formData.areaOfInterest}
//               onChange={handleChange}
//             />
//           </div>

//           <div className="form-row">
//             <div className="form-group">
//               <label>Name *</label>
//               <input
//                 type="text"
//                 name="name"
//                 value={formData.name}
//                 onChange={handleChange}
//                 required
//               />
//             </div>

//             <div className="form-group">
//               <label>Company Name</label>
//               <input
//                 type="text"
//                 name="companyName"
//                 value={formData.companyName}
//                 onChange={handleChange}
//               />
//             </div>
//           </div>

//           <div className="form-row">
//             <div className="form-group">
//               <label>Contact No *</label>
//               <input
//                 type="text"
//                 name="contactNo"
//                 value={formData.contactNo}
//                 onChange={handleChange}
//                 required
//               />
//             </div>

//             <div className="form-group">
//               <label>Email *</label>
//               <input
//                 type="email"
//                 name="email"
//                 value={formData.email}
//                 onChange={handleChange}
//                 required
//               />
//             </div>
//           </div>

//           <div className="form-row">
//             <div className="form-group">
//               <label>Select Country</label>
//               <select
//                 name="country"
//                 value={formData.country}
//                 onChange={handleChange}
//               >
//                 <option value="">Select Country</option>
//                 <option value="India">India</option>
//                 {/* Add other countries if needed */}
//               </select>
//             </div>

//             <div className="form-group">
//               <label>Select State</label>
//               <select
//                 name="state"
//                 value={formData.state}
//                 onChange={handleChange}
//                 disabled={formData.country !== 'India'}
//               >
//                 <option value="">Select State</option>
//                 {formData.country === 'India' && (
//                   <>
//                     <option value="Andhra Pradesh">Andhra Pradesh</option>
//                     <option value="Arunachal Pradesh">Arunachal Pradesh</option>
//                     <option value="Assam">Assam</option>
//                     <option value="Bihar">Bihar</option>
//                     <option value="Chhattisgarh">Chhattisgarh</option>
//                     <option value="Goa">Goa</option>
//                     <option value="Gujarat">Gujarat</option>
//                     <option value="Haryana">Haryana</option>
//                     <option value="Himachal Pradesh">Himachal Pradesh</option>
//                     <option value="Jharkhand">Jharkhand</option>
//                     <option value="Karnataka">Karnataka</option>
//                     <option value="Kerala">Kerala</option>
//                     <option value="Madhya Pradesh">Madhya Pradesh</option>
//                     <option value="Maharashtra">Maharashtra</option>
//                     <option value="Manipur">Manipur</option>
//                     <option value="Meghalaya">Meghalaya</option>
//                     <option value="Mizoram">Mizoram</option>
//                     <option value="Nagaland">Nagaland</option>
//                     <option value="Odisha">Odisha</option>
//                     <option value="Punjab">Punjab</option>
//                     <option value="Rajasthan">Rajasthan</option>
//                     <option value="Sikkim">Sikkim</option>
//                     <option value="Tamil Nadu">Tamil Nadu</option>
//                     <option value="Telangana">Telangana</option>
//                     <option value="Tripura">Tripura</option>
//                     <option value="Uttar Pradesh">Uttar Pradesh</option>
//                     <option value="Uttarakhand">Uttarakhand</option>
//                     <option value="West Bengal">West Bengal</option>
//                   </>
//                 )}
//               </select>
//             </div>
//           </div>

//           <div className="form-row">
//             <div className="form-group">
//               <label>City/Town/District *</label>
//               <input
//                 type="text"
//                 name="city"
//                 value={formData.city}
//                 onChange={handleChange}
//                 required
//               />
//             </div>

//             <div className="form-group">
//               <label>Enter Pincode *</label>
//               <input
//                 type="text"
//                 name="pincode"
//                 value={formData.pincode}
//                 onChange={handleChange}
//                 required
//               />
//             </div>
//           </div>

//           <div className="form-group full-width">
//             <label>Message *</label>
//             <textarea
//               name="message"
//               value={formData.message}
//               onChange={handleChange}
//               required
//             ></textarea>
//           </div>

//           <button type="submit">Submit</button>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default ContactUs;


import React from 'react';
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope, FaUser, FaBuilding, FaGlobe } from 'react-icons/fa';
import './ContactUs.css';

const ContactUs = () => {
  return (
    <section className="contact-us">
      <div className="contact-container">
        <h2 className="contact-heading">
          <span className="highlight">Contact</span> Us
        </h2>
        
        <div className="contact-flex">
          <div className="contact-info">
            <div className="contact-card">
              <FaMapMarkerAlt className="contact-icon" />
              <p>100, Golden,
              Farm House,opp. Tilak Parisar,
              CAT Square,Rajendra Nagar,   Indore - 452012 </p>
            </div>
            <div className="contact-card">
              <FaPhoneAlt className="contact-icon" />
              <p><a href="tel: +91-8770608670"> +91-8770608670</a>, <a href="tel:+91-9827458587">+91-9827458587</a></p>
            </div>
            <div className="contact-card">
              <FaEnvelope className="contact-icon" />
              <p><a href="info@technoflex.in">info@technoflex.in</a></p>
            </div>
            <a href="" className="btn-primaryy">Our offices</a>
          </div>
          
          <form className="contact-form" id="enquiry-form" action="https://www.technoflex.com/form/contact-us" method="POST">
            <input type="hidden" name="_token" value="dummy_token" />
            <input type="hidden" name="page_url" value="https://www.technoflex.com" />
            
            <div className="form-group">
              <label htmlFor="select-business">Area of Interest *</label>
              <select name="business" id="select-business" required>
                <option value="" disabled>Select your interest</option>
                <option value="Electrical Accessories">Electrical Accessories</option>
                <option value="PVC Coated Flexible Conduit">PVC Coated Flexible Conduit</option>
                <option value="Liquid Tight Flexible Conduit">Liquid Tight Flexible Conduit</option>
                <option value="GI Pipes">GI Pipes</option>
                {/* Add other options here */}
              </select>
            </div>
            
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="name"><FaUser className="lable-icon" /> Name *</label>
                <input id="name" type="text" name="name" required />
              </div>
              <div className="form-group">
                <label htmlFor="company"><FaBuilding  className="lable-icon"/> Company Name</label>
                <input id="company" type="text" name="company" />
              </div>
            </div>
            
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="phone"><FaPhoneAlt className="lable-icon" /> Contact No *</label>
                <input id="phone" type="tel" name="phone" minLength="10" maxLength="10" required />
              </div>
              <div className="form-group">
                <label htmlFor="email"><FaEnvelope className="lable-icon" /> Email *</label>
                <input id="email" type="email" name="email" required />
              </div>
            </div>
            
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="country"><FaGlobe className="lable-icon" /> Country *</label>
                <select name="country" id="country" required>
                  <option value="" disabled>Select Country</option>
                  <option value="India">India</option>
                  {/* Add other countries here */}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="state">State *</label>
                <select name="state" id="state" required>
                  <option value="" disabled>Select State</option>
                  {/* Add states here */}
                </select>
              </div>
            </div>
            
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="city">City/Town/District *</label>
                <input id="city" type="text" name="city" required />
              </div>
              <div className="form-group">
                <label htmlFor="pincode">Pincode *</label>
                <input id="pincode" type="number" name="pincode" minLength="6" maxLength="6" required />
              </div>
            </div>
            
            <div className="form-group">
              <label htmlFor="message">Message *</label>
              <textarea id="message" name="message" required></textarea>
            </div>
            
            <div className="g-recaptcha" data-sitekey="your-site-key"></div>
            
            <button type="submit" className="btn-primaryy">Submit</button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;

