import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

const ProductList = () => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axios.get('http://localhost:8000/api/products');
                console.log('API Response:', response.data); // Log API response for debugging
                setProducts(response.data);
            } catch (error) {
                console.error('Error fetching products:', error);
                setError('Failed to fetch products.');
            } finally {
                setLoading(false);
            }
        };

        fetchProducts();
    }, []);

    if (loading) {
        return <div className="container mt-5"><p>Loading...</p></div>;
    }

    if (error) {
        return <div className="container mt-5"><p>{error}</p></div>;
    }

    return (
        <div className="container mt-5">
            <h2>Product List</h2>
            <div className="row">
                {products.length === 0 ? (
                    <p>No products available.</p>
                ) : (
                    products.map((product) => (
                        <div key={product.ProductID} className="col-md-4 mb-4">
                            <div className="card">
                                {product.ProductImage ? (
                                    <img
                                        src={`data:image/jpeg;base64,${product.ProductImage}`}
                                        className="card-img-top"
                                        alt={product.ProductName}
                                    />
                                ) : (
                                    <img
                                        src="path_to_placeholder_image.jpg" // Ensure this path is valid
                                        className="card-img-top"
                                        alt="Placeholder"
                                    />
                                )}
                                <div className="card-body">
                                    <h5 className="card-title">{product.ProductName}</h5>
                                    <p className="card-text">{product.ShortDesc}</p>
                                    <p className="card-text"><strong>Price:</strong> ${product.Price}</p>
                                    <p className="card-text"><strong>Category:</strong> {product.Category}</p>
                                    <p className="card-text"><strong>Rating:</strong> {product.AvgRating}</p>
                                </div>
                            </div>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default ProductList;


